var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Criar Proposta")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados corretora")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "validation-observer",
                {
                  ref: "form",
                  staticClass: "mb-10",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "primary--text mb-2 subtitle-1 font-weight-bold",
                                    },
                                    [_vm._v("Corretora")]
                                  ),
                                  _c("v-divider"),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Corretora",
                                      vid: "corretora",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  value: _vm.form.corretora,
                                                  "value-comparator":
                                                    _vm.valueCompare,
                                                  mandatory: false,
                                                  "error-messages": errors,
                                                },
                                              },
                                              [
                                                _vm.corretoras.length > 0
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm._l(
                                                          _vm.corretoras,
                                                          function (
                                                            corretora,
                                                            index
                                                          ) {
                                                            return [
                                                              _c("v-radio", {
                                                                key: corretora.cpfCnpj,
                                                                staticClass:
                                                                  "mb-4",
                                                                class: {
                                                                  "mt-4":
                                                                    index > 0,
                                                                },
                                                                attrs: {
                                                                  "data-test-id":
                                                                    "corretora-" +
                                                                    index,
                                                                  id:
                                                                    "corretora-" +
                                                                    index,
                                                                  label:
                                                                    corretora.nomeFantasia,
                                                                  value:
                                                                    corretora.cpfCnpj,
                                                                  disabled:
                                                                    _vm.desabilitaInput,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClickCorretora(
                                                                        corretora
                                                                      )
                                                                    },
                                                                  change:
                                                                    _vm.setAdministradoras,
                                                                },
                                                              }),
                                                              index <
                                                              _vm.corretoras
                                                                .length -
                                                                1
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      key: corretora.nomeFantasia,
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c("div", [
                                                      _vm._v(
                                                        " Aguardando aprovação de corretora "
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.corretora
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Supervisor")]
                                          ),
                                          _c("v-divider"),
                                          _c("async-supervisores", {
                                            attrs: {
                                              idCorretora:
                                                _vm.selectedCorretora.id,
                                              cpfCorretor: _vm.cpf,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    loading:
                                                      loadingSupervisores,
                                                    data,
                                                  }) {
                                                    return [
                                                      _c(
                                                        "base-wrapper-dialog-list",
                                                        {
                                                          attrs: {
                                                            headerTitle:
                                                              "Supervisor",
                                                            options: data,
                                                            "label-key": "nome",
                                                            "value-key": "id",
                                                            loading:
                                                              loadingSupervisores,
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            select: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectSupervisor(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activador",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "base-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              rules:
                                                                                "required",
                                                                              readonly:
                                                                                "",
                                                                              disabled: false,
                                                                              inputmode:
                                                                                "text",
                                                                              id: "supervisor",
                                                                              name: "Supervisor",
                                                                              placeholder:
                                                                                "Selecione",
                                                                              label:
                                                                                "Supervisor",
                                                                              outlined:
                                                                                "",
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .supervisor
                                                                                  ? _vm
                                                                                      .form
                                                                                      .supervisor
                                                                                      .nome
                                                                                  : null,
                                                                              "append-icon":
                                                                                "mdi-chevron-right",
                                                                              loading: false,
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              706288094
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.selectedCorretora.portabilidade
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pb-0" },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "primary--text mb-2 subtitle-1 font-weight-bold",
                                                },
                                                [_vm._v("Portabilidade")]
                                              ),
                                              _c("v-divider"),
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Portabilidade",
                                                  vid: "portabilidade",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              attrs: {
                                                                mandatory: false,
                                                                "error-messages":
                                                                  errors,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .portabilidade,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "portabilidade",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.portabilidade",
                                                              },
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "mb-4",
                                                                attrs: {
                                                                  id: "portabilidade-sim",
                                                                  "data-test-id":
                                                                    "portabilidade-sim",
                                                                  label: "SIM",
                                                                  value: true,
                                                                },
                                                              }),
                                                              _c("v-divider"),
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "mb-4 mt-4",
                                                                attrs: {
                                                                  id: "portabilidade-nao",
                                                                  "data-test-id":
                                                                    "portabilidade-nao",
                                                                  label: "NÃO",
                                                                  value: false,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1688994832
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedCorretora.sumula
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0",
                                              attrs: {
                                                cols: "12",
                                                md: _vm.form.sumula
                                                  ? "6"
                                                  : "12",
                                              },
                                            },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "primary--text mb-2 subtitle-1 font-weight-bold",
                                                },
                                                [_vm._v("Súmula 21")]
                                              ),
                                              _c("v-divider"),
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Súmula",
                                                  vid: "sumula",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              attrs: {
                                                                mandatory: false,
                                                                "error-messages":
                                                                  errors,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .sumula,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "sumula",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.sumula",
                                                              },
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "mb-4",
                                                                attrs: {
                                                                  id: "sumula-sim",
                                                                  "data-test-id":
                                                                    "sumula-sim",
                                                                  label: "SIM",
                                                                  value: true,
                                                                },
                                                              }),
                                                              _c("v-divider"),
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "mb-4 mt-4",
                                                                attrs: {
                                                                  id: "sumula-nao",
                                                                  "data-test-id":
                                                                    "sumula-nao",
                                                                  label: "NÃO",
                                                                  value: false,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3787108145
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.form.sumula
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "primary--text mb-2 subtitle-1 font-weight-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Tempo plano anterior"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-divider"),
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Tempo do Plano Anterior",
                                                      vid: "sumulaPlanoAnterior",
                                                      rules: _vm.form.sumula
                                                        ? "required"
                                                        : "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            errors,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-radio-group",
                                                                {
                                                                  attrs: {
                                                                    mandatory: false,
                                                                    "error-messages":
                                                                      errors,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .sumulaPlanoAnterior,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          "sumulaPlanoAnterior",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.sumulaPlanoAnterior",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-radio",
                                                                    {
                                                                      staticClass:
                                                                        "mb-4",
                                                                      attrs: {
                                                                        "data-test-id":
                                                                          "maior-24",
                                                                        label:
                                                                          "MAIOR QUE 24 MESES",
                                                                        value: 1,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-radio",
                                                                    {
                                                                      staticClass:
                                                                        "mb-4 mt-4",
                                                                      attrs: {
                                                                        "data-test-id":
                                                                          "menor-24",
                                                                        label:
                                                                          "MENOR QUE 24 MESES",
                                                                        value: 0,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3506665002
                                                    ),
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.getCorretorasRepasse.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Corretora de Repasse")]
                                          ),
                                          _c("v-divider"),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Corretora de Repasse",
                                              vid: "corretoraRepasse",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          attrs: {
                                                            mandatory: false,
                                                            "error-messages":
                                                              errors,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .corretoraRepasse,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "corretoraRepasse",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.corretoraRepasse",
                                                          },
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.getCorretorasRepasse,
                                                            function (
                                                              corretoraRepasse,
                                                              index
                                                            ) {
                                                              return [
                                                                _c("v-radio", {
                                                                  key: corretoraRepasse.id,
                                                                  staticClass:
                                                                    "mb-4",
                                                                  class: {
                                                                    "mt-4":
                                                                      index > 0,
                                                                  },
                                                                  attrs: {
                                                                    "data-test-id":
                                                                      "corretoraRepasse" +
                                                                      index,
                                                                    id:
                                                                      "corretoraRepasse" +
                                                                      index,
                                                                    label:
                                                                      corretoraRepasse.nome,
                                                                    value:
                                                                      corretoraRepasse.id,
                                                                    checked: true,
                                                                    disabled:
                                                                      _vm.desabilitaInput,
                                                                  },
                                                                }),
                                                                index <
                                                                _vm.corretoras
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "v-divider",
                                                                      {
                                                                        key:
                                                                          corretoraRepasse.id +
                                                                          "2",
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2746306097
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : !_vm.getAdministradora && _vm.form.corretora
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Administradora")]
                                          ),
                                          _c("v-divider"),
                                          _c("div", { staticClass: "mt-3" }, [
                                            _c("div", [
                                              _c(
                                                "p",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    "A corretora não possui administradora cadastrada. Entre em contato com sua corretora."
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text mt-4",
                                      attrs: {
                                        "data-test-id": "salvar-continuar-1",
                                        id: "salvar-continuar",
                                        loading: _vm.loadingSalvar,
                                        disabled:
                                          !_vm.getAdministradora &&
                                          _vm.form.corretora,
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }