var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        eager: "",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c("the-nav-bar", {
            attrs: { title: "Dados Dependente" },
            on: { back: _vm.close },
          }),
          _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _c(
                "validation-observer",
                {
                  ref: "form",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "div_fields", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6 pb-0" },
                        [
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            attrs: {
                              value: "teste",
                              "data-test-id": "dob-dependente",
                              rules: "required|date",
                              id: "dataNascimento",
                              name: "Data de nascimento",
                              label: "Data de nascimento *",
                              placeholder: "DD/MM/AAAA",
                              inputmode: "numeric",
                              clearable: "",
                              disabled: _vm.desabilitaInput,
                              mode: "aggressive",
                              outlined: "",
                            },
                            on: { input: _vm.inputIdade },
                            model: {
                              value: _vm.dataNascimento,
                              callback: function ($$v) {
                                _vm.dataNascimento = $$v
                              },
                              expression: "dataNascimento",
                            },
                          }),
                          _c("async-cpf", {
                            attrs: {
                              cpf: _vm.form.cpf,
                              dataNascimento: _vm.form.dataNascimento,
                              loadingMounted: _vm.loadingMountedCpf,
                              rotina: "Consultar CPF do dependente da proposta",
                            },
                            on: {
                              success: function ($event) {
                                return _vm.successCpf($event)
                              },
                              error: function ($event) {
                                _vm.loadingMountedCpf = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ loading: loadingCpf }) {
                                  return [
                                    _c("base-text-field", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "###.###.###-##",
                                          expression: "'###.###.###-##'",
                                        },
                                      ],
                                      attrs: {
                                        value: "",
                                        rules: "required|cpf",
                                        id: "cpf",
                                        "data-test-id": "cpf-dependente",
                                        name: "CPF",
                                        label: "CPF *",
                                        inputmode: "numeric",
                                        loading: loadingCpf,
                                        clearable: "",
                                        disabled:
                                          _vm.desabilitaInput || _vm.disableCPF,
                                        outlined: "",
                                      },
                                      model: {
                                        value: _vm.form.cpf,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cpf", $$v)
                                        },
                                        expression: "form.cpf",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("base-text-field", {
                            attrs: {
                              mode: "aggressive",
                              rules: "required|special_characters",
                              id: "nome",
                              "data-test-id": "nome-dependente",
                              name: "Nome completo",
                              value: "",
                              label: "Nome completo *",
                              disabled: _vm.desabilitaInput,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nome", $$v)
                              },
                              expression: "form.nome",
                            },
                          }),
                          _c("base-text-field", {
                            attrs: {
                              mode: "aggressive",
                              rules: "required|special_characters",
                              id: "nomeMae",
                              "data-test-id": "nome-mae-dependente",
                              name: "Nome da mãe",
                              value: "",
                              label: "Nome da mãe *",
                              disabled: _vm.desabilitaInput,
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.nomeMae,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nomeMae", $$v)
                              },
                              expression: "form.nomeMae",
                            },
                          }),
                          _c("base-wrapper-dialog-options", {
                            ref: "dialogSexo",
                            attrs: {
                              headerTitle: "Sexo",
                              optionSelected: _vm.form.sexo,
                              options: [
                                { valor: "m", descricao: "Masculino" },
                                { valor: "f", descricao: "Feminino" },
                              ],
                              "value-key": "valor",
                              "label-key": "descricao",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "sexo",
                                  $event.descricao
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            readonly: "",
                                            rules: "required",
                                            placeholder:
                                              "Clique aqui para selecionar",
                                            id: "sexo",
                                            "data-test-id": "sexo-dependente",
                                            name: "Sexo",
                                            value: _vm.form.sexo,
                                            label: "Sexo *",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            disabled: _vm.desabilitaInput,
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("async-list-grau-parentesco", {
                            on: { success: _vm.setGrauParentesco },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ loading: loadingParentesco }) {
                                  return [
                                    !loadingParentesco
                                      ? _c("base-wrapper-dialog-options", {
                                          attrs: {
                                            optionSelected: _vm.form.parentesco,
                                            headerTitle: "Grau parentesco",
                                            options: _vm.parentescosFiltrado,
                                            "value-key": "parentesco",
                                            "label-key": "parentesco",
                                            loading: loadingParentesco,
                                          },
                                          on: {
                                            select: function ($event) {
                                              return _vm.selectedGrauParentesco(
                                                $event
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activador",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "base-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            rules: "required",
                                                            readonly: "",
                                                            id: "parentesco",
                                                            "data-test-id":
                                                              "parentesco-dependente",
                                                            placeholder:
                                                              "Clique aqui para selecionar",
                                                            name: "Grau parentesco",
                                                            value:
                                                              _vm.form
                                                                .parentesco,
                                                            label:
                                                              "Grau parentesco *",
                                                            outlined: "",
                                                            "append-icon":
                                                              "mdi-chevron-right",
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    _vm.dataEventoObrigatorio
                                      ? _c(
                                          "base-text-field",
                                          {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "##/##/####",
                                                expression: "'##/##/####'",
                                              },
                                            ],
                                            staticClass: "mb-2",
                                            attrs: {
                                              rules: "required|date",
                                              id: "dataEvento",
                                              "data-test-id": "data-evento",
                                              name: "Data do evento",
                                              value: "",
                                              label: "Data do evento *",
                                              disabled:
                                                _vm.verificaGrauParentescoFilho,
                                              clearable: "",
                                              outlined: "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.form.dataEvento,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "dataEvento",
                                                  $$v
                                                )
                                              },
                                              expression: "form.dataEvento",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "", right: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g({}, on),
                                                            [
                                                              _vm._v(
                                                                "mdi-information"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "caption",
                                                    staticStyle: {
                                                      "line-height": "1",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Informe a data do evento de acordo com o grau de parentesco como por exemplo: data do nascimento, casamento, união estável, entre outros."
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.dataEventoObrigatorio
                                      ? _c(
                                          "div",
                                          { staticClass: "custom-hint mb-5" },
                                          [
                                            _vm._v(
                                              " Informe a data do evento de acordo com o grau de parentesco como por exemplo: data do nascimento, casamento, união estável, entre outros. "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("base-wrapper-dialog-options", {
                                      ref: "dialogEstadoCivil",
                                      attrs: {
                                        headerTitle: "Estado civil",
                                        optionSelected: _vm.form.estadoCivil,
                                        options: _vm.estadosCivis,
                                        "value-key": "id",
                                        "label-key": "nome",
                                      },
                                      on: {
                                        select: function ($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "estadoCivil",
                                            $event.nome
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activador",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "base-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        loading:
                                                          loadingParentesco,
                                                        rules: "required",
                                                        mode: "lazy",
                                                        readonly: "",
                                                        id: "estadoCivil",
                                                        "data-test-id":
                                                          "estado-civil-dependente",
                                                        placeholder:
                                                          "Clique aqui para selecionar",
                                                        name: "Estado civil",
                                                        value:
                                                          _vm.form.estadoCivil,
                                                        label: "Estado civil *",
                                                        outlined: "",
                                                        "append-icon":
                                                          "mdi-chevron-right",
                                                        disabled: _vm.form
                                                          .parentesco
                                                          ? false
                                                          : true,
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm.propostaState.dpsPorBeneficiario &&
                          _vm.idade >= 18
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "base-text-field",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "## #####-####",
                                          expression: "'## #####-####'",
                                        },
                                      ],
                                      staticClass: "mb-3",
                                      attrs: {
                                        type: "tel",
                                        mode: "eager",
                                        rules:
                                          _vm.idade >= 18 &&
                                          _vm.propostaState.dpsPorBeneficiario
                                            ? "required|cellphone|ddd"
                                            : "cellphone|ddd",
                                        id: "celular",
                                        name: "Telefone celular",
                                        "data-test-id": "informar-telefone",
                                        label: `Telefone Celular ${
                                          _vm.idade >= 18 &&
                                          _vm.propostaState.dpsPorBeneficiario
                                            ? "*"
                                            : ""
                                        }`,
                                        disabled: _vm.desabilitaInput,
                                        clearable: "",
                                        outlined: "",
                                        hint: "Mesmo número que haverá confirmação via sms para conclusão da proposta",
                                        "persistent-hint": "",
                                      },
                                      model: {
                                        value: _vm.form.celular,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "celular", $$v)
                                        },
                                        expression: "form.celular",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "", right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          "mdi-information"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4275447087
                                          ),
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "caption",
                                              staticStyle: {
                                                "line-height": "1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Mesmo número que haverá confirmação via sms para assinatura da DPS"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("base-text-field", {
                                    attrs: {
                                      type: "email",
                                      "data-test-id": "valida-email",
                                      mode: "eager",
                                      rules:
                                        _vm.idade >= 18 &&
                                        _vm.propostaState.dpsPorBeneficiario
                                          ? "required|email"
                                          : "email",
                                      id: "email",
                                      name: "E-mail",
                                      label: `E-mail ${
                                        _vm.idade >= 18 &&
                                        _vm.propostaState.dpsPorBeneficiario
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.propostaState.plano.operadora === "Smile Saúde" &&
                          _vm.dnvObrigatorio &&
                          _vm.verificaGrauParentesco
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##-########-#",
                                        expression: "'##-########-#'",
                                      },
                                    ],
                                    attrs: {
                                      rules:
                                        _vm.verificaRgVazio &&
                                        _vm.dnvObrigatorio
                                          ? "required"
                                          : "",
                                      id: "dnvDependente",
                                      "data-test-id": "dnv-dependente",
                                      name: "Declaração Nascido Vivo",
                                      label: `Declaração Nascido Vivo ${
                                        _vm.verificaRgVazio &&
                                        _vm.dnvObrigatorio
                                          ? "*"
                                          : ""
                                      }`,
                                      maxlength: "12",
                                      inputmode: "numeric",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.declaracaoNascidoVivo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "declaracaoNascidoVivo",
                                          $$v
                                        )
                                      },
                                      expression: "form.declaracaoNascidoVivo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.dnvObrigatorio
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##-########-#",
                                        expression: "'##-########-#'",
                                      },
                                    ],
                                    attrs: {
                                      rules: _vm.dnvObrigatorio
                                        ? "required"
                                        : "",
                                      id: "dnvDependente",
                                      "data-test-id": "dnv-dependente",
                                      name: "Declaração Nascido Vivo",
                                      label: `Declaração Nascido Vivo ${
                                        _vm.dnvObrigatorio ? "*" : ""
                                      }`,
                                      maxlength: "12",
                                      inputmode: "numeric",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.declaracaoNascidoVivo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "declaracaoNascidoVivo",
                                          $$v
                                        )
                                      },
                                      expression: "form.declaracaoNascidoVivo",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("base-text-field", {
                            attrs: {
                              rules:
                                _vm.verificaDnvVazio && _vm.rgObrigatorio
                                  ? "required"
                                  : "",
                              id: "identidade",
                              "data-test-id": "identidade-dependente",
                              name: "Identidade",
                              label: `Identidade ${
                                _vm.verificaDnvVazio && _vm.rgObrigatorio
                                  ? "*"
                                  : ""
                              }`,
                              maxlength: "20",
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.rg,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rg", $$v)
                              },
                              expression: "form.rg",
                            },
                          }),
                          _vm.emissorObrigatorio &&
                          _vm.propostaState.administradora !== "plural"
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "AAAAAAAAA",
                                        expression: "'AAAAAAAAA'",
                                      },
                                    ],
                                    attrs: {
                                      rules: _vm.emissorObrigatorio
                                        ? "required"
                                        : "",
                                      id: "rgOrgaoEmissor",
                                      "data-test-id":
                                        "orgao-emissor-dependente",
                                      name: "Orgão Emissor",
                                      label: `Orgão Emissor ${
                                        _vm.emissorObrigatorio ? "*" : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.rgOrgaoEmissor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "rgOrgaoEmissor",
                                          $$v
                                        )
                                      },
                                      expression: "form.rgOrgaoEmissor",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.emissorObrigatorio &&
                          _vm.propostaState.administradora === "plural"
                            ? _c(
                                "div",
                                [
                                  _c("base-wrapper-dialog-options", {
                                    attrs: {
                                      headerTitle: "Orgão Emissor",
                                      options: _vm.listOrgaoEmissor,
                                      "value-key": "id",
                                      "label-key": "nome",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "rgOrgaoEmissor",
                                          $event.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      rules:
                                                        _vm.emissorObrigatorio
                                                          ? "required"
                                                          : "",
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      id: "rgOrgaoEmissor",
                                                      name: "Orgão Emissor",
                                                      value:
                                                        _vm.form.rgOrgaoEmissor,
                                                      label: `Orgão Emissor ${
                                                        _vm.emissorObrigatorio
                                                          ? "*"
                                                          : ""
                                                      }`,
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2244575935
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.ufObrigatorio
                            ? _c(
                                "div",
                                [
                                  _c("base-wrapper-dialog-options", {
                                    attrs: {
                                      headerTitle: "UF Orgão Emissor",
                                      options: _vm.listUf,
                                      "value-key": "id",
                                      "label-key": "nome",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "rgOrgaoEmissorUf",
                                          $event.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      rules: _vm.ufObrigatorio
                                                        ? "required"
                                                        : "",
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      id: "rgOrgaoEmissorUf",
                                                      "data-test-id":
                                                        "uf-emissor-dependente",
                                                      name: "UF Orgão Emissor",
                                                      value:
                                                        _vm.form
                                                          .rgOrgaoEmissorUf,
                                                      label: `UF Orgão Emissor ${
                                                        _vm.ufObrigatorio
                                                          ? "*"
                                                          : ""
                                                      }`,
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1641049225
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.dataExpedicaoObrigatorio
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      rules: _vm.dataExpedicaoObrigatorio
                                        ? "required"
                                        : "",
                                      id: "rgDataExpedicao",
                                      "data-test-id":
                                        "data-expedicao-dependente",
                                      name: "Data de Expedição",
                                      label: `Data de Expedição ${
                                        _vm.dataExpedicaoObrigatorio ? "*" : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.rgDataExpedicao,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "rgDataExpedicao",
                                          $$v
                                        )
                                      },
                                      expression: "form.rgDataExpedicao",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.cnsObrigatorio
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###.####.####.####",
                                        expression: "'###.####.####.####'",
                                      },
                                    ],
                                    attrs: {
                                      rules: _vm.cnsObrigatorio
                                        ? "required|cns"
                                        : "",
                                      id: "numeroCns",
                                      name: "Cartão Nacional Saúde",
                                      "data-test-id": "numero-cns",
                                      label: `Cartão Nacional Saúde ${
                                        _vm.cnsObrigatorio ? "*" : ""
                                      }`,
                                      inputmode: "numeric",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.cns,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cns", $$v)
                                      },
                                      expression: "form.cns",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.#####.##-#",
                                expression: "'###.#####.##-#'",
                              },
                            ],
                            attrs: {
                              rules: "pisPasep",
                              id: "pisPasep",
                              "data-test-id": "pis-pasep-dependente",
                              name: "PIS/PASEP",
                              label: "PIS/PASEP",
                              inputmode: "numeric",
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.form.pisPasep,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pisPasep", $$v)
                              },
                              expression: "form.pisPasep",
                            },
                          }),
                          _vm.exibirReducaoCarencia
                            ? _c("base-wrapper-dialog-options", {
                                ref: "dialogtemPlano",
                                attrs: {
                                  headerTitle: "Redução de carência",
                                  options: [
                                    { id: "Sim", nome: "Sim", value: true },
                                    { id: "Não", nome: "Não", value: false },
                                  ],
                                  "value-key": "value",
                                  "label-key": "nome",
                                },
                                on: {
                                  select: function ($event) {
                                    return _vm.$set(
                                      _vm.reducaoCarencia,
                                      "temPlano",
                                      $event.id
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activador",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "base-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  readonly: "",
                                                  rules: "required",
                                                  id: "temPlano",
                                                  "data-test-id":
                                                    "reducao-carencia-dependente",
                                                  name: "temPlano",
                                                  value:
                                                    _vm.reducaoCarencia
                                                      .temPlano,
                                                  placeholder:
                                                    "Clique aqui para selecionar",
                                                  label:
                                                    "Vai anexar documento que comprove redução de carência? *",
                                                  outlined: "",
                                                  "append-icon":
                                                    "mdi-chevron-right",
                                                },
                                                model: {
                                                  value:
                                                    _vm.reducaoCarencia
                                                      .temPlano,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.reducaoCarencia,
                                                      "temPlano",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "reducaoCarencia.temPlano",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  537748578
                                ),
                              })
                            : _vm._e(),
                          _vm.propostaState.plano.operadora.toLowerCase() ===
                          "unimed volta redonda"
                            ? _c(
                                "div",
                                [
                                  _c("base-text-field", {
                                    attrs: {
                                      inputmode: "text",
                                      rules:
                                        _vm.reducaoCarencia.temPlano === "Sim"
                                          ? "required"
                                          : "",
                                      id: "operadoraAnterior",
                                      name: "Operadora Anterior",
                                      label: `Operadora Anterior ${
                                        _vm.reducaoCarencia.temPlano === "Sim"
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.operadoraAnterior,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "operadoraAnterior",
                                          $$v
                                        )
                                      },
                                      expression: "form.operadoraAnterior",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###",
                                        expression: "'###'",
                                      },
                                    ],
                                    attrs: {
                                      inputmode: "numeric",
                                      rules:
                                        _vm.form.temPlano === "Sim"
                                          ? "required"
                                          : "",
                                      id: "tempoPlanoEmMesesAnterior",
                                      name: "Tempo em meses do plano Anterior",
                                      label: `Tempo em meses do plano Anterior ${
                                        _vm.reducaoCarencia.temPlano === "Sim"
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.tempoPlanoEmMesesAnterior,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "tempoPlanoEmMesesAnterior",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.tempoPlanoEmMesesAnterior",
                                    },
                                  }),
                                  _c("base-wrapper-dialog-options", {
                                    ref: "dialogacomodacaoAnterior",
                                    attrs: {
                                      headerTitle: "Redução de carência",
                                      options: [
                                        {
                                          id: "Coletiva",
                                          nome: "Coletiva",
                                          value: "coletiva",
                                        },
                                        {
                                          id: "Individual",
                                          nome: "Individual",
                                          value: "individual",
                                        },
                                      ],
                                      "value-key": "value",
                                      "label-key": "nome",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "acomodacaoAnterior",
                                          $event.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      readonly: "",
                                                      rules:
                                                        _vm.reducaoCarencia
                                                          .temPlano === "Sim"
                                                          ? "required"
                                                          : "",
                                                      id: "acomodacaoAnterior",
                                                      name: "acomodacaoAnterior",
                                                      value:
                                                        _vm.form
                                                          .acomodacaoAnterior,
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      label: `Selecione a acomodação do plano anterior ${
                                                        _vm.reducaoCarencia
                                                          .temPlano === "Sim"
                                                          ? "*"
                                                          : ""
                                                      }`,
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .acomodacaoAnterior,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "acomodacaoAnterior",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.acomodacaoAnterior",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1268299985
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.propostaState.plano.operadora.toLowerCase() ===
                            "unimed bh" &&
                          _vm.reducaoCarencia.temPlano === "Sim"
                            ? _c(
                                "div",
                                [
                                  _c("base-wrapper-dialog-options", {
                                    ref: "dialogAcomodacao",
                                    attrs: {
                                      headerTitle:
                                        "Informe se a operadora anterior era Unimed BH",
                                      options: [
                                        {
                                          id: "Sim",
                                          nome: "Sim",
                                          value: "Sim",
                                        },
                                        {
                                          id: "Não",
                                          nome: "Não",
                                          value: "Não",
                                        },
                                      ],
                                      "value-key": "value",
                                      "label-key": "nome",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "confirmarOperadoraAnterior",
                                          $event.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      readonly: "",
                                                      clearable: "",
                                                      rules:
                                                        _vm.reducaoCarencia
                                                          .temPlano === "Sim"
                                                          ? "required"
                                                          : "",
                                                      id: "confirmarOperadoraAnterior",
                                                      name: "Informe se a operadora anterior era Unimed BH",
                                                      value:
                                                        _vm.form
                                                          .confirmarOperadoraAnterior,
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      label: `Informe se a operadora anterior era Unimed BH ${
                                                        _vm.reducaoCarencia
                                                          .temPlano === "Sim"
                                                          ? "*"
                                                          : ""
                                                      }`,
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .confirmarOperadoraAnterior,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "confirmarOperadoraAnterior",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.confirmarOperadoraAnterior",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2856686626
                                    ),
                                  }),
                                  _vm.form.confirmarOperadoraAnterior === "Sim"
                                    ? _c(
                                        "div",
                                        [
                                          _c("base-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "###################",
                                                expression:
                                                  "'###################'",
                                              },
                                            ],
                                            attrs: {
                                              inputmode: "numeric",
                                              rules:
                                                _vm.form
                                                  .confirmarOperadoraAnterior ===
                                                "Sim"
                                                  ? "required"
                                                  : "",
                                              id: "campoCarteirinha",
                                              name: "Número Carteirinha",
                                              label: `Informe o número da carteirinha do plano anterior  ${
                                                _vm.form
                                                  .confirmarOperadoraAnterior ===
                                                "Sim"
                                                  ? "*"
                                                  : ""
                                              }`,
                                              clearable: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.campoCarteirinha,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "campoCarteirinha",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.campoCarteirinha",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.propostaState.plano.operadora.toLowerCase() ===
                            "unimed-rio" &&
                          _vm.reducaoCarencia.temPlano !== null
                            ? _c(
                                "div",
                                [
                                  _c("base-wrapper-dialog-options", {
                                    ref: "dialogAcomodacao",
                                    attrs: {
                                      headerTitle: "Possuí plano anterior ? *",
                                      options: [
                                        {
                                          id: "Sim",
                                          nome: "Sim",
                                          value: "Sim",
                                        },
                                        {
                                          id: "Não",
                                          nome: "Não",
                                          value: "Não",
                                        },
                                      ],
                                      "value-key": "value",
                                      "label-key": "nome",
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "possuiPlanoAnteriorReducaoCarencia",
                                          $event.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      readonly: "",
                                                      clearable: "",
                                                      rules: "required",
                                                      id: "possuiPlanoAnteriorReducaoCarencia",
                                                      name: "Possuí plano anterior ?",
                                                      value:
                                                        _vm.form
                                                          .possuiPlanoAnteriorReducaoCarencia,
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      label:
                                                        "Possuí plano anterior ? *",
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .possuiPlanoAnteriorReducaoCarencia,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "possuiPlanoAnteriorReducaoCarencia",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.possuiPlanoAnteriorReducaoCarencia",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      798177492
                                    ),
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: ["###"],
                                        expression: "['###']",
                                      },
                                    ],
                                    attrs: {
                                      inputmode: "numeric",
                                      rules:
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "required"
                                          : "",
                                      id: "tempoPlanoAnteriorReducaoCarencia",
                                      name: "Qual o tempo do plano anterior? Informe os meses",
                                      label: `Qual o tempo do plano anterior? Informe os meses ${
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .tempoPlanoAnteriorReducaoCarencia,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "tempoPlanoAnteriorReducaoCarencia",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.tempoPlanoAnteriorReducaoCarencia",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      inputmode: "text",
                                      rules:
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "required"
                                          : "",
                                      id: "operadoraPlanoAnteriorReducaoCarencia",
                                      name: "Qual Operadora do plano anterior?",
                                      label: `Qual Operadora do plano anterior? ${
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .operadoraPlanoAnteriorReducaoCarencia,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "operadoraPlanoAnteriorReducaoCarencia",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.operadoraPlanoAnteriorReducaoCarencia",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      inputmode: "text",
                                      rules:
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "required"
                                          : "",
                                      id: "registroAnsPlanoAnteriorReducaoCarencia",
                                      name: "Qual Registro ANS do plano anterior?",
                                      label: `Qual Registro ANS do plano anterior? ${
                                        _vm.form
                                          .possuiPlanoAnteriorReducaoCarencia ===
                                        "Sim"
                                          ? "*"
                                          : ""
                                      }`,
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value:
                                        _vm.form
                                          .registroAnsPlanoAnteriorReducaoCarencia,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "registroAnsPlanoAnteriorReducaoCarencia",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.registroAnsPlanoAnteriorReducaoCarencia",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secundaryBtn--text mt-4",
                              attrs: {
                                "data-test-id": "salvar",
                                large: "",
                                type: "submit",
                                elevation: "10",
                                block: "",
                                color: "primary",
                                loading: _vm.loading,
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }