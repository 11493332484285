var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "retain-focus": false,
            "no-click-animation": "",
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(_vm.pages[_vm.step].component, {
                ref: "component",
                tag: "component",
                attrs: { returning: _vm.returning },
                on: {
                  next: _vm.nextStep,
                  back: _vm.checkBackStep,
                  gotodadosproposta: _vm.goToDadosProposta,
                  completed: _vm.submit,
                  openmenu: function ($event) {
                    _vm.menu = true
                  },
                  verProposta: _vm.resumo,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", right: "", temporary: "", width: "330" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-list",
                    {
                      staticClass: "pa-0",
                      staticStyle: { "border-radius": "0" },
                      attrs: {
                        tile: "",
                        dark: "",
                        elevation: "6",
                        color: "red darken-4",
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "pt-2 pb-2",
                          attrs: { link: "" },
                          on: { click: _vm.leave },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "subtitle-2 mt-1" },
                                [_vm._v("Voltar para home")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { tile: "", dark: "", color: "primary" } },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "title text-center pa-3" },
                        [_vm._v("Contratação")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "scrollbar" },
            [
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("b", [_vm._v("Número")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.dados.nrProposta || "--") + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("b", [_vm._v("Valor total")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(_vm.dados.valorTotalProposta)
                        ) +
                        " "
                    ),
                  ]),
                  _vm.dados.produtosOpcionais != null &&
                  _vm.dados.produtosOpcionais.length > 0
                    ? _c(
                        "v-col",
                        [
                          _c("v-col", { staticClass: "pa-0" }, [
                            _c("b", [_vm._v("Valor total opcional")]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.somarValorTotalOpcional) + " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-2" }),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("b", [_vm._v("Plano selecionado")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.dados.plano &&
                            (_vm.dados.plano.nomeAns ||
                              _vm.dados.plano.nomePlanoAns)) ||
                            "--"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("b", [_vm._v("Planos opcionais selecionados")]),
                      _vm._v(" "),
                      _c("br"),
                      _vm.dados.produtosOpcionais &&
                      _vm.dados.produtosOpcionais.length > 0
                        ? _c(
                            "fragment",
                            _vm._l(
                              _vm.dados.produtosOpcionais,
                              function (produtoOpcional, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(produtoOpcional.nomePlanoAns) +
                                      " "
                                  ),
                                  _c("br"),
                                ])
                              }
                            ),
                            0
                          )
                        : _c("fragment", [_c("span", [_vm._v("--")])]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("b", [_vm._v("Entidade")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.dados.titular && _vm.dados.titular.entidade) ||
                            "--"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("b", [_vm._v("Operadora")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.dados.plano &&
                            _vm.dados.plano.nomeExibicaoOperadora) ||
                            "--"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("b", [_vm._v("Vigência")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.dados.dataVigencia || "--") + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-3", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("b", [_vm._v("Qtd. beneficiário")]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.dados.dependentes &&
                            _vm.dados.dependentes.length + 1) ||
                            0
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }