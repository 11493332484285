<template>
  <validation-observer ref="formTaxa" class="taxa-angariacao" v-if="!loading">
    <div v-if="mostrarOpcoesTaxa || taxaAdesao.cobrarTaxa || ((propostaState.status || '').toUpperCase() !== 'EM_DIGITACAO' && form.taxaFormatada)">
      <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Taxa de angariação</h2>
      <v-divider></v-divider>
    </div>
    <div class="tipo-taxa" v-if="mostrarOpcoesTaxa">
      <validation-provider name="Taxa de Angariação" vid="taxaAngariacao" slim v-slot="{ errors }" rules="required">
        <v-radio-group v-model="form.tipoTaxaAngariacao" :mandatory="false" :error-messages="errors">
          <div v-for="(forma, index) in tiposTaxa" :key="`taga-angariacao-${index}`">
            <v-radio
              :data-test-id="'taxa-'+ index"
              class="mb-4 mt-4"
              :label="forma.nome"
              :value="forma.id"
              :disabled="desabilitarTaxa"
            ></v-radio>
            <v-divider v-if="index === 0"></v-divider>
          </div>
        </v-radio-group>
      </validation-provider>
      <validation-provider name="Justificativa de Isenção" vid="justificativaTaxaAngariacao" slim v-slot="{ errors }" v-if="verificarJustificativa"
      rules="required|max:300">
        <v-textarea
          mode="aggressive"
          :data-test-id="'justificativaTaxaAngariacao'"
          :error-messages="errors"
          id="justificativaTaxaAngariacao"
          name="justificativaTaxaAngariacao"
          counter="300"
          v-model="form.justificativaTaxaAngariacao"
          label="Justificativa da isenção"
          @keydown="limitaCaracteres"
          @input="limitaInputJustificativa"
          clearable
          outlined
          no-resize
        ></v-textarea>
      </validation-provider>
    </div>
    <div v-if="exibirMensagem" class="text-center text-caption">{{mesagemIsencao}}</div>
    <div v-if="
      (taxaAdesao.cobrarTaxa && form.tipoTaxaAngariacao === 'COBRANCA')
      || ((propostaState.status || '').toUpperCase() !== 'EM_DIGITACAO')">
      <p class="caption text-center mt-4" style="line-height: 1.3;" v-if="taxaAdesao.valorTaxa">
        <span class="corretor-nome">
          {{ corretorNome }},
          </span> o valor da taxa de adesão para esse contrato é de
            <strong>{{ taxaAdesao.valorTaxa.max | currency }}</strong><br/>o valor mínimo que pode ser cobrado é de
            <strong>{{ taxaAdesao.valorTaxa.min | currency }}</strong>
      </p>
      <div class="taxa-slider mt-10" v-if="taxaAdesao.valorTaxa && !propostaConcluida">
        <div class="text-center primary--text mb-10"><strong>Seleciona na barra o valor de desconto</strong></div>
        <v-slider
          v-model="form.valueSlider"
          :max="taxaAdesao.valorTaxa.porcentagemMax"
          thumb-label="always"
          @change="taxaFinal"
          @input="taxaFinal"
        >
        <template v-slot:thumb-label="{value}">
          <div class="taxa-slider__porcentagem">{{ value }}%</div>
        </template>
        </v-slider>
      </div>
      <div v-if="taxaAdesao.cobrarTaxa && form.tipoTaxaAngariacao === 'COBRANCA'">
        <base-text-field
          outlined
          label="Valor da Taxa"
          id="taxa-adesao-formatada"
          name="taxa-adesao-formatada"
          v-model="form.taxaFormatada"
          type="text"
          :disabled="true"
          class="mt-4"
        >
        </base-text-field>
        <validation-provider
          :rules="taxaAdesao.valorTaxa ? `required|min_value:0|max_value:${taxaAdesao.valorTaxa.porcentagemMax}` : null"
          mode="aggressive"
          v-slot="{ errors }">
              <input type="hidden"
              v-model="form.valueSlider"
              data-vv-validate-on="change"
              name="taxa de adesão"
              id="taxa-adesao" />
              <div class="v-messages v-messages__message error--text mt-n3">{{ errors[0] }}</div>
          </validation-provider>
          <br/>
        <validation-provider
          :rules="taxaAdesao.valorTaxa ? `required|min_value:${taxaAdesao.valorTaxa.min}|max_value:${taxaAdesao.valorTaxa.max}` : `required`"
          mode="aggressive"
          v-slot="{ errors }">
              <input type="hidden" v-model="form.valorTaxaAdesao"
              data-vv-validate-on="change"
              name="taxa de adesão"
              id="taxa-adesao" />
              <div class="v-messages v-messages__message error--text mt-n3">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </div>
  </validation-observer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import propostaService from '@/services/propostaService';
import isencaoService from '@/services/isencaoTaxaService';
import BaseTextField from '@/components/BaseTextField.vue';
import { VMoney } from 'v-money';

export default {
  name: 'tipo-taxa-angariacao',
  directives: { money: VMoney },
  components: { BaseTextField },
  data() {
    return {
      form: {
        taxaFormatada: 0,
        tipoTaxaAngariacao: null,
        valorTaxaAdesao: null,
        justificativaTaxaAngariacao: null,
      },
      moedaMascara: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      tiposTaxa: [
        {
          id: 'ISENCAO',
          nome: 'ISENTO',
          opcional: 'ISENTO',
        },
        {
          id: 'COBRANCA',
          nome: 'COBRANÇA',
          opcional: 'COBRANÇA (Não Permitida para Portabilidade)',
        },
      ],
      taxaAdesao: {
        cobrarTaxa: false,
      },
      loading: true,
    };
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
      dadoTipoTaxaAngariacao: (state) => state.cadastroProposta.tipoTaxaAngariacao,
      dadoValorTaxaAngariacao: (state) => state.cadastroProposta.valorTaxaAngariacao,
    }),
    ...mapGetters({
      corretorNome: "user/primeiroNome",
    }),
    mostrarOpcoesTaxa() {
      const { corretora } = this.propostaState;
      return corretora.taxaDefinidaPeloCorretor && corretora.necessarioAnexoTaxaAngariacao;
    },
    propostaConcluida() {
      return (this.propostaState.status || '') !== 'EM_DIGITACAO';
    },
    taxaFixada() {
      const { corretora } = this.propostaState;
      const dadosImpressos = (corretora.dadosImpressos || '') === 'QUALICORP';
      return dadosImpressos;
    },
    taxaDefinidaPeloCorretor() {
      const { corretora } = this.propostaState;
      return corretora.taxaDefinidaPeloCorretor;
    },
    desabilitarTaxa() {
      if (this.propostaConcluida || (this.taxaDefinidaPeloCorretor && !this.taxaAdesao.cobrarTaxa && !this.taxaAdesao.corretoraNaoPossuiRegra)) {
        return true;
      }
      return false;
    },
    exibirMensagem() {
      const { corretora } = this.propostaState;
      let mostrarMensagem = false;
      const taxaComMensagem = !this.taxaAdesao.cobrarTaxa && !this.taxaAdesao.corretoraNaoPossuiRegra && (
        corretora.taxaDefinidaPeloCorretor || (!corretora.taxaDefinidaPeloCorretor && this.taxaReciboPadrao() === 'COBRANCA'));

      if (taxaComMensagem && (this.propostaState.status || '').toUpperCase() === 'EM_DIGITACAO') {
        mostrarMensagem = true;
      } else if (taxaComMensagem && this.taxaAdesao.isentoDevolvida) {
        mostrarMensagem = true;
      }

      return mostrarMensagem;
    },
    necessarioAnexoTaxaAngariacao() {
      const { corretora } = this.propostaState;
      return corretora.necessarioAnexoTaxaAngariacao;
    },
    verificarJustificativa() {
      if (this.propostaState.corretora.taxaDefinidaPeloCorretor
          && this.taxaAdesao.cobrarTaxa
          && this.form.tipoTaxaAngariacao === "ISENCAO") {
        return true;
      }
      return false;
    },
    mesagemIsencao() {
      if (this.taxaAdesao.isentoDevolvida) {
        return this.propostaState.isentoPor;
      }
      if (this.taxaAdesao.isentoPorCPF) {
        return `Proposta isenta pelo usuário ${this.taxaAdesao.usuario}, utilizando o fluxo de exceção de isenção de taxa por CPF`;
      }
      if (this.taxaAdesao.isentoPorEntidade) {
        return "Taxa de adesão isenta, entidade/operadora não permite cobrança";
      }
      return "Cliente não elegível a cobrança de taxa";
    },
  },
  mounted() {
    this.getTaxaAdesao();
  },
  watch: {
    dadoTipoTaxaAngariacao: {
      immediate: true,
      handler: 'setTaxaAngariacao',
      deep: true,
    },
    dadoValorTaxaAngariacao: {
      immediate: true,
      handler: 'setDadoValorTaxaAngariacao',
      deep: true,
    },
  },
  methods: {
    async getTaxaAdesao() {
      this.form.tipoTaxaAngariacao = this.taxaReciboPadrao();
      const mostrarTaxaAdesao = this.mostrarOpcoesTaxa || this.taxaReciboPadrao() === 'COBRANCA';
      this.preecherJustificativa();
      if (mostrarTaxaAdesao && (this.propostaState.status || '') === 'EM_DIGITACAO') {
        const res = await propostaService.taxaValidarCobranca({ guidProposta: this.propostaState.guid });
        let porPorcentagem = 0;
        if (res.cobrarTaxa) {
          const { min, max, porcentagemMax } = res.valorTaxa;
          const diferencaValores = max - min;
          const { valorTaxaAdesao } = this.form;
          porPorcentagem = diferencaValores / porcentagemMax;
          if (valorTaxaAdesao === 0 || !valorTaxaAdesao || valorTaxaAdesao > max || valorTaxaAdesao < min) {
            this.$set(this.form, 'valorTaxaAdesao', max);
            this.$set(this.form, 'valueSlider', 0);
            this.formatarDinheiro(max);
          } else {
            const diferenca = max - valorTaxaAdesao;
            const valor = (diferenca / porPorcentagem).toFixed(2);
            this.$set(this.form, 'valueSlider', valor);
            this.formatarDinheiro(valor);
          }
        }
        this.taxaAdesao = {
          ...res,
          porPorcentagem,
        };
        if (!res.cobrarTaxa && !res.corretoraNaoPossuiRegra) {
          this.form.tipoTaxaAngariacao = 'ISENCAO';
        }
      } else {
        const temExcecao = this.propostaState.isentoPor;

        if (temExcecao) {
          this.taxaAdesao.isentoDevolvida = true;
        }
      }
      this.loading = false;
      this.$emit('loaded');
    },
    taxaReciboPadrao() {
      const { taxaReciboPadrao, taxaDefinidaPeloCorretor } = this.propostaState.corretora;
      const taxaSelecionada = this.necessarioAnexoTaxaAngariacao ? this.dadoTipoTaxaAngariacao : null;
      return taxaReciboPadrao && !taxaDefinidaPeloCorretor ? taxaReciboPadrao.toUpperCase() : taxaSelecionada;
    },
    converteTaxaAdesao(val, ok) {
      val = val.replace(".", "");
      val = val.replace(",", ".");
      this.$set(this.form, 'valorTaxaAdesao', parseFloat(val));
    },
    setTaxaAngariacao() {
      this.form.tipoTaxaAngariacao = this.dadoTipoTaxaAngariacao;
    },
    setDadoValorTaxaAngariacao() {
      this.form.valorTaxaAdesao = this.dadoValorTaxaAngariacao;
      if (this.dadoValorTaxaAngariacao > 0) {
        this.formatarDinheiro(this.dadoValorTaxaAngariacao);
      }
    },
    formatarDinheiro(valor) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      this.$set(this.form, 'taxaFormatada', formatter.format(valor));
    },
    async salvar() {
      const isValid = await this.$refs.formTaxa.validate();
      if (!isValid) return false;
      let { tipoTaxaAngariacao, valorTaxaAdesao, justificativaTaxaAngariacao } = this.form;
      tipoTaxaAngariacao = (tipoTaxaAngariacao || '').toUpperCase();
      valorTaxaAdesao = valorTaxaAdesao !== 0 && tipoTaxaAngariacao === 'COBRANCA' ? valorTaxaAdesao : null;
      justificativaTaxaAngariacao = tipoTaxaAngariacao === 'COBRANCA' ? null : justificativaTaxaAngariacao;
      if (!this.necessarioAnexoTaxaAngariacao) {
        tipoTaxaAngariacao = null;
        valorTaxaAdesao = null;
      }
      return {
        tipoTaxaAngariacao,
        valorTaxaAdesao,
        justificativaTaxaAngariacao,
      };
    },
    taxaFinal() {
      const { porPorcentagem, valorTaxa } = this.taxaAdesao;
      const valorFinal = valorTaxa.max - this.form.valueSlider * porPorcentagem;
      this.$set(this.form, 'valorTaxaAdesao', valorFinal.toFixed(2));
      this.formatarDinheiro(valorFinal.toFixed(2));
    },
    preecherJustificativa() {
      this.form.justificativaTaxaAngariacao = this.propostaState.justificativaTaxaAngariacao;
    },
    limitaCaracteres(evt) {
      if (evt.target.value.length >= 300) {
        if ((evt.keyCode >= 48 && evt.keyCode <= 90) || evt.keyCode === 32 || evt.keyCode === 13) {
          this.form.justificativaTaxaAngariacao = this.form.justificativaTaxaAngariacao.substr(0, 300);
          evt.preventDefault();
        }
      }
    },
    limitaInputJustificativa(valor) {
      if (this.form.justificativaTaxaAngariacao.length >= 300) {
        this.form.justificativaTaxaAngariacao = valor.substr(0, 300);
      }
    },
  },
};
</script>

<style lang="scss">
$slider-thumb-label-height: 200px!important;
@import '~vuetify/src/styles/styles.sass';
</style>
