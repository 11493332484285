var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card-details", {
    staticClass: "d-flex flex-column plano-item",
    attrs: { title: _vm.nome },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-list-item-avatar",
              {
                staticClass: "mr-2 mt-0 mb-0",
                attrs: { dark: "", size: "50", tile: "" },
              },
              [
                _c("v-img", {
                  attrs: { width: "100%", contain: "", src: _vm.logo },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "6" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Operadora"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "text-uppercase d-block text-truncate" },
                      [_vm._v(_vm._s(_vm.operadora))]
                    ),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "6" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "secundaryBtn--text",
                          attrs: { color: "primary", label: "" },
                        },
                        [
                          _c("div", [
                            _vm.valorTotalOdonto
                              ? _c(
                                  "span",
                                  { staticClass: "mt-1 text-uppercase" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          parseFloat(_vm.valor) +
                                            parseFloat(_vm.valorTotalOdonto)
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "mt-1 text-uppercase" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("currency")(_vm.valor))
                                    ),
                                  ]
                                ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.valor < _vm.valorSemDesconto
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-end align-start" },
                        [
                          _c("div", { staticClass: "mx-2" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mx-2 text-decoration-line-through text-caption grey--text",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(_vm.valorSemDesconto)
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Abrangência"),
                    ]),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(_vm.abrangencia)),
                    ]),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Acomodação"),
                    ]),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(_vm.acomodacao)),
                    ]),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Segmentação"),
                    ]),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(_vm.segmentacao)),
                    ]),
                  ]),
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _vm.coparticipacao
                      ? _c("v-chip", { staticClass: "px-3 mr-2 my-2" }, [
                          _vm._v("Coparticipação"),
                        ])
                      : _vm._e(),
                    _vm.modeloCoparticipacao
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "px-3 mr-2 my-2",
                            attrs: { color: "#00cfb6" },
                          },
                          [_vm._v("Copart " + _vm._s(_vm.modeloCoparticipacao))]
                        )
                      : _vm._e(),
                    _vm.valorTotalOdonto
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "px-3 mr-2 my-2",
                            attrs: { color: "#FFB500" },
                          },
                          [
                            _vm._v(
                              "Plano Odonto de " +
                                _vm._s(
                                  _vm._f("currency")(_vm.valorTotalOdonto)
                                ) +
                                " já incluso no valor do plano"
                            ),
                          ]
                        )
                      : _vm.odonto
                      ? _c("v-chip", { staticClass: "px-3 my-2" }, [
                          _vm._v("Com Odonto"),
                        ])
                      : _vm._e(),
                    _c("v-chip", {
                      staticClass: "px-3 my-2",
                      attrs: { color: "transparent" },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _vm.dataInicioProximaTabela && _vm.valorProximaTabela
                      ? _c(
                          "v-chip",
                          {
                            staticClass:
                              "px-3 my-2 white--text col-12 justify-center",
                            attrs: { color: "light-blue" },
                          },
                          [
                            _vm._v(
                              " A partir de " +
                                _vm._s(_vm.dataInicioProximaTabela) +
                                " custará R$ " +
                                _vm._s(
                                  _vm._f("currency")(_vm.valorProximaTabela)
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.captacao
                  ? _c("v-col", { staticClass: "font-weight-bold" }, [
                      _vm._v(" Captação "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-row",
              {
                staticClass: "d-flex no-wrap col-12 pa-0",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0", attrs: { cols: "5" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: { text: "", block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("detalhes")
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-eye-outline"),
                        ]),
                        _c("span", { staticClass: "mt-1 subtitle-2" }, [
                          _vm._v("Ver detalhes"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c("v-divider", { attrs: { vertical: "" } }),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { staticClass: "pa-0", attrs: { cols: "5" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: {
                          "data-test-id": `selecionar-plano-${_vm.index}`,
                          text: "",
                          block: "",
                          loading: _vm.loading && _vm.loadingSelecionar,
                        },
                        on: { click: _vm.planoSelecionado },
                      },
                      [
                        _c("span", { staticClass: "mt-1 subtitle-2" }, [
                          _vm._v("Selecionar"),
                        ]),
                        _c("v-icon", { attrs: { right: "" } }, [
                          _vm._v("mdi-menu-right"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }