<template>
  <fragment>
    <slot :data="bancos" :loading="loading"></slot>
  </fragment>
</template>

<script>
import bancosService from '@/services/bancosService';
import pagamentosService from '@/services/pagamentosService';

export default {
  name: 'AsyncBancos',
  props: ['tipoDadosFinanceiros', 'idSinf', 'formaPagamento'],
  data() {
    return {
      loading: false,
      bancos: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        if (this.tipoDadosFinanceiros && this.tipoDadosFinanceiros.toLowerCase() === 'cobranca') {
          if (this.idSinf && this.formaPagamento) {
            const listBancos = await pagamentosService.getBancosPagamentoPorId({ idSinf: this.idSinf, formaPagamento: this.formaPagamento });
            this.bancos = listBancos.length > 0 ? listBancos[0].bancos : [];
          } else {
            this.bancos = [];
          }
        }

        if (this.tipoDadosFinanceiros && this.tipoDadosFinanceiros.toLowerCase() === 'reembolso') {
          this.bancos = await bancosService.getBancos();
        }

        this.$emit('success', this.bancos);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};

</script>

<style>

</style>
