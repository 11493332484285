import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'carencias';
const listaOpcoesCarencia = async ({ idProdutoFatura, dataVigencia }) => {
  try {
    const config = {
      params: {
        dataVigencia,
      },
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_CARENCIAS_API_KEY }
    };
    const { data } = await api.get(`${resource}/${idProdutoFatura}`, config);

    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  listaOpcoesCarencia,
};
