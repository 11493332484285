import filters from '@/filters';

function createID() {
  return Math.random().toString(36).substr(2, 9);
}
export const dependenteFactory = ({
  guid = '',
  cpf = '',
  nome = '',
  dataNascimento = '',
  parentesco = '',
  estadoCivil = '',
  sexo = '',
  nomeMae = '',
  pisPasep = '',
  aceitouNaoReduzirCarencia = false,
  rgDataExpedicao = '',
  check = false,
} = {}) => ({
  guid,
  cpf,
  nome,
  dataNascimento: dataNascimento ? filters.convertDateInput(dataNascimento) : '',
  parentesco,
  estadoCivil,
  sexo,
  nomeMae,
  pisPasep,
  aceitouNaoReduzirCarencia,
  check,
  rgDataExpedicao: rgDataExpedicao ? filters.convertDateInput(rgDataExpedicao) : '',
});
