/* eslint-disable no-restricted-syntax */
<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Portabilidade Beneficiários</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados portabilidade</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <validation-observer ref="form" tag="form" class="mb-10" @submit.prevent="submit" v-else>
        <v-row justify="center">
          <v-col class="col-12 col-md-6">
            <div v-for="(beneficiario, index) in getBeneficiarios" :key="beneficiario.nome + index">
              <v-row>
                <v-col class="d-flex align-center col-6 pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">{{ beneficiario.nome }}</h2>
                </v-col>
                <v-col class="col-6 pb-0">
                  <validation-provider name="Portabilidade" :vid="beneficiario.guid + '_portabilidade'" v-slot="{ errors }" rules="required">
                    <v-radio-group
                      class="justify-end-radio-group"
                      row
                      v-model="form[beneficiario.guid].portabilidade"
                      @change="changePortabilidade($event, beneficiario.guid)"
                      :value-comparator="valueCompare"
                      :mandatory="false"
                      :error-messages="errors"
                    >
                      <v-radio class="mb-4" :class="{ 'mt-4': index > 0 }" label="Sim" :value="true" :key="beneficiario.guid + '_option_true'"></v-radio>
                      <v-radio class="mb-4" :class="{ 'mt-4': index > 0 }" label="Não" :value="false" :key="beneficiario.guid + '_option_false'"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="form[beneficiario.guid].portabilidade" class="mt-0">
                <v-col class="col-md-12">                               
                  <div v-if="propostaState.plano.operadora.toLowerCase() === 'unimed-rio'">                    
                    <validation-provider
                      :ref="'validation_operadoraanterior_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Nome da operadora anterior?"
                      :vid="'validation_operadoraanterior_' + beneficiario.guid"
                      rules="required"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Nome da operadora anterior? *"
                        :error-messages="errors"
                        name="nomeOperadoraAnteriorPortabilidade"
                        :value="form[beneficiario.guid].nomeOperadoraAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].nomeOperadoraAnteriorPortabilidade"
                        :ref="'text_operadoraanterior_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider
                      :ref="'validation_registroansoperadoraanterior_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Registro ANS da operadora anterior?"
                      :vid="'validation_registroansoperadoraanterior_' + beneficiario.guid"
                      rules="required"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Registro ANS da operadora anterior? *"
                        :error-messages="errors"
                        name="registroAnsOperadoraAnteriorPortabilidade"
                        :value="form[beneficiario.guid].registroAnsOperadoraAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].registroAnsOperadoraAnteriorPortabilidade"
                        :ref="'text_registroansoperadoraanterior_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider
                      :ref="'validation_nomeplanoanterior_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Nome do plano anterior?"
                      :vid="'validation_nomeplanoanterior_' + beneficiario.guid"
                      rules="required"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Nome do plano anterior? *"
                        :error-messages="errors"
                        name="nomePlanoAnteriorPortabilidade"
                        :value="form[beneficiario.guid].nomePlanoAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].nomePlanoAnteriorPortabilidade"
                        :ref="'text_nomeplanoanterior_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider
                      :ref="'validation_registroansplanoanterior_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Registro ANS do plano anterior?"
                      :vid="'validation_registroansplanoanterior_' + beneficiario.guid"
                      rules="required"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Registro ANS do plano anterior? *"
                        :error-messages="errors"
                        name="registroAnsPlanoAnteriorPortabilidade"
                        :value="form[beneficiario.guid].registroAnsPlanoAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].registroAnsPlanoAnteriorPortabilidade"
                        :ref="'text_registroansplanoanterior_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <async-segmentacoes :key="'c_segmentacoes-'" v-slot="{ loading: loadingSegmentacoes, data }">
                    <base-wrapper-dialog-list :key="'d_segmentacoes'" headerTitle="Segmentações" :options="data" :loading="loadingSegmentacoes" @select="selectSegmentacao($event, beneficiario.guid)">
                      <template v-slot:activador="{ on }">
                        <validation-provider
                          :ref="'validation_segmentacao_' + beneficiario.guid"
                          slim
                          v-slot="{ errors }"
                          :name="'Segmentação'"
                          :vid="'validation_segmentacao_' + beneficiario.guid"
                          rules="required"
                          :mode="'eager'"
                        >
                          <v-text-field
                            :dark="false"
                            data-vv-validate-on="input"
                            v-on="on"
                            outlined
                            readonly
                            append-icon="mdi-chevron-right"
                            label="Segmentação Anterior"
                            :error-messages="errors"
                            :name="'segmentacao_' + beneficiario.guid"
                            :value="form[beneficiario.guid].segmentacaoAnterior"
                            :ref="'text_segmentacao_' + beneficiario.guid"
                            :loading="loadingSegmentacoes"
                          >
                            <template v-slot:append>
                              <slot></slot>
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                    </base-wrapper-dialog-list>
                  </async-segmentacoes>
                  <div v-if="propostaState.plano.operadora.toLowerCase() === 'unimed-rio'">
                    <validation-provider
                      :ref="'validation_datainicioplanoanterior_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Data ínicio do plano anterior?"
                      :vid="'validation_datainicioplanoanterior_' + beneficiario.guid"
                      rules="required|date"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Data ínicio do plano anterior? *"
                        :error-messages="errors"
                        placeholder="DD/MM/AAAA"
                        v-mask="'##/##/####'"
                        name="dataInicioPlanoAnteriorPortabilidade"
                        :value="form[beneficiario.guid].dataInicioPlanoAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].dataInicioPlanoAnteriorPortabilidade"
                        :ref="'text_datainicioplanoanterior_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider
                      :ref="'validation_dataultimopagamento_' + beneficiario.guid"
                      slim
                      v-slot="{ errors }"
                      name="Último pagamento do plano anterior?"
                      :vid="'validation_dataultimopagamento_' + beneficiario.guid"
                      rules="required|date"
                      mode="eager"
                    >
                      <v-text-field
                        :dark="false"
                        data-vv-validate-on="input"
                        outlined
                        clearable
                        label="Último pagamento do plano anterior? *"
                        :error-messages="errors"
                        placeholder="DD/MM/AAAA"
                        v-mask="'##/##/####'"
                        name="ultimoPagamentoPlanoAnteriorPortabilidade"
                        :value="form[beneficiario.guid].ultimoPagamentoPlanoAnteriorPortabilidade"
                        v-model="form[beneficiario.guid].ultimoPagamentoPlanoAnteriorPortabilidade"
                        :ref="'text_dataultimopagamento_' + beneficiario.guid"                        
                      >
                        <template v-slot:append>
                          <slot></slot>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <base-wrapper-dialog-options
                      headerTitle="Exerceu portabilidade ? *"
                      :options="[
                        { id: 'Sim', nome: 'Sim', value: 'Sim' },
                        { id: 'Não', nome: 'Não', value: 'Não' },
                      ]"
                      @select="$set(form[beneficiario.guid], 'exerceuPortabilidade', $event.id)"
                      value-key="value"
                      label-key="nome"
                    >
                      <template v-slot:activador="{ on }">
                        <base-text-field
                          readonly
                          clearable
                          v-on="on"
                          rules="required"
                          id="exerceuPortabilidade"
                          name="Exerceu portabilidade ?"
                          :value="form[beneficiario.guid].exerceuPortabilidade"
                          v-model="form[beneficiario.guid].exerceuPortabilidade"
                          placeholder="Clique aqui para selecionar"
                          label="Exerceu portabilidade ? *"
                          outlined
                          append-icon="mdi-chevron-right"
                        ></base-text-field>
                      </template>
                    </base-wrapper-dialog-options>
                    <base-wrapper-dialog-options
                      headerTitle="Cumpriu ou está cumprindo CPT ? *"
                      :options="[
                        { id: 'Sim', nome: 'Sim', value: 'Sim' },
                        { id: 'Não', nome: 'Não', value: 'Não' },
                      ]"
                      @select="$set(form[beneficiario.guid], 'cumpriuOuCumprindoCPTPortabilidade', $event.id)"
                      value-key="value"
                      label-key="nome"
                    >
                      <template v-slot:activador="{ on }">
                        <base-text-field
                          readonly
                          clearable
                          v-on="on"
                          rules="required"
                          id="cumpriuOuCumprindoCPTPortabilidade"
                          name="Cumpriu ou está cumprindo CPT ?"
                          :value="form[beneficiario.guid].cumpriuOuCumprindoCPTPortabilidade"
                          v-model="form[beneficiario.guid].cumpriuOuCumprindoCPTPortabilidade"
                          placeholder="Clique aqui para selecionar"
                          label="Cumpriu ou está cumprindo CPT ? *"
                          outlined
                          append-icon="mdi-chevron-right"
                        ></base-text-field>
                      </template>
                    </base-wrapper-dialog-options>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-divider :key="beneficiario.guid + '_divider'" v-if="index < getBeneficiarios.length - 1"></v-divider>
              </v-row>
            </div>
            <v-row no-gutters>
              <v-col>
                <v-btn :loading="loadingSalvar" type="submit" elevation="10" block color="primary" class="secundaryBtn--text mt-4">Salvar e continuar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import propostaService from "@/services/propostaService";
import AsyncSegmentacoes from "@/components/AsyncSegmentacoes.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";

export default {
  name: "Corretoras",
  components: {
    AsyncSegmentacoes,
    BaseWrapperDialogList,
    BaseTextField,
    BaseWrapperDialogOptions
  },
  data() {
    return {
      loading: false,
      loadingSalvar: false,
      segmentacoes: [],
      errorSegmentacoes: [],
      form: {},
    };
  },
  computed: {
    ...mapState({
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    selectedCorretora() {
      return {};
    },
    getBeneficiarios() {
      const dependentesExistentes = this.propostaState.dependentes.filter((dep) => dep.nome && dep.guid);
      return [this.propostaState.titular, ...dependentesExistentes];
    },
  },
  watch: {
    propostaState: {
      immediate: true,
      handler: "loadBeneficiarios",
      deep: true,
    },
  },
  created() {
    this.loadBeneficiarios();
  },
  methods: {
    ...mapActions({
      setCorretora: "cadastroProposta/setCorretora",
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setIdFilial: "cadastroProposta/setIdFilial",
      setDadosDependentes: "cadastroProposta/setDadosDependentes",
      setDadosTitular: "cadastroProposta/setDadosTitular",
    }),
    changePortabilidade(option, guid) {
      if (!option) {
        this.form[guid].segmentacaoAnterior = "";
      }
      this.$forceUpdate();
    },
    loadBeneficiarios() {
      const beneficiarios = this.getBeneficiarios;
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, benef] of beneficiarios.entries()) {
        if (!this.form[benef.guid]) {
          const pessoa = index === 0 ? this.propostaState.titular : this.propostaState.dependentes[index - 1];
          this.form[benef.guid] = {
            segmentacaoAnterior: pessoa.segmentacaoAnterior || "",
            portabilidade: pessoa.portabilidade ?? undefined,
          };
          if (this.propostaState.plano.operadora.toLowerCase() === 'unimed-rio') {
            this.form[benef.guid].nomeOperadoraAnteriorPortabilidade = pessoa.nomeOperadoraAnteriorPortabilidade || '',
            this.form[benef.guid].registroAnsOperadoraAnteriorPortabilidade = pessoa.registroAnsOperadoraAnteriorPortabilidade || '',
            this.form[benef.guid].nomePlanoAnteriorPortabilidade = pessoa.nomePlanoAnteriorPortabilidade || '',
            this.form[benef.guid].registroAnsPlanoAnteriorPortabilidade = pessoa.registroAnsPlanoAnteriorPortabilidade || '',
            this.form[benef.guid].dataInicioPlanoAnteriorPortabilidade = pessoa.dataInicioPlanoAnteriorPortabilidade || '',
            this.form[benef.guid].ultimoPagamentoPlanoAnteriorPortabilidade = pessoa.ultimoPagamentoPlanoAnteriorPortabilidade || '',
            this.form[benef.guid].exerceuPortabilidade = pessoa.exerceuPortabilidade || '',
            this.form[benef.guid].cumpriuOuCumprindoCPTPortabilidade = pessoa.cumpriuOuCumprindoCPTPortabilidade || ''
          }
        }
      }
      this.$forceUpdate();
    },
    selectSegmentacao($event, guid) {
      this.form[guid].segmentacaoAnterior = $event;
    },
    updateDadosBeneficiarios() {
      const beneficiarios = this.getBeneficiarios;
      let titular = {};
      const dependentes = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, benef] of beneficiarios.entries()) {
        if (index === 0) {
          titular = { ...benef };
          titular.portabilidade = this.form[benef.guid].portabilidade;
          titular.segmentacaoAnterior = this.form[benef.guid].segmentacaoAnterior;
          if (this.propostaState.plano.operadora.toLowerCase() === 'unimed-rio') {
            titular.nomeOperadoraAnteriorPortabilidade = this.form[benef.guid].nomeOperadoraAnteriorPortabilidade
            titular.registroAnsOperadoraAnteriorPortabilidade = this.form[benef.guid].registroAnsOperadoraAnteriorPortabilidade
            titular.nomePlanoAnteriorPortabilidade = this.form[benef.guid].nomePlanoAnteriorPortabilidade
            titular.registroAnsPlanoAnteriorPortabilidade = this.form[benef.guid].registroAnsPlanoAnteriorPortabilidade
            titular.dataInicioPlanoAnteriorPortabilidade = this.form[benef.guid].dataInicioPlanoAnteriorPortabilidade
            titular.ultimoPagamentoPlanoAnteriorPortabilidade = this.form[benef.guid].ultimoPagamentoPlanoAnteriorPortabilidade
            titular.exerceuPortabilidade = this.form[benef.guid].exerceuPortabilidade
            titular.cumpriuOuCumprindoCPTPortabilidade = this.form[benef.guid].cumpriuOuCumprindoCPTPortabilidade
          }
        } else {
          const dependente = { ...benef };
          dependente.portabilidade = this.form[benef.guid].portabilidade;
          dependente.segmentacaoAnterior = this.form[benef.guid].segmentacaoAnterior;
          if (this.propostaState.plano.operadora.toLowerCase() === 'unimed-rio') {
            dependente.nomeOperadoraAnteriorPortabilidade = this.form[benef.guid].nomeOperadoraAnteriorPortabilidade
            dependente.registroAnsOperadoraAnteriorPortabilidade = this.form[benef.guid].registroAnsOperadoraAnteriorPortabilidade
            dependente.nomePlanoAnteriorPortabilidade = this.form[benef.guid].nomePlanoAnteriorPortabilidade
            dependente.registroAnsPlanoAnteriorPortabilidade = this.form[benef.guid].registroAnsPlanoAnteriorPortabilidade
            dependente.dataInicioPlanoAnteriorPortabilidade = this.form[benef.guid].dataInicioPlanoAnteriorPortabilidade
            dependente.ultimoPagamentoPlanoAnteriorPortabilidade = this.form[benef.guid].ultimoPagamentoPlanoAnteriorPortabilidade
            dependente.exerceuPortabilidade = this.form[benef.guid].exerceuPortabilidade
            dependente.cumpriuOuCumprindoCPTPortabilidade = this.form[benef.guid].cumpriuOuCumprindoCPTPortabilidade
          }
          dependentes.push(dependente);
        }
      }
      this.setDadosDependentes(dependentes);
      this.setDadosTitular({ ...titular });
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      this.updateDadosBeneficiarios();
      this.setDadosProposta({
        ...this.propostaState,
      });
      // this.setIdFilial(this.selectedCorretora.idFilial);

      if (this.blnContinuarState.isEditing || this.blnContinuarState.isEditingVigencia) {
        const verificacaoEtapaDevolvida = this.propostaState.status !== "DEVOLVIDA";
        // seta a etapa como portabilidade, caso seja do fluxao de edição de vigência e não sendo o status "DEVOLVIDA"
        if (this.blnContinuarState.isEditingVigencia && verificacaoEtapaDevolvida) {
          this.setDadosProposta({
            ...this.propostaState,
            etapa: 'portabilidade',
          });
        } else {
          // não seta a etapa
          this.setDadosProposta({ ...this.propostaState });
        }
      } else if (this.propostaState.plano.possuiPlanosOpcionais === true) {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: 'produto_opcional',
        });
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: 'dados_financeiros',
        });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);

      if (data && data.guid) {
        this.setDadosProposta({
          ...this.propostaState,
          guid: data.guid,
          status: data.status,
        });
      }
    },
    valueCompare(a, b) {
      return Object.is(a, b);
    },
  },
};
</script>

<style lang="scss">
.justify-end-radio-group div[role="alert"] {
  text-align: right !important;
}
.justify-end-radio-group div[role="radiogroup"] {
  justify-content: flex-end !important;
}
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
