<template>
  <base-card-details :title="nome" class="d-flex flex-column plano-item">
    <template v-slot:header class="flex-grow-0">
      <v-list-item-avatar dark size="50" tile class="mr-2 mt-0 mb-0">
        <v-img width="100%" contain :src="logo"></v-img>
      </v-list-item-avatar>
    </template>
    <template v-slot:body class="flex-grow-1">
      <v-row no-gutters>
        <v-col cols="6" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Operadora</h3>
            <span class="text-uppercase d-block text-truncate">{{operadora}}</span>
          </div>
        </v-col>
        <v-col cols="6" class="mb-2">
          <div class="d-flex justify-end">
            <v-chip color="primary" class="secundaryBtn--text" label>
              <div>
                <span v-if="valorTotalOdonto" class="mt-1 text-uppercase">{{parseFloat(valor) + parseFloat(valorTotalOdonto) | currency}}</span>
                <span v-else class="mt-1 text-uppercase">{{valor | currency}}</span>
              </div>
            </v-chip>
          </div>
          <div class="d-flex justify-end align-start" v-if="valor < valorSemDesconto">
            <div class="mx-2">
              <span class="mx-2 text-decoration-line-through text-caption grey--text">{{valorSemDesconto | currency}}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Abrangência</h3>
            <span class="text-uppercase">{{abrangencia}}</span>
          </div>
        </v-col>
        <v-col cols="4" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Acomodação</h3>
            <span class="text-uppercase">{{acomodacao}}</span>
          </div>
        </v-col>
        <!-- <v-col cols="4" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Coparticipação</h3>
            <span class="text-uppercase">{{coparticipacao | simOuNao}}</span>
          </div>
        </v-col> -->
        <v-col cols="12" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Segmentação</h3>
            <span class="text-uppercase">{{segmentacao}}</span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-chip class="px-3 mr-2 my-2" v-if="coparticipacao">Coparticipação</v-chip>
          <v-chip class="px-3 mr-2 my-2" v-if="modeloCoparticipacao" color="#00cfb6">Copart {{modeloCoparticipacao}}</v-chip>
          <v-chip class="px-3 mr-2 my-2" color="#FFB500" v-if="valorTotalOdonto">Plano Odonto de {{valorTotalOdonto | currency}} já incluso no valor do plano</v-chip>
          <v-chip class="px-3 my-2" v-else-if="odonto">Com Odonto</v-chip>
          <v-chip class="px-3 my-2" color="transparent"></v-chip>
        </v-col>
        <v-col cols="12">
          <v-chip class="px-3 my-2 white--text col-12 justify-center" color="light-blue" v-if="dataInicioProximaTabela && valorProximaTabela">
            A partir de {{ dataInicioProximaTabela }} custará R$ {{ valorProximaTabela | currency }}
          </v-chip>
        </v-col>
        <v-col v-if="captacao" class=font-weight-bold>
          Captação
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer class="flex-grow-0">
      <v-row no-gutters class="d-flex no-wrap col-12 pa-0">
        <v-col cols="5" class="pa-0">
          <v-btn text block class="text-capitalize" @click="$emit('detalhes')">
            <v-icon left>mdi-eye-outline</v-icon>
            <span class="mt-1 subtitle-2">Ver detalhes</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="5" class="pa-0">
          <v-btn :data-test-id="`selecionar-plano-${index}`" text block class="text-capitalize" :loading="loading && loadingSelecionar" @click="planoSelecionado">
            <span class="mt-1 subtitle-2">Selecionar</span>
            <v-icon right>mdi-menu-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </base-card-details>
</template>

<script>
import BaseCardDetails from '@/components/BaseCardDetails.vue';

export default {
  components: {
    BaseCardDetails,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    abrangencia: {
      type: String,
      default: '--',
    },
    acomodacao: {
      type: String,
      default: '--',
    },
    coparticipacao: Boolean,
    modeloCoparticipacao: {
      type: String,
      default: '',
    },
    odonto: Boolean,
    valorTotalOdonto: {
      type: Number,
      default: 0,
    },
    logo: {
      type: String,
      required: true,
    },
    nome: {
      type: String,
      default: '--',
    },
    operadora: {
      type: String,
      default: '--',
    },
    segmentacao: {
      type: String,
      default: '--',
    },
    loadingSelecionar: {
      type: Boolean,
      default: false,
    },
    captacao: {
      type: Boolean,
      default: false
    },
    valor: {
      default: 0,
    },
    valorSemDesconto: {
      default: 0,
    },
    valorProximaTabela: {
      default: 0,
    },
    dataInicioProximaTabela: {
      default: 0,
    },
  },
  methods: {
    planoSelecionado() {
      this.loading = true;
      this.$emit('selecionar');
    },
  },
};
</script>

<style lang="scss" scoped>
.plano-item {
  height: 100%;
}
</style>
