<template>
  <fragment>
    <slot :data="endereco" :loading="loading"></slot>
  </fragment>
</template>

<script>
import catalogoService from '@/services/catalogoService';
import filters from '@/filters';

export default {
  name: 'AsyncCepComercial',
  props: {
    cep: {
      required: true,
    },
    loadingMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      endereco: [],
    };
  },
  watch: {
    cep(newValue, oldValue) {
      const nvalue = filters.removeSymbols(newValue);
      const ovalue = filters.removeSymbols(oldValue);
      if (nvalue !== ovalue) this.getData(nvalue);
    },
  },
  methods: {
    async getData(cep) {
      if (!cep) return;
      if (this.loading) return;
      if (cep.length === 8) {
        this.$emit('valid');
        try {
          this.loading = true;
          const data = await catalogoService.getEndereco({ cep });
          this.endereco = data;
          this.$emit('success', data);
          if (this.loadingMounted) return;
          this.$root.$snackBar.open({
            color: 'success',
            message: `${data.logradouro ? `${data.logradouro}, ` : ' '}${data.bairro ? `${data.bairro}- ` : ' '}${data.cidade}`,
          });
        } catch (error) {
          this.$root.$snackBar.open({ color: 'error', message: error.message });
          this.$emit('error');
        } finally {
          this.loading = false;
        }
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
</style>
