<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Dados financeiros</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-4">
      <v-row justify="center">
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <validation-observer ref="form" tag="form" @submit.prevent="submit">
            <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Forma de pagamento</h2>
            <v-divider></v-divider>
            <validation-provider name="Forma de pagamento" vid="formaPagamento" slim v-slot="{ errors }" rules="required">
              <v-radio-group v-model="form.pagamento.forma" :mandatory="false" :error-messages="errors">
                <div v-for="(forma, index) in formasPagamento" :key="index" @click.prevent="removeCobranca">
                  <v-radio :data-test-id="'forma-pagamento-' + index" class="mb-4 mt-4" :label="forma.nome" :value="forma.id"></v-radio>
                  <v-divider v-if="index < formasPagamento.length - 1"></v-divider>
                </div>
              </v-radio-group>
            </validation-provider>

            <div v-if="form.pagamento.forma != null && form.pagamento.forma === 'Débito'">
              <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Dados para cobrança</h2>
              <v-divider></v-divider>
              <async-list-bancos
                :idSinf="idSinf"
                tipoDadosFinanceiros="cobranca"
                :formaPagamento="form.pagamento.forma"
                v-slot="{ loading: loadingBancosDebito, data }"
                @success="onSucessBancoPagamento"
              >
                <base-wrapper-dialog-list
                  headerTitle="Bancos"
                  :options="data"
                  label-key="nome"
                  value-key="id"
                  :loading="loadingBancosDebito"
                  :searchByValue="form.pagamento.codBanco && !bancosSelecionados.pagamento.nome ? form.pagamento.codBanco : ''"
                  @select="bancoSelecionado('pagamento', $event)"
                  returnObject
                >
                  <template v-slot:activador="{ on }">
                    <base-text-field
                      rules="required"
                      id="cobrancaBanco"
                      :data-test-id="'banco-selecao'"
                      name="Banco"
                      readonly
                      :loading="loadingBancosDebito"
                      :value="bancosSelecionados.pagamento.nome"
                      placeholder="Clique aqui para selecionar"
                      label="Banco *"
                      v-on="on"
                      outlined
                      append-icon="mdi-chevron-right"
                      class="mt-5"
                    >
                      <div class="d-flex align-center" style="top: -2px; position: relative">
                        <v-icon>mdi-chevron-right</v-icon>
                        <v-btn color="red" v-if="form.pagamento.codBanco" small icon class="ml-2" @click.prevent="removeCobranca">
                          <v-icon size="25" class="ma-0 pa-0">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </base-text-field>
                  </template>
                </base-wrapper-dialog-list>
              </async-list-bancos>
              <div v-if="form.pagamento.codBanco" key="cobranca">
                <v-row no-gutters>
                  <v-col cols="12">
                    <base-text-field
                      type="text"
                      rules="required"
                      id="cobrancaAgencia"
                      name="Agência"
                      v-model="form.pagamento.agencia"
                      label="Agência / Dígito *"
                      v-mask="getMascaraAgencia(form.pagamento.codBanco)"
                      clearable
                      outlined
                    ></base-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <base-text-field
                      type="text"
                      rules="required"
                      id="cobrancaConta"
                      :data-test-id="'conta-banco'"
                      name="Conta"
                      v-model="form.pagamento.contaCorrente"
                      label="Conta / Dígito *"
                      clearable
                      v-mask="getMascaraConta(form.pagamento.codBanco)"
                      outlined
                    ></base-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="dadosPlanoState">
              <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Dados para reembolso</h2>
              <v-divider></v-divider>
              <p class="caption text-center mt-4" style="line-height: 1.3">
                Opção de conta corrente do titular ou de seu responsável legal (no caso de menores de idade ou incapazes) pera depósito de reembolso pela Operadora.
              </p>
              <div>
                <v-btn v-if="form.pagamento.agencia && form.pagamento.contaCorrente" @click="setCopiaDados()" class="text-center mt-4 mb-4" style="display: flex; margin: auto">
                  Copiar Dados de Cobrança <v-icon size="18" class="ma-2 pa-0"> mdi-content-copy</v-icon>
                </v-btn>
                <!-- Bancos -->
                <async-list-bancos tipoDadosFinanceiros="reembolso" v-slot="{ loading: loadingEstados, data }">
                  <base-wrapper-dialog-list
                    headerTitle="Bancos"
                    :options="data"
                    label-key="nome"
                    value-key="codigo"
                    :loading="loadingEstados"
                    @select="bancoSelecionado('dadosParaReembolso', $event)"
                    returnObject
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        id="reembolsoBanco"
                        :rules="dadosPlanoState.operadora.toUpperCase() === 'BRADESCO' && !form.dadosParaReembolso.ordemPagamento ? 'required' : ''"
                        name="Banco"
                        readonly
                        :value="bancosSelecionados.dadosParaReembolso.nome"
                        placeholder="Clique aqui para selecionar"
                        :label="dadosPlanoState.operadora.toUpperCase() === 'BRADESCO' ? 'Banco *' : 'Banco'"
                        v-on="on"
                        outlined
                        class="mt-5"
                        v-model="bancosSelecionados.dadosParaReembolso.nome"
                      >
                        <div class="d-flex align-center" style="top: -2px; position: relative">
                          <v-icon>mdi-chevron-right</v-icon>
                          <v-btn color="red" v-if="form.dadosParaReembolso.codBanco" small icon class="ml-2" @click.prevent="removeReembolso">
                            <v-icon size="25" class="ma-0 pa-0">mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </base-text-field>
                    </template>
                  </base-wrapper-dialog-list>
                </async-list-bancos>
              </div>
              <!-- Agencia / conta -->
              <div v-if="form.dadosParaReembolso.codBanco" key="reembolso">
                <!-- agencia -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <base-text-field
                      type="text"
                      rules="required"
                      id="reembolsoAgencia"
                      :data-test-id="'agencia-banco'"
                      name="Agência"
                      v-model="form.dadosParaReembolso.agencia"
                      label="Agência / Dígito *"
                      v-mask="getMascaraAgencia(form.dadosParaReembolso.codBanco)"
                      clearable
                      outlined
                    ></base-text-field>
                  </v-col>
                </v-row>
                <!-- Conta -->
                <v-row no-gutters>
                  <v-col cols="12">
                    <base-text-field
                      type="text"
                      rules="required"
                      id="reembolsoConta"
                      name="Conta"
                      v-model="form.dadosParaReembolso.contaCorrente"
                      label="Conta / Dígito *"
                      v-mask="getMascaraConta(form.dadosParaReembolso.codBanco)"
                      clearable
                      outlined
                    ></base-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="possuiOrdemPagamento">
                <v-checkbox false-value :true-value="true" color="primary" v-model="form.dadosParaReembolso.ordemPagamento" class="caption">
                  <template v-slot:label>
                    <span class="mt-1" style="line-height: 1.3">Ordem de Pagamento</span>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <div class="text-center" v-if="!loaded.taxaAdesao">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
          </validation-observer>
          <taxa-angariacao ref="taxaAngariacao" @loaded="loaded.taxaAdesao = true" />
          <v-btn :data-test-id="'salvar-continuar-7'" large elevation="10" block :disabled="!loaded.taxaAdesao" :loading="validating" color="primary" class="secundaryBtn--text mt-4 mb-4" @click="submit">
            Salvar e continuar
          </v-btn>
          <v-dialog v-model="dialogConfirmarTaxa" max-width="300" eager persistent>
            <v-card class="pa-0">
              <v-sheet color="primary pa-2" style="border-bottom-right-radius: 0; border-bottom-left-radius: 0">
                <h2 class="white--text title text-center">Aviso importante</h2>
              </v-sheet>
              <v-sheet class="pa-4 pt-6 pb-0">
                <p class="subtitle-2 text-center mb-3 font-weight-bold">Consultor, não perca a vigência desta proposta.</p>
                <p class="subtitle-2 text-center mb-3">
                  Atente-se a data de fechamento e oriente seu cliente quanto ao prazo de compensação bancária para pagamentos através de boleto, que pode ser de até 2 dias úteis.
                </p>
              </v-sheet>
              <v-divider></v-divider>
              <v-sheet color="pa-2" class="d-flex justify-center">
                <v-btn :data-test-id="'enviar-mais-tarde'" text color="primary" class="secundaryBtn--text" @click="closeDialogConfirmarTaxa">
                  <span class="font-weight-bold text-capitalize subtitle-1">Continuar</span>
                </v-btn>
              </v-sheet>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AsyncListBancos from "@/components/AsyncListBancos.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import bancosService from "@/services/bancosService";
import propostaService from "@/services/propostaService";
import reembolsoService from "@/services/reembolsoService";
import pagamentosService from "@/services/pagamentosService";
import TaxaAngariacao from "@/views/AreaLogada/CriarProposta/Financeiro/TaxaAngariacao.vue";

export default {
  name: "DadosFinanceiros",
  components: {
    AsyncListBancos,
    BaseWrapperDialogList,
    BaseTextField,
    TaxaAngariacao,
  },
  data() {
    return {
      idSinf: "",
      dialogConfirmarTaxa: false,
      possuiOrdemPagamento: false,
      loading: false,
      idPlano: 0,
      corretoras: [],
      loaded: {
        taxaAdesao: false,
      },
      bancosSelecionados: {
        pagamento: {},
        dadosParaReembolso: {},
      },
      formasPagamento: [],
      tipoTaxaAngariacao: [
        {
          id: "ISENCAO",
          nome: "ISENTO",
          opcional: "ISENTO",
        },
        {
          id: "COBRANCA",
          nome: "COBRANÇA",
          opcional: "COBRANÇA (Não Permitida para Portabilidade)",
        },
      ],
      replicaReembolso: {},
      form: {
        taxaFormatada: 0.0,
        pagamento: {
          forma: null,
          codBanco: null,
          agencia: null,
          contaCorrente: null,
        },
        dadosParaReembolso: {
          agencia: "",
          banco: "",
          contaCorrente: "",
          codBanco: "",
        },
      },
      validating: false,
    };
  },
  mounted() {
    this.getFormasPagamento();
    this.getConsultaReembolso();
  },
  created() {
    this.idPlano = this.propostaState.plano.idProdutoFatura;
    this.idSinf = this.propostaState.idPlanoSinf;
  },
  computed: {
    ...mapState({
      dadosPagamentoState: (state) => state.cadastroProposta.pagamento,
      dadosPlanoState: (state) => state.cadastroProposta.plano,
      dadosReembolsoState: (state) => state.cadastroProposta.dadosParaReembolso,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
    }),
  },
  watch: {
    dadosPagamentoState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
    dadosReembolsoState: {
      immediate: true,
      handler: "setDataReembolso",
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDadosPagamento: "cadastroProposta/setDadosPagamento",
      setDadosReembolso: "cadastroProposta/setDadosReembolso",
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setTipoTaxaAngariacao: "cadastroProposta/setTipoTaxaAngariacao",
      setValorTaxaAngariacao: "cadastroProposta/setValorTaxaAngariacao",
      setjustificativaTaxaAngariacao: "cadastroProposta/setjustificativaTaxaAngariacao",
    }),
    setData() {
      if (!this.dadosPagamentoState) return;
      const pagamento = this.$cleanObserver(this.dadosPagamentoState);
      this.form.pagamento = pagamento;
    },
    onSucessBancoPagamento() {
      if (this.propostaState && this.propostaState.status === "DEVOLVIDA") return;
      const pagamento = this.$cleanObserver(this.dadosPagamentoState);
      if (pagamento == null || pagamento.forma.toUpperCase() === "BOLETO") return;
      this.form.pagamento = pagamento;
      const codBanco = parseInt(pagamento.codBanco, 16);
      this.bancosSelecionados.pagamento = {
        id: codBanco,
        nome: pagamento.banco,
      };
    },
    async getFormasPagamento() {
      try {
        const data = await pagamentosService.getFormasPagamento({ idSinf: this.idSinf, administradora: this.propostaState.administradora });
        this.formasPagamento = data;
        return data;
      } catch (error) {
        this.mostraErro(error.message || 'Erro ao buscar dados, tente novamente mais tarde');
        this.$emit('error');
        return error;
      }
    },
    setCopiaDados() {
      this.form.dadosParaReembolso.agencia = this.form.pagamento.agencia;
      this.form.dadosParaReembolso.contaCorrente = this.form.pagamento.contaCorrente;
      this.form.dadosParaReembolso.codBanco = this.form.pagamento.codBanco;
      this.form.dadosParaReembolso.banco = this.form.pagamento.banco;
      this.bancosSelecionados.dadosParaReembolso = {
        id: this.bancosSelecionados.pagamento.id,
        nome: this.bancosSelecionados.pagamento.nome,
      };
    },
    setDataReembolso() {
      if (!this.dadosReembolsoState) return;
      const reembolso = this.$cleanObserver(this.dadosReembolsoState);
      this.form.dadosParaReembolso = reembolso;
      this.bancosSelecionados.dadosParaReembolso = {
        id: reembolso.codBanco,
        nome: reembolso.banco,
      };
    },
    removeCobranca() {
      this.form.pagamento.codBanco = null;
      this.form.pagamento.agencia = null;
      this.form.pagamento.contaCorrente = null;
      this.form.pagamento.banco = null;

      this.bancosSelecionados.pagamento = {};
    },
    removeReembolso() {
      this.form.dadosParaReembolso = {
        forma: "",
        agencia: "",
        contaCorrente: "",
        codBanco: "",
        banco: "",
      };
      this.bancosSelecionados.dadosParaReembolso = {};
    },
    bancoSelecionado(tipo, event) {
      if (this.bancosSelecionados[tipo].id && this.bancosSelecionados[tipo].id !== event.id) {
        this.form[tipo].agencia = "";
        this.form[tipo].contaCorrente = "";
      }
      this.form[tipo].codBanco = event.id.toString();
      this.form[tipo].banco = event.nome;
      this.bancosSelecionados[tipo] = event;
    },
    mostraErro(mensagem) {
      this.$root.$snackBar.open({
        color: "error",
        message: mensagem,
      });
      this.$emit("error");
    },
    async validaDados(banco) {
      try {
        this.tratarAgencia(banco);
        this.tratarConta(banco);
        const data = await bancosService.validaDados({ codigoBanco: banco.codBanco, agencia: banco.agencia, conta: banco.contaCorrente });
        return data;
      } catch (error) {
        this.mostraErro("Erro ao buscar dados, tente novamente mais tarde");
        this.$emit("error");
        return error;
      }
    },
    tratarAgencia(banco) {
      const { codBanco } = banco;

      if ((codBanco === "1" || codBanco === "237") && !banco.agencia.includes("-")) {
        banco.agencia = banco.agencia.padStart(5, 0);
        banco.agencia = `${banco.agencia.substring(0, 4)}-${banco.agencia.substring(4, 5)}`;
      }

      if ((codBanco === "341" || codBanco === "33" || codBanco === "77") && banco.agencia.length < 4) {
        banco.agencia = banco.agencia.padStart(4, 0);
      }
    },
    tratarConta(banco) {
      const qtdNumeros = [
        { codBanco: "1", qtd: 12 },
        { codBanco: "33", qtd: 9 },
        { codBanco: "341", qtd: 6 },
        { codBanco: "237", qtd: 8 },
        { codBanco: "77", qtd: 10 },
      ];

      const { codBanco } = banco;
      const qtdBanco = qtdNumeros.find((qt) => qt.codBanco === codBanco);

      if (qtdBanco && !banco.contaCorrente.includes("-")) {
        banco.contaCorrente = banco.contaCorrente.padStart(qtdBanco.qtd, 0);
        banco.contaCorrente = `${banco.contaCorrente.substring(0, qtdBanco.qtd - 1)}-${banco.contaCorrente.substring(qtdBanco.qtd - 1, qtdBanco.qtd)}`;
      }
    },
    async stopValidating() {
      this.validating = false;
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      const taxaAngariacao = await this.$refs.taxaAngariacao.salvar();
      if (!isValid || !taxaAngariacao) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Verifique o preenchimento do formulário e tente novamente",
        });
        return;
      }
      this.validating = true;
      if (this.form.pagamento.codBanco) {
        const validaCobranca = await this.validaDados(this.form.pagamento);

        if (!validaCobranca) {
          this.mostraErro(`Ocorreu um erro na validação dos dados de débito automático. Tente novamente`);
          this.stopValidating();
          return;
        }

        if (!validaCobranca.return) {
          this.mostraErro(`Débito Automático: ${validaCobranca.mensagem}`);
          this.stopValidating();
          return;
        }
      }

      if (this.form.dadosParaReembolso.codBanco) {
        const validaReembolso = await this.validaDados(this.form.dadosParaReembolso);

        if (!validaReembolso) {
          this.mostraErro(`Ocorreu um erro na validação dos dados de reembolso. Tente novamente`);
          this.stopValidating();
          return;
        }

        if (!validaReembolso.return) {
          this.mostraErro(`Reembolso: ${validaReembolso.mensagem}`);
          this.stopValidating();
          return;
        }
      }

      if (!this.form.dadosParaReembolso.ordemPagamento) {
        this.form.dadosParaReembolso.ordemPagamento = false;
      }

      const { pagamento, dadosParaReembolso } = this.$cleanObserver(this.form);
      const { valorTaxaAdesao, tipoTaxaAngariacao, justificativaTaxaAngariacao } = taxaAngariacao;

      this.setDadosPagamento(pagamento);
      this.setDadosReembolso(dadosParaReembolso);
      this.setTipoTaxaAngariacao(tipoTaxaAngariacao);
      this.setValorTaxaAngariacao(valorTaxaAdesao);
      this.setjustificativaTaxaAngariacao(justificativaTaxaAngariacao);

      if (this.blnContinuarState.isEditing) {
        this.setDadosProposta({ ...this.propostaState });
      } else if (process.env.VUE_APP_VISUALIZA_MENSAGEM_CONFIRMACAO === "true" && this.propostaState.tipoTaxaAngariacao === "COBRANCA" && this.propostaState.valorTaxaAngariacao) {
        this.dialogConfirmarTaxa = true;
        return;
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          justificativaTaxaAngariacao,
          etapa: "anexo",
        });
      }

      try {
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.mostraErro(error.message || "Erro ao salvar dados, tente novamente mais tarde");
      } finally {
        this.stopValidating();
      }
    },
    async salvarDados() {
      await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
    },
    async getConsultaReembolso() {
      try {
        this.loadingRede = true;
        const idProdutoFatura = this.idPlano;
        const data = await reembolsoService.getConsultaReembolso({
          idProdutoFatura,
        });
        this.possuiOrdemPagamento = data.data.aceitaOrdemPagamento;
        this.$emit("success", data);
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$emit("error");
      } finally {
        this.loadingRede = false;
      }
    },
    async closeDialogConfirmarTaxa() {
      this.dialogConfirmarTaxa = false;
      this.setDadosProposta({
        ...this.propostaState,
        etapa: "anexo",
      });
      try {
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.mostraErro(error.message || "Erro ao salvar dados, tente novamente mais tarde");
      } finally {
        this.stopValidating();
      }
    },

    getMascaraAgencia(codBanco) {
      if (codBanco) {
        if (codBanco === "237" || codBanco === "1") return "XXXX-X";
        if (codBanco === "341" || codBanco === "33" || codBanco === "77") return "####";
        return "#####";
      }
      return "";
    },
    getMascaraConta(codBanco) {
      if (codBanco) {
        if (codBanco === "341") return "#####-#";
        if (codBanco === "237") return "#######-#";
        if (codBanco === "1") return "XXXXXXXXXXX-X";
        if (codBanco === "33") return "XXXXXXXX-X";
        if (codBanco === "77") return "XXXXXXXXX-X";
        return "#############";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
