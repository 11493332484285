<template>
  <v-dialog no-click-animation v-model="value" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-container class="pa-5 pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="pb-0">
                <div>
                  <the-nav-bar placeHolder="Buscar por nome" @filterInput="nomePlano = $event" :filter="true" title="Filtro de planos" @back="close"></the-nav-bar>
                  <div class="header-height"></div>
                  <v-container class="pa-5 pt-5">
                    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
                      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
                    </div>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-row justify="center">
                          <v-col cols="12">
                            <validation-observer ref="form" @submit.prevent="submit" tag="form">
                              <base-wrapper-dialog-options
                                :key="'a_abrangencia-0'"
                                headerTitle="Abrangencia"
                                :options="filtros.dataAbrangencia"
                                @select="$set(form, 'abrangencia', $event.id)"
                                value-key="id"
                                label-key="nome"
                                ref="dialogAbrangencia"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="abrangencia"
                                    placeholder="Clique aqui para selecionar"
                                    name="Abrangencia"
                                    v-on="on"
                                    :value="form.abrangencia"
                                    label="Abrangencia"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>
                              <base-wrapper-dialog-options
                                :key="'a_segmentacao-0'"
                                headerTitle="Segmentação"
                                :options="filtros.dataSegmentacao"
                                @select="$set(form, 'segmentacao', $event.id)"
                                value-key="id"
                                label-key="nome"
                                ref="dialogSegmentacao"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="segmentacao"
                                    placeholder="Clique aqui para selecionar"
                                    name="Segmetação"
                                    v-on="on"
                                    :value="form.segmentacao"
                                    label="Segmentação"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>

                              <base-wrapper-dialog-options
                                :key="'a_acomodacao-0'"
                                headerTitle="Acomodação"
                                :options="filtros.dataAcomodacao"
                                @select="$set(form, 'acomodacao', $event.id)"
                                value-key="id"
                                label-key="nome"
                                ref="dialogAcomodacao"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="acomodacao"
                                    placeholder="Clique aqui para selecionar"
                                    name="Acomodação"
                                    v-on="on"
                                    :value="form.acomodacao"
                                    label="Acomodação"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>

                              <base-wrapper-dialog-options
                                :key="'c_coparticipacao-0'"
                                headerTitle="Coparticipação"
                                :options="filtro.dataCoparticipacao"
                                @select="$set(form, 'coparticipacao', $event.nome)"
                                value-key="id"
                                label-key="nome"
                                ref="dialogCoparticipaca"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="coparticipacao"
                                    placeholder="Clique aqui para selecionar"
                                    name="Coparticipação"
                                    v-on="on"
                                    :value="form.coparticipacao"
                                    label="Coparticipação"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>
                              <v-row>
                                <v-col cols="12">
                                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">
                                    Intervalo de Preço
                                  </h2>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col md="2" sm="2">
                                  <v-text-field v-model="price[0]" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col md="2" sm="2">
                                  <v-text-field v-model="price[1]" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
                                  <v-range-slider class="rangePrice" v-model="price" :max="24000" :min="0" :step="1"></v-range-slider>
                                </v-col>
                              </v-row>
                              <base-wrapper-dialog-options
                                :key="'c_ordenar-0'"
                                headerTitle="Ordenar"
                                :options="filtro.dataOrdenar"
                                @select="$set(form, 'ordenar', $event.nome)"
                                value-key="nome"
                                label-key="nome"
                                ref="dialogOrdenar"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="ordenar"
                                    placeholder="Por Preço"
                                    name="Ordenar"
                                    v-on="on"
                                    :value="form.ordenar"
                                    label="Ordenar"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>

                              <base-wrapper-dialog-list
                                :key="'d_rede-referenciada-0'"
                                headerTitle="Rede Médica"
                                :options="listaPrestadores"
                                label-key="prestador"
                                refKey="idProdutoFatura"
                                value-key="id"
                                :loading="loadingRede"
                                :selectMultiplos="true"
                                return-object
                                @select="selectHospital"
                              >
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    inputmode="text"
                                    v-on="on"
                                    readonly
                                    id="redeReferenciada"
                                    name="Rede Médica"
                                    placeholder="Selecione"
                                    label="Rede Médica"
                                    outlined
                                    :value="hospitaisSelecionados"
                                    append-icon="mdi-chevron-right"
                                    :loading="loadingRede"
                                  ></base-text-field>
                                </template>
                                <template v-slot:options="">
                                  <base-wrapper-dialog-options
                                    class="mt-10"
                                    :key="'c_cidades-0'"
                                    headerTitle="Cidades"
                                    :options="redeReferenciada.cidades"
                                    @select="selectCidadePrestadores"
                                    value-key="nome"
                                    label-key="nome"
                                    ref="dialogCidades"
                                  >
                                    <template v-slot:activador="{ on }">
                                      <base-text-field
                                        :loading="false"
                                        readonly
                                        id="cidade"
                                        placeholder="Clique aqui para selecionar"
                                        name="Cidades"
                                        v-on="on"
                                        :value="cidadeSelecionada"
                                        label="Cidades"
                                        outlined
                                        append-icon="mdi-chevron-right"
                                      ></base-text-field>
                                    </template>
                                  </base-wrapper-dialog-options>
                                </template>
                              </base-wrapper-dialog-list>

                              <v-btn large type="submit" elevation="10" block
                              color="primary"
                              class="text-none mt-5 mb-4" @click.prevent="enviarFiltro">Filtrar</v-btn>

                              <v-btn large type="submit" elevation="10" block
                              color="primary"
                              class="text-none mt-5 mb-4" @click.prevent="limparFiltro">Limpar Filtro</v-btn>
                            </validation-observer>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import redeService from "@/services/redeReferenciadaService";
import BaseCardBeneficiary from "@/components/BaseCardBeneficiary.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import { filterArrayObejctByString } from "@/utils/filterArray";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import TheNavBar from "@/components/TheNavBar.vue";
import { mapState, mapActions } from "vuex";
import filters from "@/filters";

export default {
  name: "DialogFiltro",
  components: {
    TheNavBar,
    BaseTextField,
    BaseWrapperDialogOptions,
    BaseWrapperDialogList,
  },
  data() {
    return {
      nomePlano: "",
      price: [0, 24000],
      loading: false,
      loadingRede: true,
      cidadeSelecionada: "",
      listaPrestadores: [],
      hospitalid: [],
      hospitaisSelecionados: [],
      redeReferenciada: {
        prestadores: [],
        cidades: [],
      },
      form: {},
      filtro: {
        dataOrdenar: [
          {
            id: "Preco",
            nome: "Por Preço",
          },
          {
            id: "Nome",
            nome: "Por nome",
          },
        ],
        dataAbrangencia: [],
        dataSegmentacao: [],
        dataAcomodacao: [],
        dataCoparticipacao: [
          {
            id: true,
            nome: "Sim",
          },
          {
            id: false,
            nome: "Não",
          },
        ],
      },
    };
  },
  props: {
    filtros: {
      type: [Array, Object],
      default: () => {},
    },
    planos: {
      type: [Array, Object],
      default: () => {},
    },
    UF: String,
    value: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
  },
  watch: {
    planos: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getRedeMedica();
        }
      },
    },
  },
  methods: {
    selectHospital(value) {
      this.hospitalid = value.data;
      this.hospitaisSelecionados = [];
      Object.keys(value.nome).forEach((e) => {
        if (value.nome[e]) this.hospitaisSelecionados.push(e);
      });
    },
    selectCidadePrestadores(cidade) {
      this.cidadeSelecionada = cidade.nome;
      this.listaPrestadores = this.redeReferenciada.prestadores.filter((Element) => Element.cidade === this.cidadeSelecionada);
    },
    enviarFiltro() {
      this.$emit("filtrarplanos", {
        nomePlano: this.nomePlano,
        campos: this.form,
        hospitais: this.hospitalid,
        rangePreco: this.price,
      });
    },
    limparFiltro() {
      this.form = {};
      this.price = [0, 24000];
      this.hospitaisSelecionados = [];
      this.hospitalid = [];
      this.listaPrestadores = [];
      this.cidadeSelecionada = "";
      this.$emit("limparfiltro");
    },
    close() {
      this.$emit("input", false);
    },
    async getRedeMedica() {
      try {
        this.loadingRede = true;
        const idProdutoFatura = this.planos.map((e) => e.idProdutoFatura);
        const data = await redeService.getRedeReferenciada({
          idProdutoFatura,
          UF: this.UF,
        });
        const cidades = [];
        this.redeReferenciada = data.data;
        this.redeReferenciada.cidades.forEach((e) => {
          cidades.push({ nome: e });
        });
        // eslint-disable-next-line no-nested-ternary
        cidades.sort((a, b) => (a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0));
        this.redeReferenciada.cidades = cidades;
        this.$emit("success", data);
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$emit("error");
      } finally {
        this.loadingRede = false;
      }
    },
    // search(value) {
    //   this.nomePlano = value
    // }
  },
};
</script>
