<template>
  <div v-if="!returning || this.idade < 18">
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Dados Responsável Legal</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0 mt-3">
      <v-row justify="center">
        <v-col cols="12" md="6" lg="6" xl="6" sm="6">
          <validation-observer
            tag="form"
            ref="form"
            class="mb-10"
            @submit.prevent="submit"
            autocomplete="off"
          >
            <v-row>
              <v-col class="pb-0">
                <async-cpf
                  :cpf="form.cpf"
                  :loadingMounted="loadingMountedCpf"
                  rotina="Consultar CPF do responsável legal da proposta"
                  v-slot="{loading: loadingCpf}"
                  @loading-cpf="setLoadingCpf"
                  @success="successCpf($event)"
                  @error="loadingMountedCpf = false"
                >
                  <base-text-field
                    :autoBlur="14"
                    rules="required|cpf"
                    inputmode="numeric"
                    type="tel"
                    id="cpf"
                    name="CPF"
                    label="CPF *"
                    clearable
                    v-mask="'###.###.###-##'"
                    outlined
                    maxlength="14"
                    v-model="form.cpf"
                    :loading="loadingCpf"
                  ></base-text-field>
                </async-cpf>
                <base-text-field
                  mode="aggressive"
                  rules="required|special_characters"
                  @blur="debouncedValidName"
                  id="nome"
                  name="Nome completo"
                  :disabled="desabilitaInput"
                  value
                  label="Nome completo *"
                  v-model="form.nome"
                  clearable
                  outlined
                ></base-text-field>
                <base-text-field
                  rules="required|date"
                  id="dataNascimento"
                  name="Data de nascimento"
                  label="Data de nascimento *"
                  placeholder="DD/MM/AAAA"
                  v-mask="'##/##/####'"
                  inputmode="numeric"
                  :disabled="desabilitaInput"
                  clearable
                  v-model="form.dataNascimento"
                  mode="aggressive"
                  outlined
                ></base-text-field>
                <base-text-field
                  rules="required"
                  id="indentidade"
                  name="Identidade"
                  v-model="form.rg"
                  label="Identidade *"
                  maxlength="20"
                  clearable
                  outlined
                ></base-text-field>
                <async-tipo-sexo
                  key="c_tipo-sexo"
                  v-slot="{ loading: loadingTipoSexo, data }"
                >
                  <base-wrapper-dialog-options
                    key="d_tipo-sexo"
                    headerTitle="Sexo"
                    :options="data"
                    @select="$set(form, 'sexo', $event.id)"
                    value-key="id"
                    label-key="nome"
                    ref="dialogSexo"
                    :loading="loadingTipoSexo"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        readonly
                        rules="required"
                        placeholder="Clique aqui para selecionar"
                        id="sexo"
                        name="Sexo"
                        v-on="on"
                        :value="form.sexo"
                        label="Sexo *"
                        outlined
                        append-icon="mdi-chevron-right"
                        :loading="loadingTipoSexo"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-options>
                </async-tipo-sexo>
                <async-list-grau-parentesco-responsavel v-slot="{loading: loadingParentesco}" @success="setGrauParentesco">
                  <base-wrapper-dialog-options
                    headerTitle="Grau parentesco"
                    :options="parentescosFiltrado"
                    @select="form.parentesco = $event.grauParentesco"
                    value-key="grauParentesco"
                    label-key="grauParentesco"
                    :loading="loadingParentesco">
                    <template v-slot:activador="{on}">
                      <base-text-field
                        rules="required"
                        readonly
                        id="grauParentesco"
                        placeholder="Clique aqui para selecionar"
                        name="Grau parentesco"
                        v-on="on"
                        :value="form.parentesco"
                        label="Grau parentesco *"
                        outlined
                        append-icon="mdi-chevron-right">
                      </base-text-field>
                  </template>
                </base-wrapper-dialog-options>
                <async-estado-civil
                  key="c_estado-civil"
                  v-slot="{ loading: loadingEstadoCivil, data }"
                >
                  <base-wrapper-dialog-options
                    key="d_estado-civil"
                    headerTitle="Estado civil"
                    :options="data"
                    @select="$set(form, 'estadoCivil', $event.id)"
                    value-key="id"
                    label-key="nome"
                    ref="dialogEstadoCivil"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        :loading="loadingEstadoCivil"
                        rules="required"
                        readonly
                        id="estadoCivil"
                        placeholder="Clique aqui para selecionar"
                        name="Estado civil"
                        v-on="on"
                        :value="form.estadoCivil"
                        label="Estado civil *"
                        outlined
                        append-icon="mdi-chevron-right"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-options>
                </async-estado-civil>
                </async-list-grau-parentesco-responsavel>
                <base-text-field
                  type="email"
                  rules="required|email"
                  id="email"
                  name="E-mail"
                  v-model="form.email"
                  :disabled="desabilitaInput"
                  label="E-mail *"
                  clearable
                  outlined
                ></base-text-field>
                <base-text-field
                  type="tel"
                  rules="required|cellphone|ddd"
                  id="telefoneCelular"
                  name="Telefone celular"
                  v-model="telefoneCelular"
                  label="Telefone celular *"
                  v-mask="'## #####-####'"
                  :disabled="desabilitaInput"
                  clearable
                  outlined
                  persistent-hint
                  class="mb-3"
                ></base-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :loading="loadingSalvar"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text"
                  :disabled="isLoadingCpf"
                >Salvar e continuar</v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AsyncCpf from '@/components/AsyncCpf.vue';
import AsyncTipoSexo from '@/components/AsyncTipoSexo.vue';
import BaseWrapperDialogOptions from '@/components/BaseWrapperDialogOptions.vue';
import AsyncListGrauParentescoResponsavel from '@/components/AsyncListGrauParentescoResponsavel.vue';
import AsyncEstadoCivil from '@/components/AsyncEstadoCivil.vue';
import BaseTextField from '@/components/BaseTextField.vue';
import validaService from '@/services/validaService';
import { mapActions, mapState } from 'vuex';
import filters from '@/filters';
import propostaService from '@/services/propostaService';
import moment from 'moment';

const _ = require('lodash');

export default {
  name: 'DadosResponsavelLegal',
  props: {
    returning: Boolean,
  },
  components: {
    AsyncTipoSexo,
    AsyncCpf,
    BaseTextField,
    BaseWrapperDialogOptions,
    AsyncListGrauParentescoResponsavel,
    AsyncEstadoCivil,
  },
  data() {
    return {
      loadingMountedCpf: false,
      loadingSalvar: false,
      loadingCpf: false,
      isLoadingCpf: false, // propriedade duplicada por motivos da prop acima nao esta sendo reativa no contexto dos metodos, apenas no scopo do template
      parentescosFiltrado: [],
      parentescos: [],
      estadosCivis: [],
      idade: null,
      dataNascimento: null,
      telefoneCelular: '',
      mounting: true,
      form: {
        cpf: '',
        nome: '',
        dataNascimento: '',
        rg: '',
        sexo: '',
        parentesco: '',
        estadoCivil: '',
        email: '',
        telefones: [],
      },
    };
  },
  created() {
    this.debouncedValidName = _.debounce(this.validName, 350);
  },
  mounted() {
    if (this.form.cpf) this.loadingMountedCpf = true;
    this.getIdade();
    this.verificaProximo();
  },
  computed: {
    ...mapState({
      responsavelLegalState: (state) => state.cadastroProposta.responsavelLegal,
      dadosTitularState: (state) => state.cadastroProposta.titular,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
    }),
    desabilitaInput() {
      return (this.propostaState.status || '') !== 'EM_DIGITACAO';
    },
  },
  watch: {
    responsavelLegalState: {
      immediate: true,
      handler: 'setDadosResponsavelLegal',
      deep: true,
    },
  },
  methods: {
    setLoadingCpf(bool) {
      this.isLoadingCpf = bool;
    },
    ...mapActions({
      setResponsavelLegal: 'cadastroProposta/setResponsavelLegal',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setBlnContinuar: "cadastroProposta/setBlnContinuar",
    }),
    setDadosResponsavelLegal() {
      if (!this.mounting) return;
      this.mounting = false;
      const { telefones, ...restoDados } = this.responsavelLegalState || {};
      this.form = { ...restoDados };

      if (this.form.dataNascimento) { this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento); }
      if (telefones && telefones.length) {
        const [{ numero }] = telefones;
        this.setTelefoneCelular(numero);
      }
    },
    setTelefoneCelular(telefone) {
      this.telefoneCelular = telefone || '';
      this.form.telefones = [];
      if (telefone) {
        this.form.telefones.push({
          tipo: 'Celular',
          numero: filters.removeSymbols(telefone),
          preferencial: true,
        });
      }
    },
    async validName(ev) {
      if (ev.$event.target.value) {
        const result = await validaService.validaNome(ev.$event.target.value);

        if (result.valido) {
          this.$refs.form.setErrors({ [ev.id]: [] });
        } else {
          const errors = result.errors.split('\n');
          this.$refs.form.setErrors({ [ev.id]: errors });
        }
      }
    },
    verificaProximo() {
      if (this.idade >= 18) {
        if (this.returning) {
          this.$router.go(-1);
        } else {
          this.$emit('next');
        }
      }
    },
    successCpf(data) {
      this.$set(this.form, 'nome', data.nome);

      if (data.dataNascimento) { this.$set(this.form, 'dataNascimento', filters.convertDateOutput(data.dataNascimento)); }
      this.$set(this.form, 'sexo', data.sexo);
      this.loadingMountedCpf = false;
      // this.form.parentesco = '';
      this.setDataNascimento();
    },
    setGrauParentesco(data) {
      this.parentescos = data;
      this.filtroGrauParentesco();
    },
    setDataNascimento() {
      if (this.form.dataNascimento) { this.dataNascimento = moment(this.form.dataNascimento).format('DD/MM/YYYY'); }
    },
    filtroGrauParentesco() {
      this.parentescosFiltrado = [];
      let grausParentesco = this.parentescos.filter((d) => this.idade >= d.idadeMinima && this.idade <= d.idadeMaxima);
      grausParentesco = grausParentesco.map(({ grauParentesco }) => grauParentesco);
      grausParentesco.forEach((item) => {
        item.forEach((i) => this.parentescosFiltrado.push({ grauParentesco: i }));
      });
    },
    getIdade() {
      this.idade = filters.getAge(new Date(this.dadosTitularState.dataNascimento));
      if (this.idade) {
        this.filtroGrauParentesco();
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      if (this.form.rg && validaService.validaRG(this.form.rg)) {
        this.$root.$snackBar.open({
          color: "error",
          message: "O RG não pode conter caracteres especiais.",
        });
        return;
      }

      const form = { ...this.form }; // Realiza o clone do form
      const telefoneCelular = (` ${this.telefoneCelular}`).slice(1);
      let paginaEspecifica;

      form.dataNascimento = filters.convertDateInput(form.dataNascimento);
      form.cpf = filters.removeSymbols(form.cpf);
      form.rg = filters.removeSymbols(form.rg);
      this.setTelefoneCelular(telefoneCelular);
      this.setResponsavelLegal({ ...form, telefones: this.form.telefones });
      if (this.blnContinuarState.isEditing || this.blnContinuarState.isEditingRL) {
        if (this.blnContinuarState.isEditingCPFTitular) {
          this.setDadosProposta({
            ...this.propostaState,
            etapa: "dados_financeiros",
          });
          paginaEspecifica = { specificStep: 12 };
        } else {
          this.setDadosProposta({ ...this.propostaState });
          this.setBlnContinuar({
            flag: true,
            page: 6,
            isEditingRL: this.blnContinuarState.isEditingRL,
          });
        }
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: 'dados_profissionais',
        });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        this.$emit('next', paginaEspecifica);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
      if (data && data.responsavelLegal && data.responsavelLegal.guid) {
        this.setResponsavelLegal({
          ...this.responsavelLegalState,
          guid: data.responsavelLegal.guid,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
