<template>
  <v-dialog
  no-click-animation
  v-model="value"
  persistent
  fullscreen
  hige-overlay
  transition="dialog-bottom-transition">
   <v-card tile v-if="produtoOpcional">
     <the-nav-bar title="Detalhe produto opcional" @back="close"></the-nav-bar>
     <v-container class="pa-5 pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0">
            <v-row>
              <v-col>
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Resumo</h2>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Nome</v-list-item-title>
                    <v-list-item-subtitle
                      class="subtitle-1 text-uppercase text-wrap"
                      style="line-height: 1.2;"
                    >{{produtoOpcional.nomePlanoAns}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Operadora</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.operadora}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Qtd. Beneficiário</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.beneficiarios != null ? produtoOpcional.beneficiarios.length : 0}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="produtoOpcional.opcionalExigeConfirmacaoCliente" class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Valor Total</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{produtoOpcional.valorTotal}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-col v-if="!produtoOpcional.opcionalExigeConfirmacaoCliente" class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Beneficiários</h2>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" v-for="(beneficiario, index) in beneficiarios" :key="index">
                    <base-card-beneficiary
                      :nome="beneficiario.chave || beneficiario.dataNascimento"
                      :valor="beneficiario.valorContratual"
                      :valorSemDesconto="beneficiario.valorContratualSemDesconto"
                      :tipo="isTipo(beneficiario)"
                      :avatar="isTipo(beneficiario)"
                    ></base-card-beneficiary>
                  </v-col>
                </v-row>
            </v-col>
            <v-row no-gutters>
                <v-col>
                  <v-btn
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="text-none mt-6"
                  @click="close()"
                  >Voltar</v-btn>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-container>
   </v-card>
  </v-dialog>
</template>

<script>
import TheNavBar from '@/components/TheNavBar.vue';
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import filters from '@/filters';
import { mapState } from 'vuex';

export default {
  name: "ProdutosOpcionais",
  data() {
    return {
    };
  },
  components: { TheNavBar, BaseCardBeneficiary },
  props: {
    produtoOpcional: {
      required: true,
    },
    value: {
      default: false,
    },
  },
  created() {},
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    beneficiarios() {
      if (this.produtoOpcional.beneficiarios != null) {
        return this.produtoOpcional.beneficiarios.map((m) => {
          m.dataNascimento = filters.convertDateInput(m.dataNascimento);
          return m;
        });
      } return [];
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    isTipo(beneficiario) {
      const nome = this.propostaState.titular.nome != null
        && this.propostaState.titular.nome !== ''
        ? this.propostaState.titular.nome
        : 'Titular';
      return beneficiario.chave === nome ? 'Titular' : 'Dependente';
    },
  },
};
</script>
