<template>
  <v-dialog
    no-click-animation
    v-model="value"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile v-if="plano">
      <the-nav-bar title="Detalhe plano" @back="close"></the-nav-bar>
    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
    </div>
      <v-container class="pa-5 pt-0" v-else>
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Resumo</h2>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Plano</v-list-item-title>
                    <v-list-item-subtitle
                      class="subtitle-1 text-uppercase text-wrap"
                      style="line-height: 1.2;"
                    >{{plano.nomePlanoAns}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Operadora</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{plano.operadora}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Qtd. Beneficiário</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{beneficiarios.length}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Beneficiários</h2>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" v-for="(beneficiario, index) in beneficiarios" :key="index">
                    <base-card-beneficiary
                      :data-test-id="'beneficiario'+index"
                      :nome="beneficiario.chave || beneficiario.dataNascimento"
                      :valor="beneficiario.valorContratual"
                      :valorSemDesconto="beneficiario.valorContratualSemDesconto"
                      :tipo="isTipo(beneficiario)"
                      :avatar="isTipo(beneficiario)"
                      :valorOdonto="beneficiario.valorOdonto"
                      :comOdonto="plano.comOdonto"
                    ></base-card-beneficiary>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :data-test-id="'seleciona-plano-beneficiario'"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-4"
                  @click.prevent="selecionaPlano(plano)"
                >Selecionar plano</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import TheNavBar from '@/components/TheNavBar.vue';
import { mapState, mapActions } from 'vuex';
import filters from '@/filters';

export default {
  name: 'DialogPlano',
  components: { BaseCardBeneficiary, TheNavBar },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    plano: {
      required: true,
    },
    value: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    beneficiarios() {
      return this.plano.beneficiarios.map((m) => {
        m.dataNascimento = filters.convertDateInput(m.dataNascimento);
        m.valorOdonto = (parseFloat(m.valorContratual) + parseFloat(m.valorOdontoAdicional));
        return m;
      });
    },
  },
  methods: {
    selecionaPlano(plano) {
      this.loading = true;
      this.$emit('selecionarplano', plano);
    },
    close() {
      this.loading = false;
      this.$emit('input', false);
    },

    isTipo(beneficiario) {
      const nome = this.propostaState.titular.nome != null
        && this.propostaState.titular.nome !== ''
        ? this.propostaState.titular.nome
        : 'Titular';
      return beneficiario.chave === nome ? 'Titular' : 'Dependente';
    },
  },
};
</script>
