<template>
  <v-list elevation="2" class="pa-0" style="overflow: hidden;">
    <v-list-item link :style="{borderBottom: selected ? ('5px solid ' + $store.getters.getColor('primary')) : 'none' }" :class="{ 'especial': especial}" @click="selecionar()">
      <v-list-item-icon class="d-flex align-center justify-center mr-4">
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2 font-weight-bold d-flex align-center ma-0">
          <span>Início vigência</span>
          <v-spacer></v-spacer>
          <span>{{dataVigencia | convertDateOutput}}</span>
        </v-list-item-title>
        <v-list-item-subtitle class="caption d-flex align-center ma-0">
          <span>Comercialização até:</span>
          <v-spacer></v-spacer>
          <span>{{dataAceitacao | convertDateOutput}}</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption d-flex align-center ma-0">
          <span>Reapresentação até:</span>
          <v-spacer></v-spacer>
          <span>{{dataRepresentacao | convertDateOutput}}</span>
        </v-list-item-subtitle>

        <v-list-item-title v-if="observacao !== undefined" class="subtitle-2 font-weight-bold d-flex align-center ma-0">
          <span class="observacao">{{ observacao ? `${observacao}` : ``}}</span>
        </v-list-item-title>

      </v-list-item-content>
      <v-list-item-action>
        <v-btn text block class="text-capitalize pa-1" :loading="loading && loadingSelecionar">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: {
    dataVigencia: {
      type: String,
      required: true,
    },
    dataAceitacao: {
      type: String,
      required: true,
    },
    dataRepresentacao: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    especial: {
      type: Boolean,
      default: false,
    },
    observacao: {
      type: String,
    },
    loadingSelecionar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selecionar() {
      this.loading = true;
      this.$emit('selecionar');
    },
  },
};
</script>

<style lang="scss">
.especial {
  background-color: #fffbb8 !important;
  border: 1px solid #cacaca !important;
}
.observacao {
  color: #5d717e;
  padding-top: 2px;
  font-size: 10px;
}
</style>
