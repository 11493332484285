var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Filiação ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-5" },
        [
          _c(
            "validation-observer",
            {
              ref: "form",
              attrs: { tag: "form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center mt-10 pa-10" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "grey", size: "50" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  !_vm.entidadeComFiliacaoApiPropria
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center pb-0",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text subtitle-1 font-weight-regular pr-4",
                                            },
                                            [
                                              _vm._v(
                                                "Deseja realizar o processo de filiação digital?"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      !_vm.entidadeComFiliacaoApiPropria
                                        ? _c(
                                            "v-radio-group",
                                            {
                                              on: { change: _vm.changeAceite },
                                              model: {
                                                value: _vm.form.aceitaFiliacao,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "aceitaFiliacao",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.aceitaFiliacao",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Sim",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Não",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.form.aceitaFiliacao == true
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center pb-5",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text subtitle-1 font-weight-regular pr-4",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Valide os dados abaixo para preenchimento da ficha de filiação:"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.entidadeComFiliacaoApiPropria &&
                                              _vm.msgTaxa
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("base-text-field", {
                                                        attrs: {
                                                          id: "taxa-filiacao",
                                                          name: "taxa-filiacao",
                                                          label:
                                                            "Taxa de Filiação",
                                                          outlined: "",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value: _vm.msgTaxa,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.msgTaxa = $$v
                                                          },
                                                          expression: "msgTaxa",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("validation-observer", {
                                                ref: "formFiliacao",
                                                attrs: { slim: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _vm._l(
                                                            _vm.campos,
                                                            function (
                                                              c,
                                                              index
                                                            ) {
                                                              return [
                                                                c.valores
                                                                  ? _c(
                                                                      "base-wrapper-dialog-options",
                                                                      {
                                                                        key:
                                                                          "d_options-" +
                                                                          c.campo,
                                                                        attrs: {
                                                                          "error-messages":
                                                                            errors,
                                                                          headerTitle:
                                                                            c.titulo,
                                                                          options:
                                                                            c.valores.split(
                                                                              "|"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          select:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.selectOption(
                                                                                {
                                                                                  campo:
                                                                                    c.campo,
                                                                                  valor:
                                                                                    $event,
                                                                                }
                                                                              )
                                                                            },
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activador",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "base-text-field",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              rules:
                                                                                                "required",
                                                                                              readonly:
                                                                                                "",
                                                                                              id: c.campo,
                                                                                              placeholder:
                                                                                                "Clique aqui para selecionar",
                                                                                              name: c.titulo,
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  c
                                                                                                    .campo
                                                                                                ],
                                                                                              label:
                                                                                                c.obrigatorio
                                                                                                  ? `${c.titulo} *`
                                                                                                  : `${c.titulo}`,
                                                                                              outlined:
                                                                                                "",
                                                                                              "append-icon":
                                                                                                "mdi-chevron-right",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "base-text-field",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          rules:
                                                                            "required",
                                                                          id: c.campo,
                                                                          name: c.titulo,
                                                                          label:
                                                                            c.obrigatorio
                                                                              ? `${c.titulo} *`
                                                                              : `${c.titulo}`,
                                                                          outlined:
                                                                            "",
                                                                          disabled:
                                                                            !c.editavel,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              c
                                                                                .campo
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                c.campo,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[c.campo]",
                                                                        },
                                                                      }
                                                                    ),
                                                              ]
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1829727396
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "secundaryBtn--text mt-5 mb-4",
                                      attrs: {
                                        loading: _vm.loadingSalvar,
                                        large: "",
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                  !_vm.entidadeComFiliacaoApiPropria ||
                                  !_vm.flagFichaPreenchida
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "secundaryBtn--text mt-5 mb-4",
                                          attrs: {
                                            large: "",
                                            text: "",
                                            block: "",
                                            color: "primary",
                                          },
                                          on: { click: _vm.goEdit },
                                        },
                                        [_vm._v("Alterar Dados Filiação")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }