var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    {
      staticClass: "pa-0",
      staticStyle: { overflow: "hidden" },
      attrs: { elevation: "2" },
    },
    [
      _c(
        "v-list-item",
        {
          class: { especial: _vm.especial },
          style: {
            borderBottom: _vm.selected
              ? "5px solid " + _vm.$store.getters.getColor("primary")
              : "none",
          },
          attrs: { link: "" },
          on: {
            click: function ($event) {
              return _vm.selecionar()
            },
          },
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "d-flex align-center justify-center mr-4" },
            [_c("v-icon", [_vm._v("mdi-calendar")])],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                {
                  staticClass:
                    "subtitle-2 font-weight-bold d-flex align-center ma-0",
                },
                [
                  _c("span", [_vm._v("Início vigência")]),
                  _c("v-spacer"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("convertDateOutput")(_vm.dataVigencia))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-subtitle",
                { staticClass: "caption d-flex align-center ma-0" },
                [
                  _c("span", [_vm._v("Comercialização até:")]),
                  _c("v-spacer"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("convertDateOutput")(_vm.dataAceitacao))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-subtitle",
                { staticClass: "caption d-flex align-center ma-0" },
                [
                  _c("span", [_vm._v("Reapresentação até:")]),
                  _c("v-spacer"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("convertDateOutput")(_vm.dataRepresentacao))
                    ),
                  ]),
                ],
                1
              ),
              _vm.observacao !== undefined
                ? _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "subtitle-2 font-weight-bold d-flex align-center ma-0",
                    },
                    [
                      _c("span", { staticClass: "observacao" }, [
                        _vm._v(
                          _vm._s(_vm.observacao ? `${_vm.observacao}` : ``)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize pa-1",
                  attrs: {
                    text: "",
                    block: "",
                    loading: _vm.loading && _vm.loadingSelecionar,
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }