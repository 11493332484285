var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card-details-produto-opcional", {
    staticClass: "d-flex flex-column plano-item",
    attrs: {
      title: _vm.nome,
      selected: _vm.backgroundSelected(),
      disabled: _vm.disabled,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-list-item-avatar",
              {
                staticClass: "mr-2 mt-0 mb-0",
                attrs: { dark: "", size: "50", tile: "" },
              },
              [
                _c("v-img", {
                  attrs: { width: "100%", contain: "", src: _vm.logo },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "6" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Operadora"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "text-uppercase d-block text-truncate" },
                      [_vm._v(_vm._s(_vm.operadora || "--"))]
                    ),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "6" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "ma-2",
                          attrs: { color: "primary", dark: "", label: "" },
                        },
                        [
                          _c("span", { staticClass: "mt-1 text-uppercase" }, [
                            _vm._v(
                              "R$ " +
                                _vm._s(_vm.moneyFormatter(_vm.valor) || "--")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "4" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Abrangência"),
                    ]),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(_vm.abrangencia || "--")),
                    ]),
                  ]),
                ]),
                _c("v-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Segmentação"),
                    ]),
                    _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v(_vm._s(_vm.segmentacao || "--")),
                    ]),
                  ]),
                ]),
                _vm.mostrarPfp
                  ? _c(
                      "v-col",
                      { staticClass: "mb-2", attrs: { cols: "12" } },
                      [
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "text-uppercase",
                              on: {
                                click: function ($event) {
                                  return _vm.abrirPdfPFP()
                                },
                              },
                            },
                            [_vm._v("Mais informações")]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-row",
              {
                staticClass: "d-flex no-wrap col-12 pa-0",
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0", attrs: { cols: "5" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: { text: "", block: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("detalhes")
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-eye-outline"),
                        ]),
                        _c("span", { staticClass: "mt-1 subtitle-2" }, [
                          _vm._v("Ver detalhes"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c("v-divider", { attrs: { vertical: "" } }),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { staticClass: "pa-0", attrs: { cols: "5" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: {
                          "data-test-id": `selecionar-opcional-${_vm.index}`,
                          text: "",
                          block: "",
                          loading: _vm.loading && _vm.loadingSelecionar,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectItem()
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "mt-1 subtitle-2",
                            style: [
                              _vm.disabled
                                ? { "text-decoration": "line-through" }
                                : _vm.selectedItem
                                ? { color: "#171a88" }
                                : { color: "black" },
                            ],
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.selectedItem
                                    ? `Selecionado`
                                    : `Selecionar`
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.selectedItem
                          ? _c(
                              "v-icon",
                              {
                                staticStyle: { color: "#171a88" },
                                attrs: { right: "" },
                              },
                              [_vm._v("mdi-check-circle-outline")]
                            )
                          : _c(
                              "v-icon",
                              {
                                staticStyle: { color: "black" },
                                attrs: { right: "" },
                              },
                              [_vm._v("mdi-check-circle-outline")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }