<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Operadora</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados contratação</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <validation-observer ref="form" tag="form" class="mb-10" @submit.prevent="handleSubmit" v-else>
        <v-row justify="center">
          <v-col class="col-12 col-md-6">
            <v-row v-if="!erro.deuErro">
              <v-col class="pb-0">
                <validation-provider
                  name="Operadora"
                  vid="operadora"
                  v-slot="{errors}"
                  rules="required"
                >
                  <v-radio-group
                    v-model="form.operadora"
                    :mandatory="false"
                    :error-messages="errors"
                    v-if="operadoras.length"
                  >
                    <template v-for="(operadora, index) in operadoras">
                      <div :key="index">
                        <v-radio
                          :data-test-id="'operadora-'+ index"
                          class="mb-4"
                          :class="{'mt-4': index > 0}"
                          :label="operadora.nomeExibicaoOperadora"
                          :value="operadora.nomeExibicaoOperadora"
                          :disabled="propostaState.status.toUpperCase() != 'EM_DIGITACAO'"
                        ></v-radio>
                        <v-divider v-if="index < operadoras.length - 1"></v-divider>
                      </div>
                    </template>
                  </v-radio-group>
                  <div v-else>
                    <p class="text-center mt-5">Não existe operadoras cadastradas em sua abrangência.</p>
                  </div>
                </validation-provider>
              </v-col>
            </v-row>
            <div class="mt-3" v-else>
              <div>
                <p class="text-center">{{ erro.mensagem }}</p>
              </div>
              <div class="text-center">
                <v-btn
                    text
                    color="primary"
                    class="secundaryBtn--text"
                    @click="getOperadoras"
                  >
                  Tentar novamente
                </v-btn>
              </div>
            </div>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :loading="loadingSalvar"
                  :data-test-id="'salvar-continuar-3'"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-4"
                  :disabled="erro.deuErro || disableButton"
                >Salvar e continuar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
    <modal-confirmation
      :show="showModalExcecao"
      name="modalExcecao"
      :persistent="true"
      :loading="false"
      title="Atenção"
      text="Cliente fora da área de abrangência da sua comercialização para esta operadora, para mais informações procure seu gestor comercial"
      buttonConfirm="Ok"
      buttonCancel=""
      :width="500"
      @confirm="() => showModalExcecao = false"
    />
    <modal-confirmation
      :show="showModalBloqueioSegurosUnimed"
      name="modalBloqueioSegurosUnimed"
      :persistent="true"
      :loading="false"
      title="Atenção"
      text="Não é possível seguir com a operadora de saúde selecionada através de portabilidade."
      buttonConfirm="Ok"
      buttonCancel=""
      :width="500"
      @confirm="showModalBloqueioSegurosUnimed = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import catalogoService from '@/services/catalogoService';
import propostaService from '@/services/propostaService';
import ModalConfirmation from "@/components/ModalConfirmation.vue";
import excecaoComercialService from '@/services/excecaoComercialService';
import listaAtencaoService from "@/services/listaAtencaoService";
import { find } from "lodash";

export default {
  name: 'Operadora',
  components: {
    ModalConfirmation,
  },
  data() {
    return {
      showModalExcecao: false,
      showModalBloqueioSegurosUnimed: false,
      disableButton: false,
      loadingSalvar: false,
      loading: false,
      operadoras: [],
      form: {
        operadora: null,
      },
      erro: {
        deuErro: false,
        mensagem: '',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      planoState: (state) => state.cadastroProposta.plano,
      operadoraState: (state) => (state.cadastroProposta.plano && state.cadastroProposta.plano.operadora
        ? state.cadastroProposta.plano
        : ''),
    }),
    operadoraSelected() {
      return this.operadoras.find((item) => item.nomeExibicaoOperadora === this.form.operadora);
    },
  },
  watch: {
    operadoraState: {
      immediate: true,
      handler: 'setData',
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setOperadora: 'cadastroProposta/setOperadora',
      setPlano: 'cadastroProposta/setPlano',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
      setVigencia: "cadastroProposta/setVigencia",
    }),
    setData() {
      if (Object.keys(this.operadoraState).length) {
        if (this.operadoraState.nomeExibicaoOperadora) {
          this.form.operadora = this.operadoraState.nomeExibicaoOperadora;
        } else {
          this.form.operadora = this.operadoraState.operadora;
        }
      }
    },
    async getOperadoras() {
      try {
        this.loading = true;
        this.setErro(false, '');

        const enderecoIndex = this.propostaState.titular.enderecos.findIndex(
          (f) => f.tipo.findIndex((x) => x === 'ENDERECO_RESIDENCIAL') >= 0,
        );
        let enderecoResidencial = {};
        if (enderecoIndex >= 0) {
          enderecoResidencial = this.propostaState.titular.enderecos[
            enderecoIndex
          ];
        }
        const profissao = this.propostaState.titular.publicoAlvo ? this.propostaState.titular.publicoAlvo.toUpperCase() : "";
        let data = await catalogoService.getOperadoraPorProposta({
          publicoAlvo: profissao,
          entidade: this.propostaState.titular.entidade,
          uf: enderecoResidencial.estado,
          cidade: enderecoResidencial.cidade,
          cpfCnpj: this.propostaState.corretora.cpfCnpj,
          administradora: this.propostaState.administradora,
        });
        if (data && data.length) {
          data = [...new Map(data.map((item) => [item.nomeExibicaoOperadora && item.nomeExibicaoOperadora.toLowerCase(), item])).values()];
        }
        this.operadoras = data;
        if (this.operadoras && !this.operadoras.length) {
          this.disableButton = true;
        } else {
          this.disableButton = false;
        }
      } catch (error) {
        this.setErro(true, error.message || 'Erro ao buscar operadoras');
      } finally {
        this.loading = false;
      }
    },
    setErro(deuErro, mensagem) {
      this.erro = { deuErro, mensagem };
    },

    async salvarDados() {
      if (this.propostaState.nrProposta) {
        const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
        if (data && data.nrProposta) {
          this.setDadosProposta({
            ...data,
            ...this.propostaState,
            nrProposta: data.nrProposta,
          });
        }
      }
    },

    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;      

      const titularUF = this.propostaState.titular.enderecos.find((endereco) => endereco.tipo.indexOf("ENDERECO_RESIDENCIAL") > -1).estado;
      const corretorUF = this.user.endereco.estado;
      if (titularUF !== corretorUF) {
        const { cpf } = this.propostaState.titular;
        const result = await excecaoComercialService.listarExcecaoCPFAtiva(cpf);
        const { segueVendaNacional } = this.propostaState.corretora;

        const existeExcecaoSolicitacaoAtiva = result && result.excecoes.length > 0;

        if (!existeExcecaoSolicitacaoAtiva && segueVendaNacional) {
          const { id: idOperadora } = this.operadoraSelected;
          const { restricaoUF } = await excecaoComercialService.listarExcecaoOperadora(idOperadora);
          if (restricaoUF) {
            this.showModalExcecao = true;
            return;
          }
        }
      }

      const { possuiPortabilidade } = this.propostaState;
      const { operadora } = this.form;
      if (possuiPortabilidade && operadora === 'SEGUROS UNIMED') {
        this.showModalBloqueioSegurosUnimed = true;
        return;
      }

      const handleProposta = {
        redirectStepPlano(context) {
          let plano = {};
          /*
             compara a operadora selecionada com a possivel que tenha no state,
                para salvar os dados do plano.
          */
          if (((context.planoState && context.planoState.operadora) || '').toUpperCase() === (context.operadoraSelected.nome || '').toUpperCase()) {
            plano = { ...context.planoState };
          } else {
            context.setVigencia({});
          }

          // seta no state os dados do plano mais o nome da operadora selecionada
          context.setPlano({
            ...plano,
            nomeExibicaoOperadora: context.operadoraSelected.nomeExibicaoOperadora,
            cnpjOperadora: context.operadoraSelected.id,
          });

          // seta no state o nome da operadora selecionada
          context.setOperadora(context.operadoraSelected.nome);

          /*
            caso seja uma proposta em edição não salva a nova etapa
            caso seja, salva a etapa "plano" no state
          */
          if (context.blnContinuarState.isEditing) {
            context.setDadosProposta({ ...context.propostaState });
          } else {
            context.setDadosProposta({
              ...context.propostaState,
              etapa: 'plano',
            });
          }
        },
        redirectStepVigencia(context) {
          // seta o valor da operadora selecionada
          context.setPlano({
            nomeExibicaoOperadora: context.operadoraSelected.nomeExibicaoOperadora,
            cnpjOperadora: context.operadoraSelected.id,
          });

          // seta no state o nome da operadora selecionada
          context.setOperadora(context.operadoraSelected.nome);

          /*
            caso seja uma proposta em edição não salva a nova etapa
            caso seja, salva a etapa "vigencia" no state
          */
          if (context.blnContinuarState.isEditing) {
            context.setDadosProposta({ ...context.propostaState });
          } else {
            context.setDadosProposta({
              ...context.propostaState,
              etapa: 'vigencia',
            });
          }
        },
      };

      // obs: seria melhor no if ternário, porém o eslint esta barrando
      const vigenciaBeforePlano = this.propostaState.plano && !this.propostaState.plano.idProdutoFatura;
      if (vigenciaBeforePlano) {
        handleProposta.redirectStepVigencia(this);
      } else {
        handleProposta.redirectStepPlano(this);
      }

      try {
        this.loadingSalvar = true;
        const operadora = this.form.operadora;
        if ((operadora === 'SULAMÉRICA SAÚDE' || operadora === 'BLUE' || operadora === 'AMIL SAÚDE SA') && !this.propostaState.possuiPortabilidade) {
          const cpf = this.propostaState.titular.cpf
          const bloqueioList = await listaAtencaoService.verificarBloqueiosListaAtencao({ cpf });
          const bloqueioEncontrado = operadora === 'SULAMÉRICA SAÚDE' ? find(bloqueioList, { origem: 'sulamerica' }) : operadora === 'BLUE' ? find(bloqueioList, { origem: 'blue' }) : find(bloqueioList, { origem: 'amil' });
          if (bloqueioEncontrado) throw new Error('Cliente bloqueado na operadora');
        }
        await this.salvarDados();

        this.$emit('next');
      } catch (error) {
        if (error.message === 'Cliente bloqueado na operadora') {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Não é possível seguir com a operadora selecionada, verifique com seu gestor comercial.',
          });            
        } else {
          this.$root.$snackBar.open({
            color: 'error',
            message: error.message || 'Erro ao salvar dados, tente novamente mais tarde',
          });
        }
      } finally {
        this.loadingSalvar = false;
      }
    },
  },
  created() {
    this.getOperadoras();
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
