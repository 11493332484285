<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1
              class="title font-weight-bold mb-1 title"
              style="line-height: 1"
            >
              Dados Filiação
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-5">
      <validation-observer ref="form" @submit.prevent="submit" tag="form">
        <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
          <v-progress-circular
            indeterminate
            color="grey"
            size="50"
          ></v-progress-circular>
        </div>
        <v-row v-else justify="center">
          <v-col class="col-12 col-md-6">
            <v-row>
              <v-col cols="12">
                <div
                  v-if="!entidadeComFiliacaoApiPropria"
                  class="d-flex align-center pb-0"
                >
                  <span
                    class="primary--text subtitle-1 font-weight-regular pr-4"
                    >Deseja realizar o processo de filiação digital?</span
                  >
                </div>
                <div>
                  <v-radio-group
                    v-if="!entidadeComFiliacaoApiPropria"
                    v-model="form.aceitaFiliacao"
                    v-on:change="changeAceite"
                  >
                    <v-radio label="Sim" :value="true"></v-radio>
                    <v-radio label="Não" :value="false"></v-radio>
                  </v-radio-group>
                  <div v-if="form.aceitaFiliacao == true">
                    <div class="d-flex align-center pb-5">
                      <span
                        class="primary--text subtitle-1 font-weight-regular pr-4"
                        >Valide os dados abaixo para preenchimento da ficha de
                        filiação:</span
                      >
                    </div>
                    <div v-if="entidadeComFiliacaoApiPropria && msgTaxa">
                      <base-text-field
                        id="taxa-filiacao"
                        name="taxa-filiacao"
                        label="Taxa de Filiação"
                        v-model="msgTaxa"
                        outlined
                        disabled
                      ></base-text-field>
                    </div>
                    <validation-observer
                      ref="formFiliacao"
                      slim
                      v-slot="{ errors }"
                    >
                      <template v-for="(c, index) in campos">
                        <base-wrapper-dialog-options
                          v-if="c.valores"
                          :key="'d_options-' + c.campo"
                          :error-messages="errors"
                          :headerTitle="c.titulo"
                          :options="c.valores.split('|')"
                          @select="
                            selectOption({ campo: c.campo, valor: $event })
                          "
                        >
                          <template v-slot:activador="{ on }">
                            <base-text-field
                              rules="required"
                              readonly
                              :id="c.campo"
                              placeholder="Clique aqui para selecionar"
                              :name="c.titulo"
                              v-on="on"
                              :value="form[c.campo]"
                              :label="
                                c.obrigatorio ? `${c.titulo} *` : `${c.titulo}`
                              "
                              outlined
                              append-icon="mdi-chevron-right"
                            ></base-text-field>
                          </template>
                        </base-wrapper-dialog-options>
                        <base-text-field
                          v-else
                          :key="index"
                          :rules="'required'"
                          :id="c.campo"
                          :name="c.titulo"
                          :label="
                            c.obrigatorio ? `${c.titulo} *` : `${c.titulo}`
                          "
                          v-model="form[c.campo]"
                          outlined
                          :disabled="!c.editavel"
                        ></base-text-field>
                      </template>
                    </validation-observer>
                  </div>
                </div>
                <v-btn
                  :loading="loadingSalvar"
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-5 mb-4"
                  >Salvar e continuar</v-btn
                >
                <v-btn
                  v-if="!entidadeComFiliacaoApiPropria || !flagFichaPreenchida"
                  large
                  @click="goEdit"
                  text
                  block
                  color="primary"
                  class="secundaryBtn--text mt-5 mb-4"
                  >Alterar Dados Filiação</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
  </div>
</template>

<script>
import propostaService from "@/services/propostaService";
import entidadesService from "@/services/entidadesService";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import { mapState, mapActions } from "vuex";
import { filter, findIndex } from "lodash";

export default {
  name: "DadosFiliacao",
  components: { BaseTextField, BaseWrapperDialogOptions },
  data() {
    return {
      loadingSalvar: false,
      loading: false,
      form: {},
      campos: [],
      camposOriginais: [],
      msgTaxa: null,
      flagFichaPreenchida: false,
    };
  },
  computed: {
    ...mapState({
      enderecoState: (state) => state.cadastroProposta.titular.enderecos,
      profissaoState: (state) => state.cadastroProposta.titular.publicoAlvo,
      entidadeState: (state) => state.cadastroProposta.titular.entidade,
      planoState: (state) => state.cadastroProposta.proposta.plano,
      titularState: (state) => state.cadastroProposta.titular,
      dadosFiliacaoState: (state) =>
        state.cadastroProposta.dadosComplementaresFiliacao,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
    }),
    entidadeComFiliacaoApiPropria() {
      const entidadeComFiliacaoApiPropria = [
        "ABRACEM",
        "ASPROFILI",
        "ANAPROLIE",
        "UBES",
      ].includes(this.entidadeState);
      return entidadeComFiliacaoApiPropria;
    },
  },
  watch: {
    dadosFiliacaoState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
  },
  mounted() {
    if (this.entidadeComFiliacaoApiPropria) this.form.aceitaFiliacao = true;
    this.changeAceite();
  },
  methods: {
    ...mapActions({
      setDadosFiliacao: "cadastroProposta/setDadosFiliacao",
      setDadosProposta: "cadastroProposta/setDadosProposta",
    }),
    selectOption({ campo, valor }) {
      this.$set(this.form, campo, valor);
      this.verificaExibicaoCamposDinamicos();
    },
    verificaExibicaoCamposDinamicos() {
      this.verificaExibeEnsinoCurso();
      if (this.entidadeComFiliacaoApiPropria)
        this.verificaExibeCamposFiliacaoApiPropria();
    },
    verificaExibeEnsinoCurso() {
      const persisteEnsinoCurso = ["UNE", "UBES", "ABRACEM"];
      if (this.form.ensinoTipo === "Superior") {
        if (!this.campos.find((item) => item.campo === "ensinoCurso")) {
          const ensinoCurso = this.camposOriginais.find(
            (item) => item.campo === "ensinoCurso"
          );
          if (ensinoCurso) {
            ensinoCurso.obrigatorio = true;
            if (!this.form[ensinoCurso.campo]) {
              this.form[ensinoCurso.campo] = ensinoCurso.valor;
            }
            const indexEnsinoTipo = this.campos.findIndex(
              (item) => item.campo === "ensinoTipo"
            );
            this.campos.splice(indexEnsinoTipo + 1, 0, ensinoCurso);
          }
        }
      } else if (
        this.campos.find((item) => item.campo === "ensinoCurso") &&
        !persisteEnsinoCurso.includes(this.entidadeState)
      ) {
        const index = this.campos.findIndex(
          (item) => item.campo === "ensinoCurso"
        );
        delete this.form.ensinoCurso;
        this.campos.splice(index, 1);
      }
    },
    verificaExibeCamposFiliacaoApiPropria() {
      this.campos = filter(this.campos, function (item) {
        return item.campo !== "emailSecundario";
      });
      const indexTemFilhos = findIndex(this.campos, { campo: "temFilhos" });
      if (indexTemFilhos)
        this.campos.push(this.campos.splice(indexTemFilhos, 1)[0]);
    },
    setData() {
      if (
        !this.dadosFiliacaoState ||
        !Object.keys(this.dadosFiliacaoState).length
      ) {
        return;
      }
      const dadosComplementaresFiliacao = this.$cleanObserver(
        this.dadosFiliacaoState
      );
      this.form = dadosComplementaresFiliacao || {};
    },
    showErros() {
      let errorsList = 0;
      for (let index = 0; index < this.campos.length; index++) {
        const c = this.campos[index];
        if (c.obrigatorio && !this.form[c.campo]) {
          errorsList++;
        }
      }
      return errorsList > 0;
    },
    async submit() {
      if (this.showErros()) {
        const isValid = await this.$refs.form.validate();
        if (!isValid) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Revise o formulário",
          });
          return;
        }
      }

      let body = {
        aceitaFiliacao: this.form.aceitaFiliacao,
        salvarFicha: true,
      };
      if (body.aceitaFiliacao === true) {
        body = {
          ...body,
          ...this.form,
        };
      }

      this.setDadosFiliacao({
        ...body,
      });

      if (this.blnContinuarState.isEditing) {
        this.setDadosProposta({ ...this.propostaState });
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: "dependentes_contratacao",
        });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        const bodyAfter = {
          aceitaFiliacao: this.form.aceitaFiliacao,
        };
        this.setDadosFiliacao({
          ...bodyAfter,
        });
        this.$emit("next");
      } catch (error) {
        this.loadingSalvar = false;
        this.$root.$snackBar.open({
          color: "error",
          message:
            error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      }
    },
    async salvarDados() {
      await propostaService.addOrEdit(
        this.propostaState.guid,
        this.propostaState
      );
    },
    goEdit() {
      this.$emit("next", { specificStep: 5 });
    },
    async getCampos() {
      try {
        this.loading = true;
        const campos = await propostaService.getCamposFiliacao({
          guidProposta: this.propostaState.guid,
        });
        this.camposOriginais = campos;
        const camposObrigatorios = campos.filter(
          (item) =>
            item &&
            item.obrigatorio &&
            !(
              item.campo.includes("assinatura") ||
              (item.titulo && item.titulo.toLowerCase().includes("assinatura"))
            )
        );

        if (this.entidadeComFiliacaoApiPropria) {
          const { idProdutoFatura } = this.planoState;
          const { taxa } = await entidadesService.consultarTaxaAssociativa(
            idProdutoFatura
          );
          if (taxa)
            this.msgTaxa = taxa.existeTaxa
              ? "O valor da taxa de filiação será somado mensalmente ao valor do plano de saúde contratado."
              : "Isento da cobrança contribuição associativa.";

          const { rg, cpf, planoCnpj, temFilhos } =
            await propostaService.getFiliacaoEntidade(
              this.titularState.cpf,
              this.entidadeState
            );
          if (rg && cpf && planoCnpj) this.flagFichaPreenchida = true;
          else this.flagFichaPreenchida = false;
          const context = this;
          const camposTratamentoEspecifico = {
            sexo: (m) => {
              m.valores = undefined;
              context.form[m.campo] = m.valor;
            },
            enderecoNumero: (m) =>
              (context.form[m.campo] = context.enderecoState[0].numero),
            planoCnpj: (m) =>
              (context.form[m.campo] = context.planoState.cnpjOperadora),
            subproduto: (m) =>
              (context.form[m.campo] = context.planoState.operadora),
            profissao: (m) => (context.form[m.campo] = context.profissaoState),
            temFilhos: (m) => {
              if (temFilhos) {
                context.form[m.campo] = temFilhos == "1" ? "Sim" : "Não";
                m.valores = undefined;
                return;
              }
              context.form[m.campo] = undefined;
            },
          };
          this.campos = camposObrigatorios.map((m) => {
            if (camposTratamentoEspecifico[m.campo]) {
              camposTratamentoEspecifico[m.campo](m);
            } else this.form[m.campo] = m.valor;
            return m;
          });
        } else {
          this.campos = camposObrigatorios.map((m) => {
            this.form[m.campo] = m.valor;
            return m;
          });
        }

        this.verificaExibicaoCamposDinamicos();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
    },
    changeAceite() {
      if (this.form.aceitaFiliacao) {
        this.getCampos();
      }
    },
    onChangeInput() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
