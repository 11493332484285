var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Portabilidade Beneficiários")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados portabilidade")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "validation-observer",
                {
                  ref: "form",
                  staticClass: "mb-10",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _vm._l(
                            _vm.getBeneficiarios,
                            function (beneficiario, index) {
                              return _c(
                                "div",
                                { key: beneficiario.nome + index },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "d-flex align-center col-6 pb-0",
                                        },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v(_vm._s(beneficiario.nome))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-6 pb-0" },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Portabilidade",
                                              vid:
                                                beneficiario.guid +
                                                "_portabilidade",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          staticClass:
                                                            "justify-end-radio-group",
                                                          attrs: {
                                                            row: "",
                                                            "value-comparator":
                                                              _vm.valueCompare,
                                                            mandatory: false,
                                                            "error-messages":
                                                              errors,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.changePortabilidade(
                                                                $event,
                                                                beneficiario.guid
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form[
                                                                beneficiario
                                                                  .guid
                                                              ].portabilidade,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form[
                                                                  beneficiario
                                                                    .guid
                                                                ],
                                                                "portabilidade",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form[beneficiario.guid].portabilidade",
                                                          },
                                                        },
                                                        [
                                                          _c("v-radio", {
                                                            key:
                                                              beneficiario.guid +
                                                              "_option_true",
                                                            staticClass: "mb-4",
                                                            class: {
                                                              "mt-4": index > 0,
                                                            },
                                                            attrs: {
                                                              label: "Sim",
                                                              value: true,
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            key:
                                                              beneficiario.guid +
                                                              "_option_false",
                                                            staticClass: "mb-4",
                                                            class: {
                                                              "mt-4": index > 0,
                                                            },
                                                            attrs: {
                                                              label: "Não",
                                                              value: false,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form[beneficiario.guid].portabilidade
                                    ? _c(
                                        "v-row",
                                        { staticClass: "mt-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "col-md-12" },
                                            [
                                              _vm.propostaState.plano.operadora.toLowerCase() ===
                                              "unimed-rio"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_operadoraanterior_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Nome da operadora anterior?",
                                                            vid:
                                                              "validation_operadoraanterior_" +
                                                              beneficiario.guid,
                                                            rules: "required",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref:
                                                                          "text_operadoraanterior_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Nome da operadora anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          name: "nomeOperadoraAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .nomeOperadoraAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .nomeOperadoraAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "nomeOperadoraAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].nomeOperadoraAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_registroansoperadoraanterior_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Registro ANS da operadora anterior?",
                                                            vid:
                                                              "validation_registroansoperadoraanterior_" +
                                                              beneficiario.guid,
                                                            rules: "required",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref:
                                                                          "text_registroansoperadoraanterior_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Registro ANS da operadora anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          name: "registroAnsOperadoraAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .registroAnsOperadoraAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .registroAnsOperadoraAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "registroAnsOperadoraAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].registroAnsOperadoraAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_nomeplanoanterior_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Nome do plano anterior?",
                                                            vid:
                                                              "validation_nomeplanoanterior_" +
                                                              beneficiario.guid,
                                                            rules: "required",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref:
                                                                          "text_nomeplanoanterior_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Nome do plano anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          name: "nomePlanoAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .nomePlanoAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .nomePlanoAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "nomePlanoAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].nomePlanoAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_registroansplanoanterior_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Registro ANS do plano anterior?",
                                                            vid:
                                                              "validation_registroansplanoanterior_" +
                                                              beneficiario.guid,
                                                            rules: "required",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref:
                                                                          "text_registroansplanoanterior_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Registro ANS do plano anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          name: "registroAnsPlanoAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .registroAnsPlanoAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .registroAnsPlanoAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "registroAnsPlanoAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].registroAnsPlanoAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("async-segmentacoes", {
                                                key: "c_segmentacoes-",
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        loading:
                                                          loadingSegmentacoes,
                                                        data,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "base-wrapper-dialog-list",
                                                            {
                                                              key: "d_segmentacoes",
                                                              attrs: {
                                                                headerTitle:
                                                                  "Segmentações",
                                                                options: data,
                                                                loading:
                                                                  loadingSegmentacoes,
                                                              },
                                                              on: {
                                                                select:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectSegmentacao(
                                                                      $event,
                                                                      beneficiario.guid
                                                                    )
                                                                  },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activador",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              ref:
                                                                                "validation_segmentacao_" +
                                                                                beneficiario.guid,
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  slim: "",
                                                                                  name: "Segmentação",
                                                                                  vid:
                                                                                    "validation_segmentacao_" +
                                                                                    beneficiario.guid,
                                                                                  rules:
                                                                                    "required",
                                                                                  mode: "eager",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            _vm._g(
                                                                                              {
                                                                                                ref:
                                                                                                  "text_segmentacao_" +
                                                                                                  beneficiario.guid,
                                                                                                refInFor: true,
                                                                                                attrs:
                                                                                                  {
                                                                                                    dark: false,
                                                                                                    "data-vv-validate-on":
                                                                                                      "input",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    readonly:
                                                                                                      "",
                                                                                                    "append-icon":
                                                                                                      "mdi-chevron-right",
                                                                                                    label:
                                                                                                      "Segmentação Anterior",
                                                                                                    "error-messages":
                                                                                                      errors,
                                                                                                    name:
                                                                                                      "segmentacao_" +
                                                                                                      beneficiario.guid,
                                                                                                    value:
                                                                                                      _vm
                                                                                                        .form[
                                                                                                        beneficiario
                                                                                                          .guid
                                                                                                      ]
                                                                                                        .segmentacaoAnterior,
                                                                                                    loading:
                                                                                                      loadingSegmentacoes,
                                                                                                  },
                                                                                                scopedSlots:
                                                                                                  _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key: "append",
                                                                                                        fn: function () {
                                                                                                          return [
                                                                                                            _vm._t(
                                                                                                              "default"
                                                                                                            ),
                                                                                                          ]
                                                                                                        },
                                                                                                        proxy: true,
                                                                                                      },
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  ),
                                                                                              },
                                                                                              on
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _vm.propostaState.plano.operadora.toLowerCase() ===
                                              "unimed-rio"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_datainicioplanoanterior_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Data ínicio do plano anterior?",
                                                            vid:
                                                              "validation_datainicioplanoanterior_" +
                                                              beneficiario.guid,
                                                            rules:
                                                              "required|date",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'",
                                                                            },
                                                                          ],
                                                                        ref:
                                                                          "text_datainicioplanoanterior_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Data ínicio do plano anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          placeholder:
                                                                            "DD/MM/AAAA",
                                                                          name: "dataInicioPlanoAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .dataInicioPlanoAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .dataInicioPlanoAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "dataInicioPlanoAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].dataInicioPlanoAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          ref:
                                                            "validation_dataultimopagamento_" +
                                                            beneficiario.guid,
                                                          refInFor: true,
                                                          attrs: {
                                                            slim: "",
                                                            name: "Último pagamento do plano anterior?",
                                                            vid:
                                                              "validation_dataultimopagamento_" +
                                                              beneficiario.guid,
                                                            rules:
                                                              "required|date",
                                                            mode: "eager",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function ({
                                                                  errors,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "mask",
                                                                              rawName:
                                                                                "v-mask",
                                                                              value:
                                                                                "##/##/####",
                                                                              expression:
                                                                                "'##/##/####'",
                                                                            },
                                                                          ],
                                                                        ref:
                                                                          "text_dataultimopagamento_" +
                                                                          beneficiario.guid,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          dark: false,
                                                                          "data-vv-validate-on":
                                                                            "input",
                                                                          outlined:
                                                                            "",
                                                                          clearable:
                                                                            "",
                                                                          label:
                                                                            "Último pagamento do plano anterior? *",
                                                                          "error-messages":
                                                                            errors,
                                                                          placeholder:
                                                                            "DD/MM/AAAA",
                                                                          name: "ultimoPagamentoPlanoAnteriorPortabilidade",
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .ultimoPagamentoPlanoAnteriorPortabilidade,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "append",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _vm._t(
                                                                                      "default"
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form[
                                                                              beneficiario
                                                                                .guid
                                                                            ]
                                                                              .ultimoPagamentoPlanoAnteriorPortabilidade,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ],
                                                                                "ultimoPagamentoPlanoAnteriorPortabilidade",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form[beneficiario.guid].ultimoPagamentoPlanoAnteriorPortabilidade",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "base-wrapper-dialog-options",
                                                        {
                                                          attrs: {
                                                            headerTitle:
                                                              "Exerceu portabilidade ? *",
                                                            options: [
                                                              {
                                                                id: "Sim",
                                                                nome: "Sim",
                                                                value: "Sim",
                                                              },
                                                              {
                                                                id: "Não",
                                                                nome: "Não",
                                                                value: "Não",
                                                              },
                                                            ],
                                                            "value-key":
                                                              "value",
                                                            "label-key": "nome",
                                                          },
                                                          on: {
                                                            select: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.form[
                                                                  beneficiario
                                                                    .guid
                                                                ],
                                                                "exerceuPortabilidade",
                                                                $event.id
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activador",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "base-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              rules:
                                                                                "required",
                                                                              id: "exerceuPortabilidade",
                                                                              name: "Exerceu portabilidade ?",
                                                                              value:
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ]
                                                                                  .exerceuPortabilidade,
                                                                              placeholder:
                                                                                "Clique aqui para selecionar",
                                                                              label:
                                                                                "Exerceu portabilidade ? *",
                                                                              outlined:
                                                                                "",
                                                                              "append-icon":
                                                                                "mdi-chevron-right",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ]
                                                                                  .exerceuPortabilidade,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .form[
                                                                                      beneficiario
                                                                                        .guid
                                                                                    ],
                                                                                    "exerceuPortabilidade",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form[beneficiario.guid].exerceuPortabilidade",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                      _c(
                                                        "base-wrapper-dialog-options",
                                                        {
                                                          attrs: {
                                                            headerTitle:
                                                              "Cumpriu ou está cumprindo CPT ? *",
                                                            options: [
                                                              {
                                                                id: "Sim",
                                                                nome: "Sim",
                                                                value: "Sim",
                                                              },
                                                              {
                                                                id: "Não",
                                                                nome: "Não",
                                                                value: "Não",
                                                              },
                                                            ],
                                                            "value-key":
                                                              "value",
                                                            "label-key": "nome",
                                                          },
                                                          on: {
                                                            select: function (
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.form[
                                                                  beneficiario
                                                                    .guid
                                                                ],
                                                                "cumpriuOuCumprindoCPTPortabilidade",
                                                                $event.id
                                                              )
                                                            },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activador",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "base-text-field",
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              readonly:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              rules:
                                                                                "required",
                                                                              id: "cumpriuOuCumprindoCPTPortabilidade",
                                                                              name: "Cumpriu ou está cumprindo CPT ?",
                                                                              value:
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ]
                                                                                  .cumpriuOuCumprindoCPTPortabilidade,
                                                                              placeholder:
                                                                                "Clique aqui para selecionar",
                                                                              label:
                                                                                "Cumpriu ou está cumprindo CPT ? *",
                                                                              outlined:
                                                                                "",
                                                                              "append-icon":
                                                                                "mdi-chevron-right",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .form[
                                                                                  beneficiario
                                                                                    .guid
                                                                                ]
                                                                                  .cumpriuOuCumprindoCPTPortabilidade,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .form[
                                                                                      beneficiario
                                                                                        .guid
                                                                                    ],
                                                                                    "cumpriuOuCumprindoCPTPortabilidade",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "form[beneficiario.guid].cumpriuOuCumprindoCPTPortabilidade",
                                                                            },
                                                                        },
                                                                        on
                                                                      )
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      index < _vm.getBeneficiarios.length - 1
                                        ? _c("v-divider", {
                                            key: beneficiario.guid + "_divider",
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text mt-4",
                                      attrs: {
                                        loading: _vm.loadingSalvar,
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }