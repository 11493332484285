<template>
  <div>
    <slot :data="estadosCivis" :loading="loading"></slot>
  </div>
</template>

<script>
import catalogoService from '@/services/catalogoService';

export default {
  name: 'AsyncEstadoCivil',
  props: {},
  data() {
    return {
      loading: false,
      estadosCivis: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await catalogoService.getEstadoCivil();
        this.estadosCivis = data;
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
</style>
