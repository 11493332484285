<template>
  <div>
    <slot :data="segmentacoes" :loading="loading"></slot>
  </div>
</template>

<script>
import planoService from '@/services/planoService';

export default {
  name: 'AsyncSegmentacoes',
  props: {},
  data() {
    return {
      loading: false,
      segmentacoes: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await planoService.getSegmentacoes() || [];
        this.segmentacoes = data;
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style>
</style>
