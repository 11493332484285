var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Produtos Opcionais ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "grey", size: "50" },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { md: "4" } },
                    [
                      _c("p", { staticClass: "text-center mt-5" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.propostaState.plano.operadora ===
                                "Assim Saúde"
                                ? "Deseja selecionar produtos opcionais odontológicos?"
                                : "Deseja selecionar produtos opcionais?"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { mandatory: false },
                          model: {
                            value: _vm.flagProdutoOpcional,
                            callback: function ($$v) {
                              _vm.flagProdutoOpcional = $$v
                            },
                            expression: "flagProdutoOpcional",
                          },
                        },
                        [
                          _c("v-radio", {
                            staticClass: "mb-4",
                            attrs: { label: "Sim", value: true },
                          }),
                          _c("v-divider"),
                          _c("v-radio", {
                            staticClass: "mt-4",
                            attrs: { label: "Não", value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _vm.flagProdutoOpcional
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "pb-2 col" }, [
                        !_vm.produtosOpcionais.length
                          ? _c("p", { staticClass: "text-center mt-5" }, [
                              _vm._v(
                                " Não encontramos planos opcionais disponíveis para essa proposta. "
                              ),
                            ])
                          : _c(
                              "p",
                              {
                                staticClass:
                                  "text-center subtitle-2 grey--text mb-0 font-weight-regular",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.produtosOpcionais.length) +
                                    " registro(s) encontrado(s) "
                                ),
                                _vm.listaSelecionados.length > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-center subtitle-2 grey--text mb-0 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          "e " +
                                            _vm._s(
                                              _vm.listaSelecionados.length
                                            ) +
                                            " registro(s) selecionado(s)"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.flagProdutoOpcional
                ? _c(
                    "v-row",
                    { staticClass: "pb-10" },
                    _vm._l(
                      _vm.produtosOpcionais,
                      function (produtoOpcional, index) {
                        return _c(
                          "v-col",
                          { key: index, staticClass: "col-12 col-md-6" },
                          [
                            _c("base-card-produto-opcional", {
                              attrs: {
                                index: index,
                                idProdutoFatura:
                                  produtoOpcional.idProdutoFatura,
                                operadora: produtoOpcional.operadora,
                                nome: produtoOpcional.nomePlanoAns,
                                abrangencia: produtoOpcional.abrangencia,
                                valor: produtoOpcional.valorTotal,
                                segmentacao: produtoOpcional.segmentacao,
                                logo: produtoOpcional.operadoraLogo,
                                disabled:
                                  _vm.verificarExistenciaSegmentacao(
                                    produtoOpcional
                                  ),
                                selected:
                                  _vm.checkProdutosSelecionados(
                                    produtoOpcional
                                  ),
                                mostrarPfp:
                                  produtoOpcional.opcionalExigeConfirmacaoCliente,
                              },
                              on: {
                                selectProdutoOpcional:
                                  _vm.selectProdutoOpcional,
                                detalhes: function ($event) {
                                  return _vm.verDetalhes(produtoOpcional)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "secundaryBtn--text mt-0",
                  attrs: {
                    large: "",
                    "data-test-id": "salvar-continuar",
                    type: "submit",
                    elevation: "10",
                    block: "",
                    color: "primary",
                    loading: _vm.loadingBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.flagProdutoOpcional &&
                          _vm.produtosOpcionais.length > 0
                          ? `Salvar e continuar`
                          : `Continuar sem produtos opcionais`
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("dialog-produto-opcional", {
        attrs: { produtoOpcional: _vm.produtoOpcionalSelecionado },
        model: {
          value: _vm.dialogProdutoOpcional,
          callback: function ($$v) {
            _vm.dialogProdutoOpcional = $$v
          },
          expression: "dialogProdutoOpcional",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }