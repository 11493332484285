import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'bancos';
const getBancos = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_BANCO_API_KEY } };
    const { data } = await api.get(`${resource}`, config);
    // const list = data && data.data ? data.data.map((item) => item.nome) : [];
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const validaDados = async ({ codigoBanco, agencia, conta }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_BANCO_API_KEY } };
    const { data } = await api.get(`${resource}/${codigoBanco}/${agencia}/${conta}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
export default {
  getBancos,
  validaDados,
};
/*
Valida dados bancários
GET - /bancos/${codigoBanco}/${agencia}/${conta}

import api from '@/services/api';

const getBancos = () => api.post('/bancos');

export default {
  getBancos,
}; */
