var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados Dependentes")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados contratação")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-center mb-4 mt-2" },
                        [
                          _c("div", { staticClass: "caption text-center" }, [
                            _vm._v("A alteração de dependente pode"),
                            _c("br"),
                            _vm._v("influenciar no valor total do plano"),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.dependentes, function (dependente, index) {
                        return _c(
                          "v-sheet",
                          {
                            key: dependente.id,
                            staticClass: "d-flex align-center mb-5",
                            staticStyle: {
                              overflow: "hidden",
                              cursor: "pointer",
                            },
                            attrs: { elevation: "2" },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "ripple", rawName: "v-ripple" },
                                ],
                                staticClass: "pa-4 d-flex",
                                staticStyle: { flex: "1", overflow: "hidden" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selecionarDependente(
                                      dependente,
                                      index
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "col-9 pa-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-truncate subtitle-2 font-weight-regular",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          dependente.nome
                                            ? dependente.nome
                                            : _vm.formatDate(
                                                dependente.dataNascimento
                                              )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-truncate subtitle-2 font-weight-regular grey--text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          dependente.nome
                                            ? _vm.formatDate(
                                                dependente.dataNascimento
                                              ) + " - "
                                            : ""
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.idade(dependente.dataNascimento)
                                          )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("v-spacer"),
                                !dependente.check
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          size: "30",
                                          color: "rgba(0,0,0,.4)",
                                        },
                                      },
                                      [_vm._v("mdi-alert-circle")]
                                    )
                                  : _c(
                                      "v-icon",
                                      {
                                        attrs: { size: "30", color: "success" },
                                      },
                                      [_vm._v("mdi-check-circle")]
                                    ),
                              ],
                              1
                            ),
                            !_vm.desabilitaRemove
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-4 ml-4 secundaryBtn--text",
                                    attrs: { icon: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeDependente(
                                          dependente,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "34" } }, [
                                      _vm._v("mdi-delete"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm.dialogDependente
                        ? _c("dialog-dependente", {
                            ref: "refDialogDependente",
                            attrs: { dependente: _vm.dependenteSelected },
                            on: { saveData: _vm.handleDependenteData },
                            model: {
                              value: _vm.dialogDependente,
                              callback: function ($$v) {
                                _vm.dialogDependente = $$v
                              },
                              expression: "dialogDependente",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "300", eager: "" },
                      on: { input: _vm.clearDate },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              !_vm.desabilitaInput
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          "data-test-id":
                                            "adicionar-dependente",
                                          color: "primary",
                                          text: "",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-capitalize subtitle-1 font-weight-bold",
                                        },
                                        [_vm._v("Adicionar dependente")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { size: "20" },
                                        },
                                        [_vm._v("mdi-plus-circle")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticStyle: {
                                "border-bottom-right-radius": "0",
                                "border-bottom-left-radius": "0",
                              },
                              attrs: { color: "primary pa-2" },
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "white--text title text-center",
                                },
                                [_vm._v("Adicionar dependente")]
                              ),
                            ]
                          ),
                          _c(
                            "validation-observer",
                            {
                              ref: "form",
                              attrs: { tag: "form" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addDependente.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-sheet",
                                { staticClass: "pa-4 pt-6 pb-0" },
                                [
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      rules: "required|date",
                                      id: "dataNascimento",
                                      "data-test-id": "dob-dependente-dialogo",
                                      name: "Data de nascimento",
                                      label: "Data de nascimento",
                                      placeholder: "DD/MM/AAAA",
                                      inputmode: "numeric",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-sheet",
                                {
                                  staticClass: "d-flex",
                                  attrs: { color: "pa-2" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text",
                                      attrs: {
                                        "data-test-id": "cancelar-dialogo",
                                        text: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold text-capitalize subtitle-1",
                                        },
                                        [_vm._v("Cancelar")]
                                      ),
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text",
                                      attrs: {
                                        "data-test-id": "confirmar-dialogo",
                                        loading: _vm.addingDependente,
                                        text: "",
                                        color: "primary",
                                        type: "submit",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold text-capitalize subtitle-1",
                                        },
                                        [_vm._v("Confirmar")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secundaryBtn--text mt-10 mb-10",
                      attrs: {
                        disabled: !_vm.isValid,
                        loading: _vm.loadingSalvar,
                        large: "",
                        "data-test-id": "salvar-continuar-6",
                        type: "submit",
                        elevation: "10",
                        block: "",
                        color: "primary",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("Salvar e continuar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }