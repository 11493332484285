<template>
  <fragment>
    <slot :data="dados" :loading="loading"></slot>
  </fragment>
</template>

<script>
import usuarioService from '@/services/usuarioService';
import cpfValidate from '@/validators/rules/cpfValidate';
import filters from '@/filters';

export default {
  name: 'AsyncCep',
  props: {
    cpf: {
      required: true,
    },
    entidade: {
      type: String,
      default: "",
    },
    rotina: {
      type: String,
      default: "",
    },
    dataNascimento: {
      type: String,
      default: "",
    },
    loadingMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      dados: [],
    };
  },
  watch: {
    cpf(newValue) {
      this.getData(newValue);
    },
  },
  methods: {
    async getData(cpf) {
      if (!cpf) return;
      if (cpf.length === 14) {
        this.$emit('valid');
        const valid = cpfValidate(cpf);
        if (!valid) return;
        try {
          this.loading = true;
          this.$emit("loading-cpf", true);
          const text = filters.removeSymbols(cpf);

          if (this.dataNascimento) {
            const idadeConsulta = filters.getAge(this.dataNascimento);
            if (Number(idadeConsulta) < 18) {
              this.$emit('success', 'naoConsultaSerasa');
              return;
            }
          }

          const data = await usuarioService.buscarSerasa({ cpf: text, entidade: this.entidade, rotina: this.rotina });
          const { nome, dataNascimento, naoConsultaSerasa } = data;

          if (naoConsultaSerasa) {
            this.$emit('success', 'naoConsultaSerasa');
            return;
          }

          if (!dataNascimento) {
            this.$emit('error', 'not-birth');
            return;
          }

          this.$emit('success', data);
          if (this.loadingMounted) return;
          const firstName = nome.split(' ')[0];
          const idade = filters.getAge(data.dataNascimento);
          this.$root.$snackBar.open({
            color: 'success',
            message: `${firstName} - ${idade} anos`,
          });
        } catch (error) {
          this.$emit('error', error);
        } finally {
          this.loading = false;
          this.$emit("loading-cpf", false);
        }
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
