module.exports = [
  { id: "Companhia Energética de Minas Gerais (Cemig)", nome: "Companhia Energética de Minas Gerais (Cemig)" },
  { id: "Cemig Distribuição S.A. (Cemig D)", nome: "Cemig Distribuição S.A. (Cemig D)" },
  { id: "Cemig Geração e Transmissão S.A. (Cemig GT)", nome: "Cemig Geração e Transmissão S.A. (Cemig GT)" },
  { id: "Cemig Saúde", nome: "Cemig Saúde" },
  { id: "Cemig SIM", nome: "Cemig SIM" },
  { id: "Companhia de Gás de Minas Gerais S.A. (Gasmig)", nome: "Companhia de Gás de Minas Gerais S.A. (Gasmig)" },
  { id: "Fundação Forluminas de Seguridade Social (Forluz)", nome: "Fundação Forluminas de Seguridade Social (Forluz)" },
  { id: "Sá Carvalho", nome: "Sá Carvalho" },
];
