var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "timeline" },
    _vm._l(_vm.pages, function (page, index) {
      return _c(
        "li",
        { key: page.nome, staticClass: "timeline__item d-flex align-center" },
        [
          _c(
            "div",
            { staticClass: "timeline__check" },
            [
              index === _vm.currentStep
                ? _c("v-icon", { attrs: { color: "white", size: "20" } }, [
                    _vm._v("mdi-map-marker"),
                  ])
                : index < _vm.currentStep
                ? _c("v-icon", { attrs: { color: "white", size: "20" } }, [
                    _vm._v("mdi-check-circle"),
                  ])
                : _c(
                    "v-icon",
                    { attrs: { color: "rgba(255,255,255,.5)", size: "20" } },
                    [_vm._v("mdi-minus-circle")]
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "timeline__text primary--text subtitle-2" },
            [
              _c(
                "v-list",
                { staticClass: "pa-0", staticStyle: { overflow: "hidden" } },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pa-1 ma-0",
                      attrs: { link: index <= _vm.currentStep },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("changeStep", index)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "pa-0 pl-2" },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "subtitle-2",
                              staticStyle: { "line-height": "1" },
                            },
                            [_vm._v(_vm._s(page.nome))]
                          ),
                          index <= _vm.currentStep
                            ? _c(
                                "v-list-item-subtitle",
                                { staticClass: "caption" },
                                [_c("span", [_vm._v("Clique para ver")])]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }