<template>
  <base-card-details-produto-opcional :title="nome" :selected="backgroundSelected()" :disabled="disabled" class="d-flex flex-column plano-item">
    <template v-slot:header class="flex-grow-0">
      <v-list-item-avatar dark size="50" tile class="mr-2 mt-0 mb-0">
        <v-img width="100%" contain :src="logo"></v-img>
      </v-list-item-avatar>
    </template>
    <template v-slot:body class="flex-grow-1">
      <v-row no-gutters>
        <v-col cols="6" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Operadora</h3>
            <span class="text-uppercase d-block text-truncate">{{operadora || "--"}}</span>
          </div>
        </v-col>
        <v-col cols="6" class="mb-2">
          <div class="d-flex justify-end">
            <v-chip color="primary" class="ma-2" dark label>
              <span class="mt-1 text-uppercase">R$ {{moneyFormatter(valor) || "--"}}</span>
            </v-chip>
          </div>
        </v-col>
        <v-col cols="4" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Abrangência</h3>
            <span class="text-uppercase">{{abrangencia || "--"}}</span>
          </div>
        </v-col>
        <v-col cols="12" class="mb-2">
          <div>
            <h3 class="mb-1 subtitle-2">Segmentação</h3>
            <span class="text-uppercase">{{segmentacao || "--"}}</span>
          </div>
        </v-col>
        <v-col cols="12" class="mb-2" v-if="mostrarPfp">
          <div>
            <a class="text-uppercase" @click="abrirPdfPFP()">Mais informações</a>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer class="flex-grow-0">
      <v-row no-gutters class="d-flex no-wrap col-12 pa-0">
        <v-col cols="5" class="pa-0">
          <v-btn text block class="text-capitalize" @click="$emit('detalhes')">
            <v-icon left>mdi-eye-outline</v-icon>
            <span class="mt-1 subtitle-2">Ver detalhes</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-spacer></v-spacer>
        <v-col cols="5" class="pa-0">
          <v-btn :data-test-id="`selecionar-opcional-${index}`" text block class="text-capitalize" :loading="loading && loadingSelecionar" @click="selectItem()">
           <span class="mt-1 subtitle-2" :style="[disabled ? {'text-decoration': 'line-through'} : selectedItem ? {'color': '#171a88'} : {'color': 'black'}]">
             {{selectedItem ? `Selecionado`: `Selecionar`}} </span>
            <v-icon right v-if="selectedItem" style="color:#171a88;">mdi-check-circle-outline</v-icon>
            <v-icon right v-else style="color:black;">mdi-check-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </base-card-details-produto-opcional>
</template>

<script>
import BaseCardDetailsProdutoOpcional from '@/components/BaseCardDetailsProdutoOpcional.vue';

export default {
  components: { BaseCardDetailsProdutoOpcional },
  data() {
    return {
      loading: false,
      selectedItem: false,
    };
  },
  created() {
    this.$emit("disabled");
  },
  methods: {
    selectItem() {
      this.selectedItem = !this.selectedItem;
      this.$emit("selectProdutoOpcional", this.idProdutoFatura, this.segmentacao, this.selectedItem);
      return this.selectedItem;
    },
    moneyFormatter(valor) {
      return valor.replace(".", ",");
    },
    backgroundSelected() {
      this.selectedItem = this.selected;
      return this.selectedItem;
    },
    abrirPdfPFP() {
      const url = `${process.env.VUE_APP_API_URL_TEMPLATE}pdf/pfp.pdf?api-key=${process.env.VUE_APP_API_KEY_TEMPLATES}`;
      window.open(url, "_blank");
    },
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    idProdutoFatura: {
      type: Number,
    },
    abrangencia: {
      type: String,
      default: '--',
    },
    acomodacao: {
      type: String,
      default: '--',
    },
    coparticipacao: Boolean,
    odonto: Boolean,
    logo: {
      type: String,
      required: true,
    },
    nome: {
      type: String,
      default: '--',
    },
    operadora: {
      type: String,
      default: '--',
    },
    segmentacao: {
      type: String,
      default: '--',
    },
    loadingSelecionar: {
      type: Boolean,
      default: false,
    },
    valor: {
      default: 0,
    },
    disabled: {
      required: false,
      default: false,
    },
    selected: {
      required: true,
      default: false,
    },
    mostrarPfp: {
      required: false,
      default: false,
    },
  },
};

</script>
