<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">
              Dados Profissionais
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-5">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <v-row v-else justify="center">
        <v-col class="col-12 col-md-6">
          <v-row>
            <v-col cols="12">
              <validation-observer ref="form" @submit.prevent="submit" tag="form">
                <base-text-field rules="required" id="profissao" name="Profissão" label="Profissão" :value="profissaoState" outlined disabled></base-text-field>
                <base-text-field rules="required" id="entidade" name="Entidade" :value="entidadeState" label="Entidade" outlined disabled></base-text-field>
                <div class="d-flex align-center pb-8" v-if="entidadeTemCnae">
                  <span class="primary--text subtitle-1 font-weight-regular pr-4">Informe os dados da empresa</span>
                  <v-spacer></v-spacer>
                </div>
                <div class="d-flex align-center pb-8" v-else>
                  <span class="primary--text subtitle-1 font-weight-regular pr-4">Informar dados da empresa</span>
                  <v-spacer></v-spacer>
                  <v-switch
                    :data-test-id="'informar-dados-empresa'"
                    class="ma-0"
                    hide-details
                    v-model="showDadosEmpresa"
                    :disabled="!possuiDadosEmpresaState && desabilitaInput"
                  ></v-switch>
                </div>
                <div v-if="showDadosEmpresa || (entidadeTemCnae)">
                  <base-text-field rules="required" id="cnpj" :data-test-id="'cnpj'" name="CNPJ" label="CNPJ *" v-mask="'##.###.###/####-##'" v-model="form.cnpj" outlined></base-text-field>
                  <base-text-field rules="required" id="nomeFantasia" :data-test-id="'nome-empresa'" name="Nome Empresa" label="Nome Empresa *" v-model="form.nomeFantasia" outlined></base-text-field>
                  <base-text-field
                    :rules="entidadeTemCnae ? 'required' : ''"
                    id="razaoSocial"
                    :data-test-id="'razao-social'"
                    name="Razão Social"
                    :label="`Razão Social ${entidadeTemCnae ? '*' : ''}`"
                    v-model="form.razaoSocial"
                    outlined
                  ></base-text-field>
                  <base-text-field
                    :rules="entidadeTemCnae ? 'required' : ''"
                    id="ramoAtividade"
                    :data-test-id="'ramo-atividade'"
                    name="Ramo de atividade"
                    :label="`Ramo de atividade ${entidadeTemCnae ? '*' : ''}`"
                    v-model="form.ramoAtividade"
                    outlined
                  ></base-text-field>
                  <base-text-field
                    :rules="entidadeTemCnae ? 'required' : ''"
                    id="sindicato"
                    :data-test-id="'sindicato'"
                    name="Sindicato"
                    :label="`Sindicato ${entidadeTemCnae ? '*' : ''}`"
                    v-model="form.sindicato"
                    outlined
                  ></base-text-field>
                </div>
                <v-btn :data-test-id="'salvar-continuar-5'" :loading="loadingSalvar" large type="submit" elevation="10" block color="primary" class="secundaryBtn--text mt-5 mb-4">Salvar e continuar</v-btn>
              </validation-observer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseTextField from "@/components/BaseTextField.vue";
import propostaService from "@/services/propostaService";
import catalogoService from "@/services/catalogoService";
import { mapState, mapActions } from "vuex";

export default {
  name: "DadosProfissionais",
  components: { BaseTextField },
  data() {
    return {
      loadingSalvar: false,
      loading: false,
      showDadosEmpresa: false,
      entidade: {},
      entidadeTemCnae: false,
      form: {
        cnpj: "",
        nomeFantasia: "",
        razaoSocial: "",
        ramoAtividade: "",
        sindicato: "",
      },
    };
  },
  computed: {
    ...mapState({
      enderecoState: (state) => state.cadastroProposta.titular.enderecos,
      profissaoState: (state) => state.cadastroProposta.titular.publicoAlvo,
      entidadeState: (state) => state.cadastroProposta.titular.entidade,
      dadosProfissionaisState: (state) => state.cadastroProposta.titular.dadosProfissionais,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
    }),
    desabilitaInput() {
      return (this.propostaState.status || "") !== "EM_DIGITACAO";
    },
    possuiDadosEmpresaState() {
      const dadosProfissionais = this.$cleanObserver(this.dadosProfissionaisState || {});
      return !!dadosProfissionais.cnpj;
    },
  },
  watch: {
    dadosProfissionaisState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
  },
  mounted() {
    this.getEntidadeCNAE();
  },
  methods: {
    ...mapActions({
      setDadosProfissionais: "cadastroProposta/setDadosProfissionais",
      setDadosProposta: "cadastroProposta/setDadosProposta",
    }),
    setData() {
      if (!this.dadosProfissionaisState || !Object.keys(this.dadosProfissionaisState).length) return;
      const dadosProfissionais = this.$cleanObserver(this.dadosProfissionaisState);
      this.form = dadosProfissionais;
      this.showDadosEmpresa = !!this.form.cnpj;
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      if (this.showDadosEmpresa === false && !this.entidade.cnaeEmpresaOperacional) {
        this.clearForm();
      }

      this.setDadosProfissionais({
        ...this.form,
      });

      if (this.blnContinuarState.isEditing) {
        this.setDadosProposta({ ...this.propostaState });
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: "dependentes_contratacao",
        });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async salvarDados() {
      const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
    },
    async getEntidadeCNAE() {
      try {
        this.loading = true;
        this.entidade = await catalogoService.verificaEntidadeTemCnae({
          uf: this.propostaState.titular.enderecos[0].estado,
          cidade: this.propostaState.titular.enderecos[0].cidade,
          publicoAlvo: this.propostaState.titular.publicoAlvo,
          administradora: this.propostaState.administradora,
          operadora: this.propostaState.plano.operadora,
          entidade: this.entidadeState,
        });

        this.entidadeTemCnae = this.entidade && this.entidade.cnaeEmpresaOperacional;

        this.loading = false;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      }
    },
    clearForm() {
      this.form = {
        cnpj: "",
        nomeFantasia: "",
        razaoSocial: "",
        ramoAtividade: "",
        sindicato: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
