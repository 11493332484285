var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.returning || this.idade < 18
    ? _c(
        "div",
        [
          _c(
            "v-app-bar",
            { attrs: { color: "primary", dark: "", fixed: "" } },
            [
              _c(
                "v-container",
                { staticClass: "d-flex align-center pa-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      attrs: { small: "", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("back")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c("v-toolbar-title", { staticClass: "mt-1" }, [
                    _c("div", [
                      _c(
                        "h1",
                        {
                          staticClass: "title font-weight-bold mb-1 title",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v("Dados Responsável Legal")]
                      ),
                      _c(
                        "h3",
                        {
                          staticClass: "caption ma-0",
                          staticStyle: { "line-height": "1" },
                        },
                        [_vm._v("Dados contratação")]
                      ),
                    ]),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("openmenu")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-menu")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "header-height" }),
          _c(
            "v-container",
            { staticClass: "pa-5 pt-0 mt-3" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: { cols: "12", md: "6", lg: "6", xl: "6", sm: "6" },
                    },
                    [
                      _c(
                        "validation-observer",
                        {
                          ref: "form",
                          staticClass: "mb-10",
                          attrs: { tag: "form", autocomplete: "off" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c("async-cpf", {
                                    attrs: {
                                      cpf: _vm.form.cpf,
                                      loadingMounted: _vm.loadingMountedCpf,
                                      rotina:
                                        "Consultar CPF do responsável legal da proposta",
                                    },
                                    on: {
                                      "loading-cpf": _vm.setLoadingCpf,
                                      success: function ($event) {
                                        return _vm.successCpf($event)
                                      },
                                      error: function ($event) {
                                        _vm.loadingMountedCpf = false
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            loading: loadingCpf,
                                          }) {
                                            return [
                                              _c("base-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "###.###.###-##",
                                                    expression:
                                                      "'###.###.###-##'",
                                                  },
                                                ],
                                                attrs: {
                                                  autoBlur: 14,
                                                  rules: "required|cpf",
                                                  inputmode: "numeric",
                                                  type: "tel",
                                                  id: "cpf",
                                                  name: "CPF",
                                                  label: "CPF *",
                                                  clearable: "",
                                                  outlined: "",
                                                  maxlength: "14",
                                                  loading: loadingCpf,
                                                },
                                                model: {
                                                  value: _vm.form.cpf,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "cpf",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.cpf",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      848538549
                                    ),
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      mode: "aggressive",
                                      rules: "required|special_characters",
                                      id: "nome",
                                      name: "Nome completo",
                                      disabled: _vm.desabilitaInput,
                                      value: "",
                                      label: "Nome completo *",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    on: { blur: _vm.debouncedValidName },
                                    model: {
                                      value: _vm.form.nome,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "nome", $$v)
                                      },
                                      expression: "form.nome",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      rules: "required|date",
                                      id: "dataNascimento",
                                      name: "Data de nascimento",
                                      label: "Data de nascimento *",
                                      placeholder: "DD/MM/AAAA",
                                      inputmode: "numeric",
                                      disabled: _vm.desabilitaInput,
                                      clearable: "",
                                      mode: "aggressive",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.dataNascimento,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "dataNascimento",
                                          $$v
                                        )
                                      },
                                      expression: "form.dataNascimento",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      rules: "required",
                                      id: "indentidade",
                                      name: "Identidade",
                                      label: "Identidade *",
                                      maxlength: "20",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.rg,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "rg", $$v)
                                      },
                                      expression: "form.rg",
                                    },
                                  }),
                                  _c("async-tipo-sexo", {
                                    key: "c_tipo-sexo",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            loading: loadingTipoSexo,
                                            data,
                                          }) {
                                            return [
                                              _c(
                                                "base-wrapper-dialog-options",
                                                {
                                                  key: "d_tipo-sexo",
                                                  ref: "dialogSexo",
                                                  attrs: {
                                                    headerTitle: "Sexo",
                                                    options: data,
                                                    "value-key": "id",
                                                    "label-key": "nome",
                                                    loading: loadingTipoSexo,
                                                  },
                                                  on: {
                                                    select: function ($event) {
                                                      return _vm.$set(
                                                        _vm.form,
                                                        "sexo",
                                                        $event.id
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activador",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "base-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    readonly:
                                                                      "",
                                                                    rules:
                                                                      "required",
                                                                    placeholder:
                                                                      "Clique aqui para selecionar",
                                                                    id: "sexo",
                                                                    name: "Sexo",
                                                                    value:
                                                                      _vm.form
                                                                        .sexo,
                                                                    label:
                                                                      "Sexo *",
                                                                    outlined:
                                                                      "",
                                                                    "append-icon":
                                                                      "mdi-chevron-right",
                                                                    loading:
                                                                      loadingTipoSexo,
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3197163728
                                    ),
                                  }),
                                  _c("async-list-grau-parentesco-responsavel", {
                                    on: { success: _vm.setGrauParentesco },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            loading: loadingParentesco,
                                          }) {
                                            return [
                                              _c(
                                                "base-wrapper-dialog-options",
                                                {
                                                  attrs: {
                                                    headerTitle:
                                                      "Grau parentesco",
                                                    options:
                                                      _vm.parentescosFiltrado,
                                                    "value-key":
                                                      "grauParentesco",
                                                    "label-key":
                                                      "grauParentesco",
                                                    loading: loadingParentesco,
                                                  },
                                                  on: {
                                                    select: function ($event) {
                                                      _vm.form.parentesco =
                                                        $event.grauParentesco
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activador",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "base-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    rules:
                                                                      "required",
                                                                    readonly:
                                                                      "",
                                                                    id: "grauParentesco",
                                                                    placeholder:
                                                                      "Clique aqui para selecionar",
                                                                    name: "Grau parentesco",
                                                                    value:
                                                                      _vm.form
                                                                        .parentesco,
                                                                    label:
                                                                      "Grau parentesco *",
                                                                    outlined:
                                                                      "",
                                                                    "append-icon":
                                                                      "mdi-chevron-right",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }
                                              ),
                                              _c("async-estado-civil", {
                                                key: "c_estado-civil",
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        loading:
                                                          loadingEstadoCivil,
                                                        data,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "base-wrapper-dialog-options",
                                                            {
                                                              key: "d_estado-civil",
                                                              ref: "dialogEstadoCivil",
                                                              attrs: {
                                                                headerTitle:
                                                                  "Estado civil",
                                                                options: data,
                                                                "value-key":
                                                                  "id",
                                                                "label-key":
                                                                  "nome",
                                                              },
                                                              on: {
                                                                select:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.form,
                                                                      "estadoCivil",
                                                                      $event.id
                                                                    )
                                                                  },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activador",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "base-text-field",
                                                                            _vm._g(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    loading:
                                                                                      loadingEstadoCivil,
                                                                                    rules:
                                                                                      "required",
                                                                                    readonly:
                                                                                      "",
                                                                                    id: "estadoCivil",
                                                                                    placeholder:
                                                                                      "Clique aqui para selecionar",
                                                                                    name: "Estado civil",
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .estadoCivil,
                                                                                    label:
                                                                                      "Estado civil *",
                                                                                    outlined:
                                                                                      "",
                                                                                    "append-icon":
                                                                                      "mdi-chevron-right",
                                                                                  },
                                                                              },
                                                                              on
                                                                            )
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1326438901
                                    ),
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      type: "email",
                                      rules: "required|email",
                                      id: "email",
                                      name: "E-mail",
                                      disabled: _vm.desabilitaInput,
                                      label: "E-mail *",
                                      clearable: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "## #####-####",
                                        expression: "'## #####-####'",
                                      },
                                    ],
                                    staticClass: "mb-3",
                                    attrs: {
                                      type: "tel",
                                      rules: "required|cellphone|ddd",
                                      id: "telefoneCelular",
                                      name: "Telefone celular",
                                      label: "Telefone celular *",
                                      disabled: _vm.desabilitaInput,
                                      clearable: "",
                                      outlined: "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.telefoneCelular,
                                      callback: function ($$v) {
                                        _vm.telefoneCelular = $$v
                                      },
                                      expression: "telefoneCelular",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text",
                                      attrs: {
                                        loading: _vm.loadingSalvar,
                                        large: "",
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                        disabled: _vm.isLoadingCpf,
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }