var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold title",
                      staticStyle: { "line-height": "1.2" },
                    },
                    [_vm._v(" Vigência ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1.2" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.vigencias.length
        ? _c("v-container", { staticClass: "pa-5" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center mt-10 pa-10" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "grey", size: "50" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-12 col-md-6" },
                          [
                            _c("modal-confirmation", {
                              attrs: {
                                show: _vm.cancelarPropostaHerdada.showConfirm,
                                name: "cancelar",
                                loading: _vm.cancelarPropostaHerdada.loading,
                                title: _vm.cancelarPropostaHerdada.title,
                                text: _vm.cancelarPropostaHerdada.text,
                                buttonConfirm:
                                  _vm.cancelarPropostaHerdada.confirm,
                                buttonCancel:
                                  _vm.cancelarPropostaHerdada.cancel,
                                width: 500,
                              },
                              on: {
                                close:
                                  _vm.cancelarPropostaHerdada.callAfterCancel,
                                confirm:
                                  _vm.cancelarPropostaHerdada.callAfterConfirm,
                              },
                            }),
                            _c(
                              "v-row",
                              _vm._l(_vm.vigencias, function (vigencia, i) {
                                return _c(
                                  "v-col",
                                  { key: vigencia.id, attrs: { cols: "12" } },
                                  [
                                    _c("base-button-validity", {
                                      attrs: {
                                        selected: _vm.vigenciaSelected
                                          ? vigencia.dataVigencia ===
                                            _vm.vigenciaSelected.dataVigencia
                                          : false,
                                        especial:
                                          vigencia.herdouDataVigencia ||
                                          vigencia.vigenciaSolicitada ||
                                          false,
                                        observacao:
                                          _vm.retornaMensagemVigencia(vigencia),
                                        dataVigencia: vigencia.dataVigencia,
                                        dataAceitacao:
                                          vigencia.dataFechamentoAceitacao,
                                        dataRepresentacao:
                                          vigencia.dataFechamentoReapresentacao,
                                        loadingSelecionar:
                                          _vm.loadingSelecionar[i].lSelec,
                                        "data-test-id": "vigencia-" + i,
                                      },
                                      on: {
                                        selecionar: function ($event) {
                                          return _vm.selecionarVigencia(
                                            vigencia,
                                            i
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _c("div", { staticClass: "mt-3" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center mt-10 pa-10" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "grey", size: "50" },
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v("Nenhuma vigência encontrada"),
                  ]),
                ]),
          ]),
      _vm.vigenciaSelected
        ? _c(
            "v-bottom-navigation",
            {
              attrs: {
                height: 70,
                light: "",
                "scroll-threshold": "",
                fixed: "",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "align-content": "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-6",
                      attrs: { xs: "2", sm: "2", md: "2", lg: "2", xl: "2" },
                    },
                    [_c("p", [_vm._v("Vigência selecionada:")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-2",
                      attrs: { xs: "10", sm: "6", md: "3", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-flex align-center justify-center mr-2",
                            },
                            [_c("v-icon", [_vm._v("mdi-calendar")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "subtitle-2 font-weight-bold d-flex align-center ma-0",
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v("Início vigência"),
                                  ]),
                                  _c("v-spacer"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          _vm.vigenciaSelected.dataVigencia
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass:
                                    "caption d-flex align-center ma-0",
                                },
                                [
                                  _c("span", [_vm._v("Comercialização")]),
                                  _c("v-spacer"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          _vm.vigenciaSelected
                                            .dataFechamentoAceitacao
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass:
                                    "caption d-flex align-center ma-0",
                                },
                                [
                                  _c("span", [_vm._v("Reapresentação")]),
                                  _c("v-spacer"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("convertDateOutput")(
                                          _vm.vigenciaSelected
                                            .dataFechamentoReapresentacao
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }