var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Planos ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.verFiltro(_vm.planos)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "grey", size: "50" },
              }),
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "pa-5 pt-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pb-2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-center subtitle-2 grey--text mt-2 mb-0 font-weight-regular",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.planos.length) +
                            " registro(s) encontrados(s)"
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pb-15" },
                _vm._l(_vm.planos, function (plano, index) {
                  return _c(
                    "v-col",
                    { key: index, staticClass: "col-12 col-md-6" },
                    [
                      _c("base-card-details-plan", {
                        attrs: {
                          index: index,
                          logo: plano.operadoraLogo,
                          nome: plano.nomePlanoAns,
                          abrangencia: plano.abrangencia,
                          acomodacao: plano.acomodacao,
                          coparticipacao: plano.coparticipacao,
                          modeloCoparticipacao: plano.modeloCoparticipacao,
                          odonto: plano.comOdonto,
                          valorTotalOdonto: Number(
                            plano.valorTotalOdontoAdicional
                          ),
                          operadora: plano.nomeExibicaoOperadora,
                          segmentacao: plano.segmentacao,
                          valor: plano.valorTotal,
                          valorSemDesconto: plano.valorTotalSemDesconto,
                          loadingSelecionar: _vm.loadingSelecionar,
                          valorProximaTabela:
                            plano.valorTotalProximaTabelaPreco,
                          dataInicioProximaTabela:
                            plano.dataInicioProximaTabela,
                          captacao: plano.captacao,
                        },
                        on: {
                          selecionar: function ($event) {
                            return _vm.selecionarPlano(plano)
                          },
                          detalhes: function ($event) {
                            return _vm.verDetalhes(plano)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
      _c("dialog-filtro", {
        attrs: { filtros: _vm.filtros, planos: _vm.planos, UF: _vm.estado },
        on: {
          filtrarplanos: _vm.carregaFiltro,
          limparfiltro: _vm.limpaFiltro,
          close: function ($event) {
            _vm.dialogFiltro = false
          },
        },
        model: {
          value: _vm.dialogFiltro,
          callback: function ($$v) {
            _vm.dialogFiltro = $$v
          },
          expression: "dialogFiltro",
        },
      }),
      _c("dialog-plano", {
        attrs: { plano: _vm.planoSelected },
        on: {
          selecionarplano: _vm.selecionarPlano,
          close: function ($event) {
            _vm.dialogPlano = false
          },
        },
        model: {
          value: _vm.dialogPlano,
          callback: function ($$v) {
            _vm.dialogPlano = $$v
          },
          expression: "dialogPlano",
        },
      }),
      _vm.selectedPlano && _vm.selectedPlano.idProdutoFatura
        ? _c(
            "v-bottom-navigation",
            {
              attrs: {
                height: 70,
                light: "",
                "scroll-threshold": "",
                fixed: "",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "align-content": "end" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-4",
                      attrs: {
                        cols: "4",
                        xs: "4",
                        sm: "4",
                        md: "2",
                        lg: "2",
                        xl: "2",
                      },
                    },
                    [_c("p", [_vm._v("Plano selecionado:")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      attrs: {
                        cols: "8",
                        xs: "8",
                        sm: "8",
                        md: "8",
                        lg: "8",
                        xl: "8",
                      },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            {
                              staticClass: "mr-2 mt-0 mb-0",
                              attrs: { dark: "", size: "50", tile: "" },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  width: "100%",
                                  contain: "",
                                  src: _vm.selectedPlano.operadoraLogo || "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "subtitle-2 mt-1 text-wrap text-uppercase",
                                  staticStyle: {
                                    width: "180px",
                                    "line-height": "1.3",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedPlano.nomePlanoAns || ""
                                    ) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("v-list-item-action", { staticClass: "ma-0" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "secundaryBtn--text ma-2",
                                    attrs: {
                                      color: "primary",
                                      dark: "",
                                      label: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "mt-1 text-uppercase" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.selectedPlano.valorTotal || 0
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }