<template>
  <ul class="timeline">
    <li class="timeline__item d-flex align-center" v-for="(page, index) in pages" :key="page.nome">
      <div class="timeline__check">
        <v-icon color="white" size="20" v-if="index === currentStep">mdi-map-marker</v-icon>
        <v-icon color="white" size="20" v-else-if="index < currentStep">mdi-check-circle</v-icon>
        <v-icon v-else color="rgba(255,255,255,.5)" size="20">mdi-minus-circle</v-icon>
      </div>
      <div class="timeline__text primary--text subtitle-2">
        <v-list class="pa-0" style="overflow: hidden;">
          <v-list-item :link="index <= currentStep" class="pa-1 ma-0" @click="$emit('changeStep', index)">
            <v-list-item-content class="pa-0 pl-2">
              <v-list-item-title class="subtitle-2" style="line-height: 1;">{{page.nome}}</v-list-item-title>
              <v-list-item-subtitle class="caption" v-if="index <= currentStep">
                <span>Clique para ver</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    pages: {
      required: true,
      type: Array,
    },
    currentStep: {
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables";

.timeline {
  padding: 0 20px;
  margin: 30px 0 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 2px;
    height: 80%;
    left: 36px;
    background: $azul-qualicorp-hex;
    transform: translateY(-50%);
  }

  &__item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__check {
    border: 2px solid #fff;
    background: $azul-qualicorp-hex;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  &__text {
    flex: 1;
    line-height: 1.2;
    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
}
</style>
