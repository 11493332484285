import api from "@/services/api";
import AppError from "@/utils/appError";

const resource = "excecaocomercial";

export default {
  // Exceção Operadoras
  async listarExcecaoOperadora(idOperadora) {
    try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EXCECAO_COMERCIAL } };
      const response = await api.get(`${resource}/operadoras/${idOperadora}`, config);
      const { data } = response.data;
      return data;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
  },
  // Exceção CPF
  async listarExcecaoCPFAtiva(cpf) {
    try {
      const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_EXCECAO_COMERCIAL } };
      const response = await api.get(`${resource}/cpf/ativas?cpf=${cpf}&ativo=true`, config);
      const { data } = response.data;
      return data;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
  },
};
