<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">
              Produtos Opcionais
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Dados contratação
            </h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
    </div>
    <v-container v-if="!loading">
      <v-row justify="center">
        <v-col md="4">
          <p class="text-center mt-5">
            {{ propostaState.plano.operadora === "Assim Saúde" ? "Deseja selecionar produtos opcionais odontológicos?" : "Deseja selecionar produtos opcionais?" }}
          </p>
          <v-radio-group v-model="flagProdutoOpcional" :mandatory="false">
            <v-radio class="mb-4" label="Sim" :value="true"></v-radio>
            <v-divider />
            <v-radio class="mt-4" label="Não" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!loading" class="pa-5 pt-0">
      <v-row v-if="flagProdutoOpcional">
        <v-col class="pb-2 col">
          <p v-if="!produtosOpcionais.length" class="text-center mt-5">
            Não encontramos planos opcionais disponíveis para essa proposta.
          </p>
          <p v-else class="text-center subtitle-2 grey--text mb-0 font-weight-regular">
            {{ produtosOpcionais.length }} registro(s) encontrado(s)
            <span v-if="listaSelecionados.length > 0" class="text-center subtitle-2 grey--text mb-0 font-weight-bold">e {{ listaSelecionados.length }} registro(s) selecionado(s)</span>
          </p>
        </v-col>
      </v-row>
      <v-row v-if="flagProdutoOpcional" class="pb-10">
        <v-col class="col-12 col-md-6" v-for="(produtoOpcional, index) in produtosOpcionais" :key="index">
          <base-card-produto-opcional
            :index="index"
            :idProdutoFatura="produtoOpcional.idProdutoFatura"
            :operadora="produtoOpcional.operadora"
            :nome="produtoOpcional.nomePlanoAns"
            :abrangencia="produtoOpcional.abrangencia"
            :valor="produtoOpcional.valorTotal"
            :segmentacao="produtoOpcional.segmentacao"
            :logo="produtoOpcional.operadoraLogo"
            :disabled="verificarExistenciaSegmentacao(produtoOpcional)"
            :selected="checkProdutosSelecionados(produtoOpcional)"
            :mostrarPfp="produtoOpcional.opcionalExigeConfirmacaoCliente"
            @selectProdutoOpcional="selectProdutoOpcional"
            @detalhes="verDetalhes(produtoOpcional)"
          ></base-card-produto-opcional>
        </v-col>
      </v-row>
      <v-btn
        large
        :data-test-id="'salvar-continuar'"
        type="submit"
        elevation="10"
        block
        color="primary"
        class="secundaryBtn--text mt-0"
        :loading="loadingBtn"
        @click="submit()"
      >
        {{ flagProdutoOpcional && produtosOpcionais.length > 0 ? `Salvar e continuar` : `Continuar sem produtos opcionais` }}
      </v-btn>
    </v-container>
    <dialog-produto-opcional v-model="dialogProdutoOpcional" :produtoOpcional = "produtoOpcionalSelecionado"> </dialog-produto-opcional>

  </div>
</template>

<script>
import BaseCardProdutoOpcional from '@/components/BaseCardProdutoOpcional.vue';
import DialogProdutoOpcional from '@/views/AreaLogada/CriarProposta/ProdutosOpcionais/DialogProdutoOpcional.vue';
import { mapState, mapActions } from 'vuex';
import filters from '@/filters';
import propostaService from '@/services/propostaService';
import planoService from '@/services/planoService';
import orderBy from "lodash/orderBy";

export default {
  name: "ProdutosOpcionais",
  data() {
    return {
      produtosOpcionais: [],
      loading: false,
      loadingBtn: false,
      dialogProdutoOpcional: false,
      produtoOpcionalSelecionado: false,
      produtosComSegmentosExistentes: [],
      selected: false,
      listaSelecionados: [],
      arraySegmentosSelecionados: [],
      flagProdutoOpcional: true,
    };
  },
  components: { BaseCardProdutoOpcional, DialogProdutoOpcional },
  created() {
    this.getProdutosOpcionais();
    this.loadProdutosSelecionados();
  },
  computed: {
    ...mapState({
      dadosProdutosOpcionais: (state) => state.cadastroProposta.produtosOpcionais,
      propostaState: (state) => state.cadastroProposta.proposta,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      entidadeAlterada: (state) => state.cadastroProposta.entidadeAlterada,
    }),
  },
  methods: {
    ...mapActions({
      setProdutosOpcionais: 'cadastroProposta/setProdutosOpcionais',
      setDadosProposta: 'cadastroProposta/setDadosProposta',
    }),
    async getProdutosOpcionais() {
      try {
        this.loading = true;
        // captura a lista de beneficiários da proposta
        const beneficiarios = filters.mapBeneficiario(this.propostaState);

        const { idProdutoFatura } = this.propostaState.plano;
        const { dataVigencia } = this.propostaState;
        const uf = this.propostaState.titular.enderecos[0].estado;
        const { cidade } = this.propostaState.titular.enderecos[0];
        const body = {
          idProdutoFatura,
          beneficiarios,
          dataVigencia,
          uf,
          cidade,
          guidProposta: this.propostaState.guid,
        };
        const data = await planoService.getProdutosOpcionais({ idProdutoFatura, body });
        // mapeia a lista para atribuir no template
        this.produtosOpcionais = orderBy(
          data.map((produtoOpcional) => ({ ...produtoOpcional, floatValorTotal: parseFloat(produtoOpcional.valorTotal) })),
          ["floatValorTotal"],
          ["asc"],
        );

        this.loading = false;
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      } finally {
        this.loading = false;
      }
    },
    loadProdutosSelecionados() {
      if (this.propostaState.produtosOpcionais != null) {
        for (let cont = 0;
          cont < this.propostaState.produtosOpcionais.length;
          cont++) {
          if (this.propostaState.produtosOpcionais[cont].idProdutoFatura
            != null && !this.entidadeAlterada) {
            this.listaSelecionados.push(
              this.propostaState.produtosOpcionais[cont].idProdutoFatura,
            );
            this.arraySegmentosSelecionados.push(this.propostaState.produtosOpcionais[cont].segmentacao);
          }
        }
      }
    },
    checkProdutosSelecionados(produtoOpcional) {
      const response = this.listaSelecionados.some((idProdutoFatura) => idProdutoFatura === produtoOpcional.idProdutoFatura);
      return response;
    },
    async submit() {
      if (this.flagProdutoOpcional && this.produtosOpcionais.length && !this.listaSelecionados.length) {
        this.mostraErro("Deve ser selecionado ao menos um produto opcional");
        return;
      }
      this.setProdutosOpcionais(this.listaSelecionados);
      this.setDadosProposta({ ...this.propostaState });
      try {
        await this.salvarDados();
      } catch (error) {
        this.mostraErro(error.message || 'Erro ao salvar dados, tente novamente mais tarde');
      }
    },
    verDetalhes(produtoOpcional) {
      this.produtoOpcionalSelecionado = produtoOpcional;
      this.dialogProdutoOpcional = true;
    },
    selectProdutoOpcional(idProdutoFatura, segmentacao) {
      if (!segmentacao) {
        segmentacao = '';
      }

      if (this.listaSelecionados.some((item) => item === idProdutoFatura)) {
        const index = this.listaSelecionados.indexOf(idProdutoFatura);
        // remove a partir do index e o segundo parâmetro a quatidade de itens
        this.listaSelecionados.splice(index, 1);
        this.arraySegmentosSelecionados.splice(index, 1);
      } else {
        this.listaSelecionados.push(idProdutoFatura);
        this.arraySegmentosSelecionados.push(segmentacao);
      }
    },
    verificarExistenciaSegmentacao(produtoOpcional) {
      let response = false;

      // primeira verificação: olhar se existe segmentação já selecionada
      if (this.arraySegmentosSelecionados
        && this.arraySegmentosSelecionados.length > 0
        && produtoOpcional && produtoOpcional.segmentacao) {
        response = this.arraySegmentosSelecionados.some((segmentacao) => segmentacao.toLowerCase() === produtoOpcional.segmentacao.toLowerCase());

        // segunda verificação: fazer com que o produto selecionado não fique inativo
        if (response === true) {
          response = this.listaSelecionados.some((idProdutoFatura) => idProdutoFatura === produtoOpcional.idProdutoFatura);
          response = !response;
        }
      }
      return response;
    },
    async salvarDados() {
      this.loadingBtn = true;

      try {
        let responseDialog = true;
        let paginaEspecifica;
        // verificar se algum produto opcional for selecionado, caso não tenha, não exibe o dialog de confirmação
        if (this.listaSelecionados != null && this.listaSelecionados.length > 0 && this.flagProdutoOpcional) {
          responseDialog = await this.$root.$confirmdialog.open(
            'Atenção',
            `Você deseja realmente confirmar a contratação do(s) <strong>produto(s) selecionado(s)?</strong>`,
          );
        }
        if (responseDialog) {
          let flagProdutoOpcional = false;
          if (this.listaSelecionados && this.listaSelecionados.length && this.flagProdutoOpcional) flagProdutoOpcional = true;

          paginaEspecifica = { specificStep: 12 };
          this.setDadosProposta({
            ...this.propostaState,
            etapa: 'dados_financeiros',
            flagProdutoOpcional,
          });

          const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);
          if (data && data.produtosOpcionais && flagProdutoOpcional) {
            this.setProdutosOpcionais([...data.produtosOpcionais]);
          } else this.setProdutosOpcionais([]);
          this.$emit('next', paginaEspecifica);
        }
      } catch (error) {
        this.mostraErro(error.message || 'Erro ao salvar dados, tente novamente mais tarde');
      } finally {
        this.loadingBtn = false;
      }
    },
    mostraErro(mensagem) {
      this.$root.$snackBar.open({
        color: 'error',
        message: mensagem,
      });
      this.$emit('error');
    },
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
