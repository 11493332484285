import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'reembolsos';

const getConsultaReembolso = async ({
  idProdutoFatura,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_REEMBOLSO_API_KEY } };
    const { data } = await api.get(`${resource}/consulta/${idProdutoFatura}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
export default {
  getConsultaReembolso,
};
