var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      style: [
        _vm.selected ? { border: "2px solid #00BFFF" } : { border: "none" },
      ],
      attrs: { elevation: "3", disabled: _vm.disabled },
    },
    [
      _c(
        "v-list-item",
        { staticClass: "pt-0 pb-0" },
        [
          _vm._t("header"),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                {
                  staticClass: "subtitle-2 mt-1 text-wrap text-uppercase",
                  staticStyle: { width: "180px", "line-height": "1.3" },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("v-divider"),
      _c(
        "v-card",
        {
          staticClass: "pa-2 pl-4 pr-4",
          attrs: { color: "#F7F7F7", flat: "", tile: "" },
        },
        [_vm._t("body")],
        2
      ),
      _c("v-divider"),
      _c("v-card-actions", { class: _vm.footerColor }, [_vm._t("footer")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }