import AppError from '@/utils/appError';
import api from "@/services/api";

export default {
  async verificarIsencaoProposta(guidProposta) {
    try {
      const config = {
        headers: {
          auth: localStorage.getItem(`${process.env.VUE_APP_NAME_AUTH}`),
          "X-Gravitee-Api-Key": process.env.VUE_APP_API_KEY_ISENCAO_TAXA
        }
      };
      const response = await api.get(`/isencaotaxa/verificar/isencaoEntidade/${guidProposta}`, config);
      const { data } = response.data;

      return data.length !== 0;
    } catch (error) {
      const { response } = error;
      throw new AppError(response, 400);
    }
  },
};
