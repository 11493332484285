var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hige-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _vm.produtoOpcional
        ? _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c("the-nav-bar", {
                attrs: { title: "Detalhe produto opcional" },
                on: { back: _vm.close },
              }),
              _c(
                "v-container",
                { staticClass: "pa-5 pt-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0",
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "6",
                            lg: "6",
                            xl: "6",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "primary--text mb-2 subtitle-1 font-weight-bold",
                                    },
                                    [_vm._v("Resumo")]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 font-weight-bold mb-0",
                                            },
                                            [_vm._v("Nome")]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "subtitle-1 text-uppercase text-wrap",
                                              staticStyle: {
                                                "line-height": "1.2",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.produtoOpcional
                                                    .nomePlanoAns
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 font-weight-bold mb-0",
                                            },
                                            [_vm._v("Operadora")]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "subtitle-1 text-uppercase",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.produtoOpcional.operadora
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 font-weight-bold mb-0",
                                            },
                                            [_vm._v("Qtd. Beneficiário")]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "subtitle-1 text-uppercase",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.produtoOpcional
                                                    .beneficiarios != null
                                                    ? _vm.produtoOpcional
                                                        .beneficiarios.length
                                                    : 0
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.produtoOpcional
                                    .opcionalExigeConfirmacaoCliente
                                    ? _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Valor Total")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.produtoOpcional
                                                        .valorTotal
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.produtoOpcional.opcionalExigeConfirmacaoCliente
                            ? _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "primary--text mb-2 subtitle-1 font-weight-bold",
                                    },
                                    [_vm._v("Beneficiários")]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    _vm._l(
                                      _vm.beneficiarios,
                                      function (beneficiario, index) {
                                        return _c(
                                          "v-col",
                                          { key: index, attrs: { cols: "12" } },
                                          [
                                            _c("base-card-beneficiary", {
                                              attrs: {
                                                nome:
                                                  beneficiario.chave ||
                                                  beneficiario.dataNascimento,
                                                valor:
                                                  beneficiario.valorContratual,
                                                valorSemDesconto:
                                                  beneficiario.valorContratualSemDesconto,
                                                tipo: _vm.isTipo(beneficiario),
                                                avatar:
                                                  _vm.isTipo(beneficiario),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none mt-6",
                                      attrs: {
                                        large: "",
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.close()
                                        },
                                      },
                                    },
                                    [_vm._v("Voltar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }