<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('back')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Criar Proposta</h1>
            <h3 class="caption ma-0" style="line-height: 1">Dados corretora</h3>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon dark @click="$emit('openmenu')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
    <div class="header-height"></div>
    <v-container class="pa-5 pt-0">
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <validation-observer ref="form" tag="form" class="mb-10" @submit.prevent="submit" v-else>
        <v-row justify="center">
          <v-col class="col-12 col-md-6">
            <v-row>
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Corretora</h2>
                <v-divider></v-divider>
                <validation-provider name="Corretora" vid="corretora" v-slot="{ errors }" rules="required">
                  <v-radio-group :value="form.corretora" :value-comparator="valueCompare" :mandatory="false" :error-messages="errors">
                    <div v-if="corretoras.length > 0">
                      <template v-for="(corretora, index) in corretoras">
                        <v-radio
                          :data-test-id="'corretora-' + index"
                          :id="'corretora-' + index"
                          class="mb-4"
                          :class="{ 'mt-4': index > 0 }"
                          :label="corretora.nomeFantasia"
                          :value="corretora.cpfCnpj"
                          :key="corretora.cpfCnpj"
                          :disabled="desabilitaInput"
                          @click="handleClickCorretora(corretora)"
                          @change="setAdministradoras"
                        ></v-radio>
                        <v-divider :key="corretora.nomeFantasia" v-if="index < corretoras.length - 1"></v-divider>
                      </template>
                    </div>
                    <div v-else>
                      Aguardando aprovação de corretora
                    </div>
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
            <div v-if="form.corretora">
              <v-row>
                <v-col class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Supervisor</h2>
                  <v-divider></v-divider>
                  <async-supervisores :idCorretora="selectedCorretora.id" :cpfCorretor="cpf" v-slot="{ loading: loadingSupervisores, data }">
                  <base-wrapper-dialog-list
                    headerTitle="Supervisor"
                    :options="data"
                    label-key="nome"
                    value-key="id"
                    :loading="loadingSupervisores"
                    return-object
                    @select="selectSupervisor($event)"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        rules="required"
                        readonly
                        :disabled="false"
                        inputmode="text"
                        v-on="on"
                        id="supervisor"
                        name="Supervisor"
                        placeholder="Selecione"
                        label="Supervisor"
                        outlined
                        :value="form.supervisor ? form.supervisor.nome : null"
                        append-icon="mdi-chevron-right"
                        :loading="false"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-list>
                  </async-supervisores>
                </v-col>
              </v-row>
              <v-row v-if="selectedCorretora.portabilidade">
                <v-col class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Portabilidade</h2>
                  <v-divider></v-divider>
                  <validation-provider name="Portabilidade" vid="portabilidade" v-slot="{ errors }" rules="required">
                    <v-radio-group v-model="form.portabilidade" :mandatory="false" :error-messages="errors">
                      <v-radio :id="'portabilidade-sim'" :data-test-id="'portabilidade-sim'" class="mb-4" label="SIM" :value="true"></v-radio>
                      <v-divider></v-divider>
                      <v-radio :id="'portabilidade-nao'" :data-test-id="'portabilidade-nao'" class="mb-4 mt-4" label="NÃO" :value="false"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="selectedCorretora.sumula">
                <v-col cols="12" :md="form.sumula ? '6' : '12'" class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Súmula 21</h2>
                  <v-divider></v-divider>
                  <validation-provider name="Súmula" vid="sumula" v-slot="{ errors }" rules="required">
                    <v-radio-group v-model="form.sumula" :mandatory="false" :error-messages="errors">
                      <v-radio :id="'sumula-sim'" :data-test-id="'sumula-sim'" class="mb-4" label="SIM" :value="true"></v-radio>
                      <v-divider></v-divider>
                      <v-radio :id="'sumula-nao'" :data-test-id="'sumula-nao'" class="mb-4 mt-4" label="NÃO" :value="false"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" class="pb-0" v-if="form.sumula">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Tempo plano anterior</h2>
                  <v-divider></v-divider>
                  <validation-provider name="Tempo do Plano Anterior" vid="sumulaPlanoAnterior" v-slot="{ errors }" :rules="form.sumula ? 'required' : ''">
                    <v-radio-group v-model="form.sumulaPlanoAnterior" :mandatory="false" :error-messages="errors">
                      <v-radio :data-test-id="'maior-24'" class="mb-4" label="MAIOR QUE 24 MESES" :value="1"></v-radio>
                      <v-divider></v-divider>
                      <v-radio :data-test-id="'menor-24'" class="mb-4 mt-4" label="MENOR QUE 24 MESES" :value="0"></v-radio>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
            <div v-if="getCorretorasRepasse.length">
              <v-row>
                <v-col class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Corretora de Repasse</h2>
                  <v-divider></v-divider>
                  <validation-provider name="Corretora de Repasse" vid="corretoraRepasse" v-slot="{ errors }" rules="required">
                    <v-radio-group v-model="form.corretoraRepasse" :mandatory="false" :error-messages="errors">
                      <template v-for="(corretoraRepasse, index) in getCorretorasRepasse">
                        <v-radio
                          :data-test-id="'corretoraRepasse' + index"
                          :id="'corretoraRepasse' + index"
                          class="mb-4"
                          :class="{ 'mt-4': index > 0 }"
                          :label="corretoraRepasse.nome"
                          :value="corretoraRepasse.id"
                          :key="corretoraRepasse.id"
                          :checked="true"
                          :disabled="desabilitaInput"
                        ></v-radio>
                        <v-divider :key="corretoraRepasse.id + '2'" v-if="index < corretoras.length - 1"></v-divider>
                      </template>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="!getAdministradora && form.corretora">
              <v-row>
                <v-col class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Administradora</h2>
                  <v-divider></v-divider>
                  <div class="mt-3">
                    <div>
                      <p class="text-center">A corretora não possui administradora cadastrada. Entre em contato com sua corretora.</p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-row no-gutters>
              <v-col>
                <v-btn
                  :data-test-id="'salvar-continuar-1'"
                  :id="'salvar-continuar'"
                  :loading="loadingSalvar"
                  :disabled="!getAdministradora && form.corretora"
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-4"
                  >Salvar e continuar</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import corretoraService from "@/services/corretoraService";
import propostaService from "@/services/propostaService";
import AsyncSupervisores from "@/components/AsyncSupervisores.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";

export default {
  name: "Corretoras",
  components: {
    AsyncSupervisores,
    BaseWrapperDialogList,
    BaseTextField,
  },
  data() {
    return {
      loading: false,
      loadingSalvar: false,
      corretoras: [],
      supervisores: [],
      form: {
        administradora: "qualicorp",
        corretora: null,
        supervisor: null,
        portabilidade: null,
        sumula: null,
        corretoraRepasse: null,
        sumulaPlanoAnterior: null,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      cpf: (state) => state.user.profile.cpf,
      dadosCorretoraState: (state) => state.cadastroProposta.corretora,
      blnContinuarState: (state) => state.cadastroProposta.blnContinuar,
      propostaState: (state) => state.cadastroProposta.proposta,
      dadosProfissionaisState: (state) => state.cadastroProposta.titular.dadosProfissionais,
    }),
    selectedCorretora() {
      return this.corretoras.find((item) => item.cpfCnpj === this.form.corretora);
    },
    desabilitaInput() {
      if (!this.propostaState.status) {
        return false;
      }
      return this.propostaState.status !== "EM_DIGITACAO";
    },
    desabilitaInputAdministradora() {
      if (!this.propostaState.administradora) {
        return false;
      }
      return true;
    },
    getAdministradora() {
      const corretora = this.selectedCorretora;
      return corretora && corretora.administradoras ? corretora.administradoras : "";
    },
    getCorretorasRepasse() {
      const corretora = this.selectedCorretora;
      const corretoraRepasse = ((corretora && corretora.corretorasRepasse) || []).filter((co) => co.ativo);
      if (corretoraRepasse.length) {
        return [{ id: 0, nome: "Não informar a corretora de repasse" }, ...corretoraRepasse];
      }
      return [];
    },
  },
  watch: {
    dadosCorretoraState: {
      immediate: true,
      handler: "setData",
      deep: true,
    },
    selectedCorretora: {
      handler: "changeCorretora",
    },
  },
  methods: {
    ...mapActions({
      setCorretora: "cadastroProposta/setCorretora",
      setDadosPortabilidade: "cadastroProposta/setDadosPortabilidade",
      setDadosSumula: "cadastroProposta/setDadosSumula",
      setDadosAdministradora: "cadastroProposta/setDadosAdministradora",
      setDadosProposta: "cadastroProposta/setDadosProposta",
      setIdFilial: "cadastroProposta/setIdFilial",
      setCorretoraRepasse: "cadastroProposta/setCorretoraRepasse",
    }),
    handleClickCorretora(corretora) {
      this.form.corretora = corretora.cpfCnpj
      if (corretora.tipoCorretora === this.user.profile.tipoCorretor) {
        this.form.corretora = corretora.cpfCnpj
      } else {
        this.form.corretora = null
        this.$root.$snackBar.open({
          color: "error",
          message: "Não é possível seguir com a corretora selecionada",
        });
      }
    },
    changeCorretora() {
      if (this.getAdministradora.length === 1) {
        [this.form.administradora] = this.getAdministradora;
      }
    },
    setAdministradoras() {
      const administradoras = this.getAdministradora;
      if (administradoras.length > 0) {
        this.form.administradora = administradoras;
      }
      return this.form.administradora;
    },
    setData() {
      if (this.dadosCorretoraState && Object.keys(this.dadosCorretoraState).length) {
        const corretora = this.corretoras.find((cor) => cor.cpfCnpj === this.dadosCorretoraState.cpfCnpj);
        if (corretora) {
          this.setSupervisores(corretora.tipoCorretora, corretora.supervisores);
          this.form = {
            corretora: this.dadosCorretoraState.cpfCnpj,
            portabilidade: this.propostaState.possuiPortabilidade,
            sumula: this.propostaState.possuiSumula,
            sumulaPlanoAnterior: this.propostaState.sumulaPlanoAnterior,
            supervisor: this.dadosCorretoraState.guidSupervisor === ""
            ? {
              nome: "Não informar supervisor",
              guid: "",
            } : {
              nome: this.dadosCorretoraState.nomeSupervisor,
              guid: this.dadosCorretoraState.guidSupervisor,
            },
            necessarioAnexoTaxaAngariacao: this.dadosCorretoraState.necessarioAnexoTaxaAngariacao,
            dadosImpressos: this.dadosCorretoraState.dadosImpressos,
            taxaDefinidaPeloCorretor: this.dadosCorretoraState.taxaDefinidaPeloCorretor,
            administradora: this.propostaState.administradora,
            corretoraRepasse: (this.propostaState.corretoraRepasse && this.propostaState.corretoraRepasse.id) || 0,
          };
        }
      }
    },
    setSupervisores(tipoCorretora, supervisores) {
      this.supervisores = [];

      this.form.supervisor = supervisores.length > 1 ? null
        : {
          nome: "Não informar supervisor",
          guid: "",
        };

      if (tipoCorretora !== "Corretor Interno" && tipoCorretora !== "Televendas") {
        this.supervisores.push({
          nome: "Não informar supervisor",
          guid: "",
        });
      }

      supervisores.forEach((supervisor) => {
        this.supervisores.push({
          label: supervisor.nome,
          value: supervisor.guid,
        });
      });
    },
    async getCorretoras() {
      try {
        this.loading = true;
        const data = await corretoraService.getCorretorasPorCpf({
          cpf: this.cpf,
        });

        this.corretoras = data;
        this.setData();
      } catch (error) {
        // TODO: Implementar Tratativa de erro
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const corretora = {
        cpfCnpj: this.selectedCorretora.cpfCnpj,
        guidSupervisor: !this.form.supervisor ? "" : this.form.supervisor.guid,
        nomeSupervisor: !this.form.supervisor ? "" : this.form.supervisor.nome,
        necessarioAnexoTaxaAngariacao: this.selectedCorretora.necessarioAnexoTaxaAngariacao,
        taxaDefinidaPeloCorretor: this.selectedCorretora.taxaDefinidaPeloCorretor,
        dadosImpressos: this.selectedCorretora.dadosImpressos,
        taxaReciboPadrao: this.selectedCorretora.taxaReciboPadrao,
        segueVendaNacional: this.selectedCorretora.segueVendaNacional,
      };
      const corretoraRepasse = this.form.corretoraRepasse && this.selectedCorretora && this.selectedCorretora.corretorasRepasse
          ? this.selectedCorretora.corretorasRepasse.find((co) => co.id === this.form.corretoraRepasse)
          : null;
      this.setCorretora({ corretora });

      this.setDadosAdministradora(this.setAdministradoras());

      this.setDadosPortabilidade(this.form.portabilidade);
      this.setDadosSumula({ possuiSumula: this.form.sumula, sumulaPlanoAnterior: this.form.sumulaPlanoAnterior });
      this.setIdFilial(this.selectedCorretora.idFilial);
      this.setCorretoraRepasse(corretoraRepasse);

      if (this.blnContinuarState.isEditing) {
        this.setDadosProposta({ ...this.propostaState });
      } else {
        this.setDadosProposta({
          ...this.propostaState,
          etapa: "basicos",
        });
      }

      try {
        this.loadingSalvar = true;
        await this.salvarDados();
        this.$emit("next");
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
        });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async salvarDados() {
      let dadosProposta = {};
      if (this.propostaState.nrProposta) {
        const [data] = await propostaService.addOrEdit(this.propostaState.guid, this.propostaState);

        if (data && data.guid) {
          dadosProposta = {
            ...this.propostaState,
            guid: data.guid,
            status: data.status,
          };
        }
      } else {
        dadosProposta = {
          ...this.propostaState,
          status: "EM_DIGITACAO",
        };
      }

      this.setDadosProposta(dadosProposta);
    },
      async selectSupervisor(data) {
      this.form.supervisor = data;
    },
    valueCompare(a, b) {
      return Object.is(a, b);
    },
  },
  created() {
    this.getCorretoras();
  },
};
</script>

<style lang="scss" scoped>
.header-height {
  padding: 20px;
  height: 70px;
}
</style>
