var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados Profissionais ")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v(" Dados contratação ")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-5" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "validation-observer",
                                {
                                  ref: "form",
                                  attrs: { tag: "form" },
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submit.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("base-text-field", {
                                    attrs: {
                                      rules: "required",
                                      id: "profissao",
                                      name: "Profissão",
                                      label: "Profissão",
                                      value: _vm.profissaoState,
                                      outlined: "",
                                      disabled: "",
                                    },
                                  }),
                                  _c("base-text-field", {
                                    attrs: {
                                      rules: "required",
                                      id: "entidade",
                                      name: "Entidade",
                                      value: _vm.entidadeState,
                                      label: "Entidade",
                                      outlined: "",
                                      disabled: "",
                                    },
                                  }),
                                  _vm.entidadeTemCnae
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center pb-8",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text subtitle-1 font-weight-regular pr-4",
                                            },
                                            [
                                              _vm._v(
                                                "Informe os dados da empresa"
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center pb-8",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text subtitle-1 font-weight-regular pr-4",
                                            },
                                            [
                                              _vm._v(
                                                "Informar dados da empresa"
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c("v-switch", {
                                            staticClass: "ma-0",
                                            attrs: {
                                              "data-test-id":
                                                "informar-dados-empresa",
                                              "hide-details": "",
                                              disabled:
                                                !_vm.possuiDadosEmpresaState &&
                                                _vm.desabilitaInput,
                                            },
                                            model: {
                                              value: _vm.showDadosEmpresa,
                                              callback: function ($$v) {
                                                _vm.showDadosEmpresa = $$v
                                              },
                                              expression: "showDadosEmpresa",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                  _vm.showDadosEmpresa || _vm.entidadeTemCnae
                                    ? _c(
                                        "div",
                                        [
                                          _c("base-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: "##.###.###/####-##",
                                                expression:
                                                  "'##.###.###/####-##'",
                                              },
                                            ],
                                            attrs: {
                                              rules: "required",
                                              id: "cnpj",
                                              "data-test-id": "cnpj",
                                              name: "CNPJ",
                                              label: "CNPJ *",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.cnpj,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "cnpj", $$v)
                                              },
                                              expression: "form.cnpj",
                                            },
                                          }),
                                          _c("base-text-field", {
                                            attrs: {
                                              rules: "required",
                                              id: "nomeFantasia",
                                              "data-test-id": "nome-empresa",
                                              name: "Nome Empresa",
                                              label: "Nome Empresa *",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.nomeFantasia,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "nomeFantasia",
                                                  $$v
                                                )
                                              },
                                              expression: "form.nomeFantasia",
                                            },
                                          }),
                                          _c("base-text-field", {
                                            attrs: {
                                              rules: _vm.entidadeTemCnae
                                                ? "required"
                                                : "",
                                              id: "razaoSocial",
                                              "data-test-id": "razao-social",
                                              name: "Razão Social",
                                              label: `Razão Social ${
                                                _vm.entidadeTemCnae ? "*" : ""
                                              }`,
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.razaoSocial,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "razaoSocial",
                                                  $$v
                                                )
                                              },
                                              expression: "form.razaoSocial",
                                            },
                                          }),
                                          _c("base-text-field", {
                                            attrs: {
                                              rules: _vm.entidadeTemCnae
                                                ? "required"
                                                : "",
                                              id: "ramoAtividade",
                                              "data-test-id": "ramo-atividade",
                                              name: "Ramo de atividade",
                                              label: `Ramo de atividade ${
                                                _vm.entidadeTemCnae ? "*" : ""
                                              }`,
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.ramoAtividade,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "ramoAtividade",
                                                  $$v
                                                )
                                              },
                                              expression: "form.ramoAtividade",
                                            },
                                          }),
                                          _c("base-text-field", {
                                            attrs: {
                                              rules: _vm.entidadeTemCnae
                                                ? "required"
                                                : "",
                                              id: "sindicato",
                                              "data-test-id": "sindicato",
                                              name: "Sindicato",
                                              label: `Sindicato ${
                                                _vm.entidadeTemCnae ? "*" : ""
                                              }`,
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.form.sindicato,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sindicato",
                                                  $$v
                                                )
                                              },
                                              expression: "form.sindicato",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "secundaryBtn--text mt-5 mb-4",
                                      attrs: {
                                        "data-test-id": "salvar-continuar-5",
                                        loading: _vm.loadingSalvar,
                                        large: "",
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }